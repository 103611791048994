import {Resource} from "../../api/resource";
import {MatDialogRef} from "@angular/material/dialog";
import {BaseDialogData, BaseDialogResult} from "./base-dialog-data";
import {OnDestroy, OnInit, Directive} from "@angular/core";
import {InternalPropertyMap} from "../../basic-entity-back/basic-entity-interface/mapping-internal";
import {BloqueadorService} from "../../services/bloqueador.service";
import {BaseEntity} from "../../model/base-entity.model";

@Directive()
export abstract class BaseDialog<T extends Resource> implements OnInit, OnDestroy {
    /** Result when the dialog is cancelled */
    public static readonly RESULT_CANCEL: BaseDialogResult = "cancel";
    /** Result when the dialog is accepted */
    public static readonly RESULT_SHOULD_RELOAD: BaseDialogResult = "accept";

    /** Columns passed from the invoker which describe all the fields */
    public readonly properties: InternalPropertyMap[];
    /** Model we are editing */
    public readonly model: T;
    /** Should the dialog show save and cancel? */
    public readonly showSaveAndCancelButtons: boolean;
    /** Should the dialog allow to edit the id? */
    public readonly allowIdEdition: boolean;
    /** Is this a new element or are we editing an already existent one? */
    public readonly isNew: boolean;
    /** If set to true, the dialog is expected to save the model modifications, if false, the caller will save the changes on its own */
    public readonly shouldManageSaving: boolean;
    /** Disable every field displayed */
    public readonly allFieldsDisabled: boolean;
    /** Update this value whenever a change is made so the user can be notified if he tries to leave the page */
    public modified = false;

    protected constructor(
        data: BaseDialogData<T>,
        private _dialogRef: MatDialogRef<BaseDialog<T>>,
        private bloqueador: BloqueadorService = null
    ) {
        this.model = data.model;
        this.properties = data.columns.filter(p => !p.onlyOnNew || data.isNew);
        this.allowIdEdition = data.allowIdEdition;
        this.shouldManageSaving = !!data.shouldManageSaving;
        this.isNew = data.isNew;
        this.allFieldsDisabled = !!data.allFieldsDisabled;
        this.showSaveAndCancelButtons = data.showSaveAndCancelButtons;
        this._beforeUnload = this._beforeUnload.bind(this);
    }

    ngOnInit(): void {
        window.addEventListener('beforeunload', this._beforeUnload);
    }

    ngOnDestroy(): void {
        window.removeEventListener('beforeunload', this._beforeUnload);
    }

    /**
     * Closes the dialog, cancelling the edition
     */
    public closeDialog(shouldReload = false) {
        if (this.model instanceof BaseEntity) {
            if (this.bloqueador) {
                this.bloqueador.desbloquear(this.model).subscribe(res => {
                    if (!!res) {
                        this._close(true);
                    }
                });
            }
        } else {
            this._close(shouldReload);
        }
    }

    protected _close(shouldReload) {
        if (shouldReload) {
            this._dialogRef.close(BaseDialog.RESULT_SHOULD_RELOAD);
        } else {
            this._dialogRef.close(BaseDialog.RESULT_CANCEL);
        }
    }


    /**
     * Before unload, please check if there are modified values.
     * @private
     */
    private _beforeUnload(event: BeforeUnloadEvent) {
        if (this.modified) {
            const message = 'Los datos no guardados se perderán.';

            event.returnValue = message;
            return message;
        }
    }
}
