import {Component, ElementRef, EventEmitter, Host, HostBinding, Input, OnDestroy, OnInit, Optional, Output, Renderer2, Self, SkipSelf, ViewChild} from "@angular/core";
import {AbstractControl, ControlContainer, ControlValueAccessor, UntypedFormControl, NgControl} from "@angular/forms";
import {BasicEntityInterface} from "../../../basic-entity-back/basic-entity-interface/basic-entity-interface";
import {Observable, Subject} from "rxjs";
import {InterfaceProviderService} from "../../../basic-entity-back/services/interface-provider.service";
import {Uri} from "../../../api/uri";
import {EntityNameService} from "../../../basic-entity-back/services/entity-name.service";
import {ApiModuleFactory} from "../../../api/api-module-factory.service";
import {Resource} from "../../../api/resource";
import {EntityDatasource} from "../../../api/entity.datasource";
import {UriCaster} from "../../../basic-entity-back/casters/uri-caster";
import {MatFormFieldControl} from "@angular/material/form-field";
import {MatSelect} from "@angular/material/select";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {FocusMonitor} from "@angular/cdk/a11y";
import {GeneralSearchFilter} from "../../../basic-entity-back/filters/general-search-filter";
import {FilterAndData} from "../../../api/filter-list";
import {MatOptionModel} from '../nested-input/mat-option-model';
import {fadeInFadeOut} from '../../animations/animations';

/**
 *
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */
@Component({
    selector: "be-uri-input",
    templateUrl: "./uri-input.component.html",
    styleUrls: ["./uri-input.component.scss"],
    providers: [
        { provide: MatFormFieldControl, useExisting: UriInputComponent }
    ],
    animations: [ fadeInFadeOut ]
})
export class UriInputComponent
    implements
        OnInit,
        OnDestroy,
        MatFormFieldControl<Uri>,
        ControlValueAccessor {
    private static readonly FILTER_TIMEOUT = 500;
    public static nextId = 0;

    public stateChanges = new Subject<void>();
    @HostBinding()
    public id = `be-key-value-input-${UriInputComponent.nextId++}`;

    @Input()
    get placeholder(): string {
        // No mostrar cuando tenemos el select
        return this.dataSources.length > 1 &&
            this.inputControl.enabled &&
            this.shouldLabelFloat
            ? ""
            : this._placeholder;
    }

    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }

    private _placeholder: string;

    public get focused() {
        return this._focused;
    }

    private _focused = false;

    public get empty() {
        return !this.inputControl.value;
    }

    @HostBinding("class.floating")
    public get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @Input()
    public get required() {
        return this._required;
    }

    public set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }

    private _required = false;

    @Input()
    get disabled() {
        return this.inputControl.disabled;
    }

    set disabled(dis) {
        const disabled = coerceBooleanProperty(dis);
        this.setDisabledState(disabled);
    }

    public get errorState(): boolean {
        return !!this.ngControl.errors;
    }

    public readonly controlType = "be-uri-input";
    @HostBinding("attr.aria-describedby") public describedBy = "";

    @Input()
    public get value(): Uri | null {
        return this.inputControl.value;
    }

    public set value(uri: Uri | null) {
        this.writeValue(uri);
    }

    /** Id for the input */
    @Input() public idAttr: string;
    /** Title for the input */
    @Input() public title: string;
    /** Name for the control to find the FormControl  */
    @Input() public formControlName;
    @Input() private forcedInterface;
    /** Interface of the entities referenced by the URIs stored here */
    @Input() public modelTypeOrParent: Function;
    @Input() public contenido: MatOptionModel;
    @Output() interfaz = new EventEmitter<EntityDatasource<Resource, BasicEntityInterface<Resource>>>();
    @HostBinding("class.app-uri-input") appUriInput = true;

    /** Control which manages the input */
    public inputControl = new UntypedFormControl();
    /** Control which manages the select */
    public selectControl = new UntypedFormControl();



    /** Interface select field reference */
    @ViewChild(MatSelect) private _matSelect: MatSelect;
    /** Field to store the callback to perform when the input has been touched */
    private _onTouched;
    /** Field to store the callback to perform when the input has been changed */
    private _onChange;
    /** Abstract control for the element (do not mistake with the internal FormGroup) */
    private _control: AbstractControl;
    /** Caster */
    private _caster: UriCaster;
    /** Timeout to filter in the autocomplete */
    private _filterTimeout: number;
    /** Options displayed in the autocomplete */
    options: Observable<Resource[]>;
    /** Data provider for the autocomplete */
    dataSources: EntityDatasource<Resource, BasicEntityInterface<Resource>>[] = [];
    /** Current data source */
    public currentDataSource: EntityDatasource<Resource, BasicEntityInterface<Resource>>;

    public get currentInterface(): BasicEntityInterface<Resource> {
        return this.currentDataSource.loader.entityInterface;
    }

    constructor(
        private _renderer: Renderer2,
        @Optional()
        @Host()
        @SkipSelf()
        private _controlContainer: ControlContainer,
        @Optional() @Self() public ngControl: NgControl,
        private _interfaceProvider: InterfaceProviderService,
        private _apiFactory: ApiModuleFactory,
        private _nameProvider: EntityNameService,
        private _fm: FocusMonitor,
        private _elRef: ElementRef<HTMLElement>
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
        _fm.monitor(_elRef.nativeElement, true).subscribe(origin => {
            this._focused = !!origin;
            if (this.dataSources.length > 1 && origin === "keyboard") {
                setTimeout(() => this.focusSelect());
            }
            this.stateChanges.next();
        });
        this.displayOption = this.displayOption.bind(this);
    }

    ngOnDestroy() {
        this.stateChanges.complete();
        for (const dS of this.dataSources) {
            dS.disconnect(null);
        }
        this._fm.stopMonitoring(this._elRef.nativeElement);
    }

    ngOnInit() {
        if (this._controlContainer && this.formControlName) {
            this._control = this._controlContainer.control.get(
                this.formControlName
            );
        }
        if (!this.modelTypeOrParent) {
            throw new Error("Model type required in uri-input component!");
        }
        let interfaces = this._interfaceProvider.allAcceptedInterfaces(this.modelTypeOrParent);
        if (this.forcedInterface) {
            interfaces = interfaces.filter(int => int.name === this.forcedInterface.name);
        }
        this._caster = new UriCaster(`uri-input(${this.title})`, interfaces);
        this._initDatasources(interfaces);
        let dataSource = null;
        if (this.value) {
            dataSource = this.dataSources.find(dS =>
                    dS.loader.entityInterface.serialiser.model === this.value.model
            );
        }
        if (dataSource) {
            this.selectControl.setValue(dataSource);
        } else {
            this.selectControl.setValue(this.dataSources[0]);
        }
        this.currentDataSource = this.selectControl.value;
        this.interfaz.emit(this.currentDataSource);
        this.options = this.selectControl.value.entities$;
        this.selectControl.valueChanges.subscribe(
            this._selectChanged.bind(this)
        );

        this.inputControl.valueChanges.subscribe(this._valueChanged.bind(this));
    }

    private _initDatasources(interfs: BasicEntityInterface<Resource>[]) {
        for (const interf of interfs) {
            const dataSource = this._apiFactory.createDataSource(interf);
            dataSource.connect(null);
            this.dataSources.push(dataSource);
        }
    }

    private _selectChanged(value: EntityDatasource<Resource, BasicEntityInterface<Resource>>) {
        if (this.currentDataSource !== value) {
            this.currentDataSource = value;
            this.interfaz.emit(this.currentDataSource);
            this.options = value.entities$;
            setTimeout(() => this.focusInput());
        }
    }

    private _valueChanged(value: string | Uri) {
        if (typeof value === "string") {
            if (!value) {
                clearTimeout(this._filterTimeout);
                if (!this.required) {
                    this._onValidValue(null);
                } else {
                    this._setErrors({ invalidSelection: true });
                }
            } else {
                try {
                    // Try to replace the string with an URI
                    const uri = this._caster.toModel(value);
                    this.inputControl.setValue(uri, { emitEvent: false });
                    this._onValidValue(uri);
                } catch {
                    // If it fails, set error and filter by the string the autocomplete results
                    this._setErrors({ invalidSelection: true });
                    this._filter(value);
                }
            }
        } else {
            // If it was a chosen Uri, simply reset errors an trigger onChange
            this._onValidValue(value);
        }
        this.stateChanges.next();
        // Always touched
        if (this._onTouched) {
            this._onTouched();
        }
    }

    private _onValidValue(value: Uri) {
        this._setErrors(null);
        if (this._onChange) {
            this._onChange(value);
        }
    }

    private _setErrors(errors) {
        this.inputControl.setErrors(errors);
        if (this._control) {
            this._control.setErrors(errors);
        }
    }

    _filter(value: string) {
        if (!this.currentDataSource) {
            return;
        }
        clearTimeout(this._filterTimeout);
        // @ts-ignore
        this._filterTimeout = setTimeout(() => {
            this.currentDataSource.filtros = [
                new FilterAndData(GeneralSearchFilter, value)
            ];
            this.currentDataSource.load();
        }, UriInputComponent.FILTER_TIMEOUT);
    }

    modelToUri(model: Resource): Uri {
        this._nameProvider.set(model.iri, this.currentInterface.getName(model));
        return model.iri;
    }

    displayOption(option: Uri | string | null) {
        if (typeof option === "string" && option !== "") {
            return option;
        }

        if (option instanceof Uri) {
            this._nameProvider
                .get(option, false)
                .then(name =>
                    this.inputControl.setValue(name, {
                        onlySelf: true,
                        emitEvent: false,
                        emitModelToViewChange: true,
                        emitViewToModelChange: false
                    })
                )
                .catch(error => console.error(error));
        }
        if (option instanceof Uri) {
            return option.id.join(";");
        } else {
            return option;
        }
    }

    selectAll(event: FocusEvent) {
        const ipt = event.currentTarget as HTMLInputElement;
        ipt.selectionStart = 0;
        ipt.selectionEnd = ipt.value.length;
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.inputControl.disable({ emitEvent: false });
        } else {
            this.inputControl.enable({ emitEvent: false });
        }
        this.stateChanges.next();
    }

    writeValue(obj: any): void {
        if (obj instanceof Uri) {
            const dataSource = this.dataSources.find(
                dS => dS.loader.entityInterface.serialiser.model === obj.model
            );
            if (dataSource) {
                this.selectControl.setValue(dataSource);
            }
        }
        this.inputControl.setValue(obj);
        this.stateChanges.next();
    }

    setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(" ");
    }

    onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() !== "input") {
            this.focusInput();
        }
    }

    focusInput() {
        this._elRef.nativeElement.querySelector("input").focus();
    }

    focusSelect() {
        this._matSelect.focus();
    }

    getName(model: Resource): string {
        return this.currentInterface.getName(model);
    }

    getContenido(model: Resource): MatOptionModel {
        return new MatOptionModel(model[this.contenido.titulo], model[this.contenido.segundaLinea],
                     model[this.contenido.matIcon], model[this.contenido.matIconColor]);
    }
}
