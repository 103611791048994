<form #form="ngForm" (ngSubmit)="login(form)">
    <mat-card class="login-card">
        <img class="login_logo" mat-card-image src="/assets/Logo_azul.svg">
        <mat-card-header>
            <mat-card-title></mat-card-title>
            <!--<mat-card-subtitle *ngIf="!sessionExpired">¡Hola! Inicia sesión con tu nombre de usuario</mat-card-subtitle>-->
            <mat-card-subtitle *ngIf="sessionExpired">
                <mat-icon>info</mat-icon>
                La sesión previa ha expirado
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <mat-form-field>
                <mat-label>E-mail</mat-label>
                <input matInput name="user" id="input-user" ngModel placeholder="E-mail" required>
                <mat-error *ngFor="let error of errors.username">{{error}}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Contraseña</mat-label>
                <input matInput name="password" type="password" id="input-password"
                       ngModel placeholder="Contraseña" required>
                <mat-error *ngFor="let error of errors.password">{{error}}</mat-error>
            </mat-form-field>
            <div class="info" *ngIf="capsLock">
                <mat-icon>warning</mat-icon>
                BloqMayús podría estar activado.
            </div>
            <div class="error" *ngFor="let error of errors.general">
                <mat-icon>error</mat-icon>
                {{error}}</div>
        </mat-card-content>

        <mat-card-actions>
            <button mat-raised-button type="submit" class="mat-primary full-width mb-16">Login</button>

        </mat-card-actions>
        <div class="links">
            <button mat-raised-button [routerLink]="'/password-recovery'" class="mat-accent">Olvidó la contraseña</button>
            <button mat-raised-button [routerLink]="'/registro'" class="mat-accent">Crear cuenta</button>
        </div>
    </mat-card>
</form>
