<ng-container [formGroup]="formGroup">
    <span class="prop">{{property.name}}</span>
    <div class="inputs">
        <mat-form-field class="comp">
            <mat-select formControlName="comp">
                <mat-option value="<"> &lt;</mat-option>
                <mat-option value="<="> &le;</mat-option>
                <mat-option value=">=">&ge;</mat-option>
                <mat-option value=">">&gt;</mat-option>
                <mat-option value="..">De .. A</mat-option>
            </mat-select>
        </mat-form-field>
        <be-input placeholder="" [formGroup]="formGroup" controlName="main" [property]="property" class="value"
                  forceRequired="false"></be-input>
        <be-input *ngIf="formGroup.get('comp').value === '..'"
                  placeholder="" [formGroup]="formGroup" controlName="other" [property]="property" class="value"
                  forceRequired="false"></be-input>
    </div>
</ng-container>
