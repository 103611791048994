import {Caster} from './caster';

/**
 * Allows decimal types to work (internally) with . or , irrelevantly, casting to . when obtaining from model
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */
export class DecimalCaster implements Caster<string, string> {
    constructor(private forceInternal: ','|'.'|null = null) {}

    fromModel(modelValue: string | number | null): string | null {
        if (modelValue === null) {
            return null;
        }
        return modelValue.toString().replace(/[,]/g, '.');
    }

    toModel(apiValue: string | number | null): string | null {
        if (apiValue === null) {
            return null;
        }
        return this.forceInternal ? apiValue.toString().replace(/[,.]/g, this.forceInternal) : apiValue.toString();
    }
}
