import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ItemsComponent} from './items/items.component';
import {MaterialModule} from "../../material/material.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {UserGroupsComponent} from './user-groups/user-groups.component';
import {UserGroupEditionComponent} from "../edition-list-dialog/user-group-edition.component";
import {UsuariosComponent} from './usuarios/usuarios.component';
import {BasicEntityFrontModule} from "../../basic-entity-front/basic-entity-front.module";
import {UsuariosEditionComponent} from "./usuarios/usuarios-edition/usuarios-edition.component";




@NgModule({
    declarations: [
        ItemsComponent,
        UserGroupsComponent,
        UserGroupEditionComponent,
        UsuariosComponent,
        UsuariosEditionComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MaterialModule,
        BasicEntityFrontModule,
        FormsModule
    ]
})
export class AdminModule {
}
