import {Caster} from './caster';

/**
 * Composes two casters to work chained
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */
export class CompoundCaster<T, U, V> implements Caster<T, V> {
    constructor(private _casterA: Caster<T, U>, private _casterB: Caster<U, V>) {}

    fromModel(modelValue: T | null): V | null {
        return this._casterB.fromModel(this._casterA.fromModel(modelValue));
    }

    toModel(apiValue: V | null): T | null {
        return this._casterA.toModel(this._casterB.toModel(apiValue));
    }
}
