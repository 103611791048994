<div>

    <div mat-dialog-content>
        <div style="display: flex;flex-direction: column;  max-width: 600px; padding: 1rem;">
            <h1 class="info" mat-dialog-title>{{data}}</h1>

        </div>
    </div>
    <div mat-dialog-actions>

        <button mat-button class="button" [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
    </div>
</div>
