import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'be-dialog-header',
    templateUrl: './dialog-header.component.html',
    styleUrls: ['./dialog-header.component.scss']
})
export class DialogHeaderComponent {
    @Input() public title = '';
    @Input() public closeButton = true;
    @Input() public revisado = false;
    @Output() public close = new EventEmitter<void>();
}
