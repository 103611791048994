import {Injectable} from '@angular/core';
import {InterfaceProviderService} from "../basic-entity-back/services/interface-provider.service";
import {SessionService} from "../session/session.service";
import {BaseEntity} from "../model/base-entity.model";
import {MatDialog} from "@angular/material/dialog";
import {ErrorDisplayService} from "../basic-entity-front/services/error-display.service";
import {Observable, Subject, Subscriber} from "rxjs";
import {SimpleDialogService} from "../basic-entity-front/dialog-shell/simple-dialog.service";
import {ApiService} from "../api/api.service";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class BloqueadorService {

    constructor(private _intf: InterfaceProviderService, private _sesssion: SessionService, private apiService: ApiService,
                private dialogService: MatDialog, private errorService: ErrorDisplayService, private simpleDialog: SimpleDialogService) {

    }

    bloquear(model: BaseEntity) {
        return new Observable((subscriber: Subscriber<any>) => {
            if (this.bloqueadoPorOtroOEnOtraTab(model)) {
                subscriber.next(null);
            } else {

                const modelNuevo = this.crearModelo(model, this._sesssion.token.username);

                this.apiService.patch(this._intf.interfaceForModel(model.modelType).endpointFor(model), modelNuevo).pipe(
                    ApiService.sTakeBody(),
                ).subscribe(result => {
                    result = this._intf.interfaceForModel(model.modelType).fromPutToModel(result);
                    if (result['bloqueadoPor'] !== null) {
                        if (!localStorage.getItem("bloqueos")) {
                            localStorage.setItem("bloqueos", "");
                        }
                        const contenidoActual = localStorage.getItem("bloqueos").split("_____");
                        const json = JSON.stringify({iri: result['iri'].toString(), tab: window['tabId']});
                        if (contenidoActual.indexOf(json) === -1) {
                            contenidoActual.push(json);
                            localStorage.setItem("bloqueos", contenidoActual.join("_____"));
                        }
                        subscriber.next(result);
                    }
                }, error => {
                    subscriber.next(null);
                    this.errorService.displayError(error);
                    this.dialogService.closeAll();
                    return null;
                });
            }
        });
    }

    desbloquear(model: BaseEntity) {
        return new Observable((subscriber: Subscriber<any>) => {
            if (!!model.bloqueadoPor) {
                const modelNuevo = this.crearModelo(model, null);

                this.apiService.patch(this._intf.interfaceForModel(model.modelType).endpointFor(model), modelNuevo).pipe(
                    ApiService.sTakeBody(),
                ).subscribe(result => {
                    result = this._intf.interfaceForModel(model.modelType).fromPutToModel(result);
                    if (localStorage.getItem("bloqueos")) {
                        const contenido = localStorage.getItem("bloqueos").split("_____").filter(valorStr => {
                            if (valorStr && valorStr !== '') {
                                const valor = JSON.parse(valorStr);
                                return valor['iri'] !== result['iri'].toString() || (valor['iri'] === result['iri'].toString() && valor['tab'] !== window['tabId']);
                            } else {
                                return false;
                            }
                        });
                        localStorage.setItem("bloqueos", contenido.join("_____"));
                    }
                    subscriber.next(result);
                }, error => {
                    // Si obtengo error 409, intento desbloquearla de nuevo teniendo en cuenta la nueva versión
                    let newModel = JSON.parse(error.error['hydra:new_object']);
                    newModel = this._intf.interfaceForModel(model.modelType).fromPutToModel(newModel);
                    this.desbloquear(newModel).subscribe(result => subscriber.next(result));
                    // this.errorService.displayError(error);
                });
            } else {
                subscriber.next(model);
            }
        });
    }


    crearModelo(model, bloqueadoPor) {
        let modelNuevo = this._intf.interfaceForModel(model.modelType).serialiser.getEmptyModel();
        modelNuevo['iri'] = model.iri;
        modelNuevo['bloqueadoPor'] = bloqueadoPor;
        modelNuevo['version'] = model['version'];
        modelNuevo = this._intf.interfaceForModel(model.modelType).forPut(modelNuevo);
        Object.keys(modelNuevo).forEach(key => {
            if (key !== '@id' && key !== 'bloqueadoPor' && key !== 'version') {
                delete modelNuevo[key];
            }
        });
        return modelNuevo;
    }

    bloqueado(model: BaseEntity): boolean {
        return model.bloqueadoPor && model.bloqueadoPor !== '';
    }

    bloqueadoPorOtro(model: BaseEntity): boolean {
        return !!model.bloqueadoPor && !localStorage.getItem("bloqueos")?.includes(model.iri.toString());
    }

    bloqueadoEnOtraTab(model: BaseEntity): boolean {
        const existeArray = localStorage.getItem("bloqueos")?.split("_____").filter(valorStr => {
            if (valorStr && valorStr !== '') {
                const valor = JSON.parse(valorStr);
                return valor['iri'] === model.iri.toString() && valor['tab'] !== window['tabId'];
            } else {
                return false;
            }
        });

        return !!model.bloqueadoPor && existeArray && existeArray.length > 0;
    }

    bloqueadoPorOtroOEnOtraTab(model) {
        if (this.bloqueadoPorOtro(model) || this.bloqueadoEnOtraTab(model)) {
            if (model.bloqueadoPor === this._sesssion.token.username) {
                this.errorService.displayRaw('Registro bloqueado por ti. Se procede a su desbloqueo. Antes de volver a abrirlo, asegúrate de cerrar todas las otras pestañas o habrá algún error.');
                this.desbloquear(model).subscribe(result => console.log(result));
            } else {
                this.errorService.displayRaw('Registro bloqueado por administración. Por favor, espere a que se libere');
            }
            return true;
        }
        return false;
    }

}
