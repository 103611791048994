import {Component, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";
import {SessionService} from "../session.service";
import {ApiService} from "../../api/api.service";
import {Provincia} from "../../model/localizacion/provincia.model";
import {Municipio} from "../../model/localizacion/municipio.model";
import {PersonaContacto} from "../../model/personas/contacto.model";
import {Usuario} from "../../model/personas/usuario.model";
import {MatDialog} from "@angular/material/dialog";
import {DialogSuccessComponent} from "../../pages/dialogoSuccess/dialogSuccess.component";

@Component({
  selector: 'app-datosPersonales',
  templateUrl: './datosPersonales.component.html',
  styleUrls: ['./datosPersonales.component.scss']
})
export class DatosPersonalesComponent implements OnInit {

    public nombreUsuario:string;
    public denominacionSocial:string;
    public cif:string;
    public cp:string;
    public direccion:string;
    public telefono:string;
    public mail:string;
    public provincia:string;
    public poblacion:string;
    public nombreContacto:string;
    public mailContacto:string;
    public telefonoContacto:string;
    public passContacto:string;
    public provincias = [];
    public provinciaSeleccionada;
    public municipios= [];
    public usuario: Usuario;
    public contacto:PersonaContacto;
    public errors: { general: string[],username: string[], password: string[] } = {general: [], username: [], password: []};
    public modificable:boolean;


    user= new UntypedFormControl('');

  constructor( protected loginService: SessionService,
               protected router: Router,
               private _activatedRoute: ActivatedRoute,
               private apiService: ApiService,
               private dialog : MatDialog) {
      this.user.setValue(loginService.token.username);
       this.nombreUsuario=loginService.token.username;
      this.errors.general.push('El campo es obligatorio.');

  }

  ngOnInit() {

      this.modificable=true;
      //Comprobamos que el usuario está logeado
      if(this.loginService.isLogged()){
          //Cargar select provincias
          this.apiService.get('/provincias?pais=ES').subscribe((result) => {
              this.provincias = result['body']['hydra:member'] as Array<Provincia>;
              }

          );
          //Recupera id de usuario
          this.apiService.get('/usuarios?username='+this.nombreUsuario).subscribe((result) => {
              this.usuario = result['body']['hydra:member'] as Usuario;
              //Recupera la empresa asociada al usuario
              if(this.usuario[0]!=null) {
                  // this.apiService.get('/empresas?usuario.id=' + this.usuario[0].id).subscribe((result) => {
                  //     this.empresa = result['body']['hydra:member'] as Empresa;
                  //
                  //     if (this.empresa[0] !== undefined && this.empresa[0]!==null) {
                  //
                  //         this.denominacionSocial = this.empresa[0].denominacionSocial;
                  //         this.cif = this.empresa[0].cifNif;
                  //         this.cp = this.empresa[0].cp;
                  //         this.direccion = this.empresa[0].direccion;
                  //         this.telefono = this.empresa[0].telefono;
                  //         this.mail = this.usuario[0].email;
                  //         this.provincia = this.empresa[0].provincia;
                  //         if(this.empresa[0].contacto!=null)
                  //         {
                  //             this.nombreContacto=this.empresa[0].contacto.nombre;
                  //             this.mailContacto = this.empresa[0].contacto.mail;
                  //             this.telefonoContacto = this.empresa[0].contacto.telefono;
                  //         }
                  //         //Cargar select poblacion
                  //         this.apiService.get('/municipios?provincia.nombreProvincia=' + this.empresa[0].provincia).subscribe((result) => {
                  //             this.municipios = result['body']['hydra:member'] as Array<Municipio>;
                  //
                  //         });
                  //         this.poblacion = this.empresa[0].poblacion;
                  //         /*     this.nombreContacto = this.empresa[0].contacto.nombre;
                  //              this.mailContacto = this.empresa[0].contacto.mail;
                  //              this.telefonoContacto = this.empresa[0].contacto.telefono;
                  //              this.passContacto = this.empresa[0].contacto.pass;*/
                  //     }
                  //
                  //
                  // });
              }
            });



      }
      else{
          this.errors.general.push('La sesión previa ha expirado.');
          this.router.navigateByUrl('login');
      }


  }

    onSubmit(form) {

        if(form.form.status==='INVALID')
            return;

        //crear empresa
        const empresa = {
            // 'id':this.empresa[0]["@id"],
            'usuario': this.usuario[0]["@id"],
            'denominacionSocial': form.form.value.denominacionSocial,
            'cifNif': form.form.value.cif,
            'provincia': form.form.value.provincia,
            'poblacion': form.form.value.poblacion,
            'cp': form.form.value.cp,
            'direccion':form.form.value.direccion,
            'telefono':form.form.value.telefono,
            'mail':form.form.value.mail,
            'contacto':{
                'nombre': form.form.value.contacto,
                "mail": form.form.value.mailContacto,
                "telefono": form.form.value.telefonoContacto
                }
        }
        //
        // if(this.empresa[0]["@id"]!=null || this.empresa[0]["@id"]!=undefined) {
        //
        //     this.apiService.put<Empresa>(this.empresa[0]["@id"], empresa).subscribe((result) => {
        //     const dialogSuccess = this.dialog.open(DialogSuccessComponent,{data:'Se han actualizado los datos correctamente'})
        //
        //     });
        // }
        // else {
        //
        //     this.apiService.post<Empresa>('/empresas', empresa).subscribe((result) => {
        //         const dialogSuccess = this.dialog.open(DialogSuccessComponent,{data:'Se han actualizado los datos correctamente'})
        //
        //     });
        //
        // }




       /* if(this.empresa[0].contacto!=null && this.empresa[0].contacto!=undefined) {
            //crear contacto
            const contacto = {
                'id':this.empresa[0].contacto.id,
                'nombre': form.form.value.contacto,
                'mail': form.form.value.email_contacto,
                'telefono': form.form.value.tel_contacto,
                'pass': form.form.value.pass
            }

            this.apiService.put<PersonaContacto>('/persona_contactos/'+contacto.id, contacto).subscribe((result) => {


            });
        }
        /*else {

            this.apiService.post<PersonaContacto>('/persona_contactos', contacto).subscribe((result) => {
                this.contacto = result['body'] as PersonaContacto;

                empresa.contacto=this.contacto["@id"];
                this.apiService.put<Empresa>(this.empresa[0]["@id"], empresa).subscribe((result) => {


                });

            });

        }*/


  }

    seleccionProvincia(codProv) {

        //Cargar select poblacion
           this.apiService.get('/municipios?provincia.nombreProvincia='+codProv).subscribe((result) => {
               this.municipios = result['body']['hydra:member'] as Array<Municipio>;

           });



    }

    seleccionMunicipio(idMunicipio) {




    }
    setModificable()
    {
       if(this.modificable)
            this.modificable=false;
    }

}

