import {Component, HostListener, OnInit} from '@angular/core';
import {AbstractControl, Form, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {BehaviorSubject, Subject} from "rxjs";
import {ApiService} from "../../api/api.service";
import {SessionService} from "../session.service";
import {InterfaceProviderService} from "../../basic-entity-back/services/interface-provider.service";
import {Usuario} from "../../model/personas/usuario.model";
import {Router} from "@angular/router";
import {ErrorDisplayService} from "../../basic-entity-front/services/error-display.service";

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

    formGroup: UntypedFormGroup;
    public capsLock = false;

    private readonly _IS_MAC = /Mac/.test(navigator.platform);
    public errors = new BehaviorSubject([]);

    constructor(private fb: UntypedFormBuilder, private _api: ApiService,
                private errorService: ErrorDisplayService,
                private _session: SessionService,
                private router: Router,
                private _intf: InterfaceProviderService) {
    }

    ngOnInit(): void {
        this.formGroup = new UntypedFormGroup({
            password: new UntypedFormControl('', Validators.required),
            new_password: new UntypedFormControl('', Validators.required)
        }, {validators: [this.validarPassword]})
    }

    validarPassword(c: AbstractControl): { invalid: boolean } {
        if (c.get('password').value !== c.get('new_password').value) {
            return {invalid: true};
        }
    }

    @HostListener('window:keydown', ['$event'])
    public detectCapsLock(event: KeyboardEvent) {
        if (event.getModifierState) {
            this.capsLock = event.getModifierState('CapsLock');
        } else {
            const charCode = event.charCode;
            const shiftKey = event.shiftKey;

            if (charCode >= 97 && charCode <= 122) {
                this.capsLock = shiftKey;
            } else if (charCode >= 65 && charCode <= 90 && !(shiftKey && this._IS_MAC)) {
                this.capsLock = !shiftKey;
            }
        }
    }

    public sendNewPass() {
        const user = this._session.user;
        this.formGroup.updateValueAndValidity();
        if(this.formGroup.valid) {
            this._api.post(`/password_recovery/new_credentials`,
                {userId: user.id.toString(), password: this.formGroup.get('password').value}).subscribe(result => {
                if(result.status === 201) {
                    user.passwordValidUntil = null;
                    this.errorService.displayRaw("Contraseña cambiada correctamente", 2000)
                    this.router.navigate(['/welcome']).then();
                }
            });
        }
    }
}
