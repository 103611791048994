import {Component} from '@angular/core';
import {BasicEntityTableColumnDescriptionOrString} from "../../../basic-entity-front/basic-entity-table/basic-entity-table.component";
import {Municipio} from "../../../model/localizacion/municipio.model";
import {MunicipioEditionComponent} from './municipio-edition/municipio-edition.component';

@Component({
    selector: 'app-municipio',
    template: '<be-table [columnsToDisplay]="columns" [modelType]="model" [editionDialog]="dialog"></be-table>'
})
export class MunicipioComponent {
    public model = Municipio;
    public dialog = MunicipioEditionComponent;
    public columns: BasicEntityTableColumnDescriptionOrString[] = [
        'id',
        'nombre',
        'alias',
        'provincia',
        {key: 'zonaPropia', hiddenDisplayable: true},
        'zona',
        {key: 'festivosPropios', hidden: true},
        {key: 'festivosHeredados', hidden: true}
    ];

}
