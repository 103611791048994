import {Component} from '@angular/core';
import {BasicEntityTableColumnDescriptionOrString} from "../../../basic-entity-front/basic-entity-table/basic-entity-table.component";
import {UserGroup} from "../../../model/personas/user-group.model";
import {UserGroupEditionComponent} from "../../edition-list-dialog/user-group-edition.component";

@Component({
    selector: 'app-user-groups',
    template: '<be-table [columnsToDisplay]="columns" [modelType]="model" [editionDialog]="dialog"></be-table>'
})
export class UserGroupsComponent {
    public model = UserGroup;
    public columns: BasicEntityTableColumnDescriptionOrString[] = [
        'id', 'name', 'roles'
    ];
    public dialog = UserGroupEditionComponent;
}
