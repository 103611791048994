import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {Resource} from "../../api/resource";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";

@BasicEntity({
    name: 'Grupos de usuarios',
    endpoint: 'user_groups',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    modelNameProperty: 'name',
    acceptsBulkIriSearch: true,
    type: 'UserGroup'
})
export class UserGroup extends Resource {
    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer()
    })
    private _id: number;

    @BasicProperty({
        name: 'Name',
        type: PropertyType.String()
    })
    private _name: string;

    @BasicProperty({
        name: 'Roles',
        type: PropertyType.String(),
        array: true
    })
    private _roles: string[];

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get roles(): string[] {
        return this._roles;
    }

    set roles(value: string[]) {
        this._roles = value;
    }
}
