import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from "../../material/material.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BasicEntityFrontModule} from "../../basic-entity-front/basic-entity-front.module";
import {RegistrosEmpleadosComponent} from './registros-empleados/registros-empleados.component';
import {MatRadioModule} from "@angular/material/radio";
import { ClienteEditionComponent } from './clientes/cliente-edition/cliente-edition.component';

@NgModule({
    declarations: [
        RegistrosEmpleadosComponent,
        ClienteEditionComponent
    ],
    imports: [
        CommonModule,
        BasicEntityFrontModule,
        ReactiveFormsModule,
        MaterialModule,
        FormsModule,
        MatRadioModule
    ],
    exports: []
})
export class PersonasModule {
}
