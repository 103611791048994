<be-dialog-shell [title]="title" (close)="closeDialog()" [closeButton]="!saving" [showFooter]="showSaveAndCancelButtons"
                 [className]="tags.size > 1 ? 'fixed-size' : null">
    <form autocomplete="off" (submit)="$event.preventDefault();" [formGroup]="formGroup">
        <mat-tab-group *ngIf="tags.size > 1">
            <mat-tab *ngFor="let tag of tags" [label]="tag">
                <div class="tab-content">
                    <be-edition-tab [propertiesToShow]="colsForTag(tag)" [formGroup]="formGroup"
                                    [formManager]="formManager"></be-edition-tab>
                </div>
            </mat-tab>
        </mat-tab-group>
    </form>
    <dialog-actions>
        <button mat-button (click)="closeDialog()" [disabled]="saving">Cancelar</button>
        <button mat-flat-button *ngIf="shouldManageSaving && modified" (click)="acceptDialog()" color="primary"
                [disabled]="!formGroup.valid || saving">
            <mat-icon>save</mat-icon>
            Guardar
        </button>
        <button mat-button *ngIf="!(shouldManageSaving && modified)" (click)="acceptDialog()"
                [disabled]="modified && !formGroup.valid">Aceptar
        </button>
    </dialog-actions>
</be-dialog-shell>
