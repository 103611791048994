<form [formGroup]="formGroup">
    <mat-card class="login-card">
        <mat-card-content class="contenido">
            <mat-form-field>
                <mat-label>Contraseña</mat-label>
                <input matInput type="password" placeholder="Contraseña" required formControlName="password">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Repetir Contraseña</mat-label>
                <input matInput type="password" placeholder="Repetir contraseña" required formControlName="new_password">
            </mat-form-field>
            <div class="error" *ngIf="formGroup.errors">Los passwords no coinciden</div>
            <div class="info" *ngIf="capsLock">
                <mat-icon>warning</mat-icon>
                BloqMayús podría estar activado.
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button [disabled]="formGroup.invalid" mat-raised-button (click)="sendNewPass()" class="mat-primary full-width mb-16">Cambiar Contraseña</button>
        </mat-card-actions>
    </mat-card>
</form>
