import { Component, OnInit } from '@angular/core';
import {EditionDialogComponent} from "../../../basic-entity-front/edition-dialog/edition-dialog.component";
import {Tornillo} from "../../../model/almacen/tornillo.model";
import {BasicEntityTableColumnDescriptionOrString} from "../../../basic-entity-front/basic-entity-table/basic-entity-table.component";

@Component({
  selector: 'app-tornillos',
  template: '<be-table [modelType]="model" [editionDialog]="dialog" [columnsToDisplay]="columns"></be-table>',
  styles: [
  ]
})
export class TornillosComponent {

    model = Tornillo;
    dialog = EditionDialogComponent

    columns: BasicEntityTableColumnDescriptionOrString[] = [
        "id",
        "referencia",
        "referenciaFabricante",
        "modelo",
        "dinamico",
        "tipoLlave",
        "plataforma",
        "fabricante",
        "asiento",
        "precioCompraUnitario",
        "precioVentaUnitario",
        "stock",
        "stockMinimo",
        "almacenX",
        "almancenY"
    ]
  constructor() { }

}
