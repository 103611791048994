import {Caster} from './caster';
import {JsonLdInterface} from '../../api/entity-interface';
import {Resource} from '../../api/resource';
import {BasicEntityInterface} from '../basic-entity-interface/basic-entity-interface';
import {NestedBehavior} from "../property-type/nested-model-type";
import {IRI_PROPERTY} from "../basic-entity-interface/mapping-internal";

/**
 * Casts nested models to be model instances
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */
export class NestedModelCaster<T extends Resource> implements Caster<T, JsonLdInterface | string> {
    constructor(public readonly modelInterface: BasicEntityInterface<T>, public readonly nestedBehavior: NestedBehavior = NestedBehavior.PickOnly) {
    }

    fromModel(modelValue: T, removeIds: boolean = false): JsonLdInterface | string {
        if (!modelValue) {
            return null;
        }
        if (this.nestedBehavior === NestedBehavior.SendIriOnly) {
            return this.modelInterface.serialiser.getValue(modelValue, IRI_PROPERTY).toString();
        } else {
            return this.modelInterface.serialiser.toApi(modelValue, removeIds);
        }
    }

    toModel(apiValue: JsonLdInterface): T {
        if (!apiValue) {
            return null;
        }
        return this.modelInterface.serialiser.fromApi(apiValue);
    }

    getNestedBehavior() {
        return this.nestedBehavior;
    }
}
