import {Injectable} from '@angular/core';
import {TokenService} from "../api/session/token.service";
import {Token} from "../api/session/token";
import {Persona} from "../model/personas/persona.model";
import {ApiService} from "../api/api.service";
import {InterfaceProviderService} from "../basic-entity-back/services/interface-provider.service";
import {JsonLdInterface} from "../api/entity-interface";
import {Usuario} from "../model/personas/usuario.model";
import {BehaviorSubject, Observable, of, Subject} from "rxjs";
import {map, switchMap, tap} from "rxjs/operators";
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    private _sessionProfile: Persona | Usuario | null = null;
    private _sessionProfileBS = new BehaviorSubject(this._sessionProfile);
    private _profileChanges = new Subject<void>();
    private _requestProfileUpdate = new Subject<void>();
    public profileChanges = this._profileChanges.asObservable();

    public get token(): Token {
        return this._tokenService.token;
    }

    public get mercureToken(): string {
        return this._tokenService.mercureToken;
    }

    public get profile(): Persona | Usuario | null {
        return this._sessionProfile;
    }

    public get loggedIn(): boolean {
        return this._tokenService.checkLogin();
    }

    public get profileAsObservable(): Observable<Persona | Usuario | null> {
        if (this.profile) {
            this._sessionProfileBS.next(this.profile);
        }
        return this._sessionProfileBS.asObservable();
    }


    public get user(): Usuario | null {
        if (this._sessionProfile == null) {
            return null;
        } else if (this._sessionProfile instanceof Persona) {
            return this._sessionProfile.usuario;
        } else {
            return this._sessionProfile;
        }
    }

    constructor(private _tokenService: TokenService,
                private _interfaceProvider: InterfaceProviderService,
                private _router: Router,
                private _api: ApiService) {
        this._tokenService.sessionChanges.subscribe(() => {
            this._redirectIfTokenExpired();
            this._requestProfileUpdate.next();
        });
        this._initProfileUpdateTracking();
        this._requestProfileUpdate.next();
    }

    private _initProfileUpdateTracking() {
        this._requestProfileUpdate.pipe(
            switchMap(() => {
                if (this.isLogged()) {
                    return this._api.get<JsonLdInterface>('me')
                        .pipe(ApiService.sTakeBody(),
                            map(response => {
                                if (response['baja']) {
                                    return null;
                                }
                                const interf = this._interfaceProvider.interfaceForObject(response);
                                const res = interf ? interf.fromGetToModel(response) : null;
                                return res;
                            }));
                } else {
                    return of(null);
                }
            })
        ).subscribe(profile => {
            if (!profile && this._tokenService.token.token != null) {
                this._tokenService.logout();
                this._router.navigateByUrl('/login').then();
            }
            this._sessionProfile = profile;
            this._sessionProfileBS.next(profile);
            this._profileChanges.next();
        });
    }

    /**
     * Replace the current session by the provided token
     */
    public useToken(token: string) {
        this._tokenService.useToken(token);
    }

    public hasRole(role: string): boolean {
        return this._tokenService.token.roles.includes(role);
    }

    public get sessionChanges() {
        return this._tokenService.sessionChanges;
    }

    public login(user: string, password: string): Promise<Token> {
        return this._tokenService.login({username: user, password: password});
    }

    public logout(): void {
        this._tokenService.logout();
    }

    public isLogged(): boolean {
        return this._tokenService.checkLogin();
    }

    public changeProfile(profile: Persona | Usuario): Promise<any> {
        return this._interfaceProvider
            .managerForResource(profile)
            .update(profile)
            .pipe(tap(() => this._requestProfileUpdate.next()))
            .toPromise();
    }

    private _redirectIfTokenExpired() {
        if (this.token.isExpired()) {
            setTimeout(() => this._router.navigateByUrl('/login', /* Removed unsupported properties by Angular migration: queryParams. */ {})
                .catch(err => console.error(err)));
        }
    }
}
