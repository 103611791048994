import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DatosPersonalesComponent} from "../../session/datosPersonales/datosPersonales.component";

@Component({
    selector: 'app-dialogconfirm',
    templateUrl: './dialogConfirm.component.html',
    styleUrls: ['./dialogConfirm.component.scss']
})
export class DialogConfirmComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<DialogConfirmComponent>,
        @ Inject(MAT_DIALOG_DATA) public data: boolean) {}

    ngOnInit() {
    }

    cancelar() {
        this.dialogRef.close();
    }


}
