import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MenuComponent} from "./menu/menu.component";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import {AppRoutingModule} from "../app-routing.module";
import {HeaderComponent} from './header/header.component';
import {ReactiveFormsModule} from "@angular/forms";
import {LayoutModule} from "@angular/cdk/layout";
import {FooterComponent} from './footer/footer.component';
import {MatMenuModule} from "@angular/material/menu";
import {SidenavHeaderComponent} from "./sidenavHeader/sidenavHeader.component";

@NgModule({
    declarations: [
        SidenavHeaderComponent,
        MenuComponent,
        HeaderComponent,
        FooterComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatInputModule,
        MatExpansionModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        MatSidenavModule,
        MatToolbarModule,
        MatListModule,
        AppRoutingModule,
        LayoutModule,
        MatMenuModule,
    ],
    exports: [
        SidenavHeaderComponent,
        MenuComponent,
        HeaderComponent,
        FooterComponent
    ]
})
export class ShellModule {
}
