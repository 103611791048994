import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, Validators} from "@angular/forms";
import {InternalPropertyMap} from "../../../basic-entity-back/basic-entity-interface/mapping-internal";
import {Subscription} from "rxjs";
import {FilterDefinition} from "../../../basic-entity-back/basic-entity-interface/mapping-external";
import {FilterAndData} from "../../../api/filter-list";
import {Filter} from "../../../api/filter-interface";
import {TypeStr} from "../../../basic-entity-back/property-type/type-str";
import {InterfaceProviderService} from "../../../basic-entity-back/services/interface-provider.service";
import {
    EndSearchFilter,
    ExactSearchFilter,
    MultipleSearchFilter,
    PartialSearchFilter,
    SimpleSearchFilter,
    StartSearchFilter
} from "../../../basic-entity-back/filters/search-filter";
import {NumericFilter} from "../../../basic-entity-back/filters/numeric-filter";

@Component({
    selector: 'be-default-filtering',
    templateUrl: './default-filtering.component.html',
    styleUrls: ['./default-filtering.component.scss']
})
export class DefaultFilteringComponent implements OnInit, OnDestroy {
    @Input() control: UntypedFormControl;
    @Input() filter: FilterDefinition;
    @Input() property: InternalPropertyMap;

    public formGroup;

    public get afterProperty(): string {
        switch (this.filter) {
            case MultipleSearchFilter:
            case NumericFilter:
            case ExactSearchFilter:
                return ' (Igual a)';
            case StartSearchFilter:
                return ' (Empieza con)';
            case EndSearchFilter:
                return ' (Termina con)';
            case SimpleSearchFilter:
            case PartialSearchFilter:
                return ' (Contiene)';
            default:
                return ':';
        }

    }

    private _subscription: Subscription;

    constructor(fb: UntypedFormBuilder, private _interfaceProvider: InterfaceProviderService) {
        this.formGroup = fb.group({control: fb.control(null)});
    }

    ngOnInit() {
        if (Array.isArray(this.filter)) {
            console.error('DefaultFilteringComponent cannot manage groups of filters, just one', this.filter);
        } else {
            if (this.control.value) {
                const data = (this.control.value as FilterAndData).data;
                if (this.property.type.toString() === TypeStr.NestedModel) {
                    const manager = this._interfaceProvider.managerForModel(this.property.type.asNestedModel().modelType);
                    manager.get(data.toString()).then(model =>
                        (this.formGroup.get('control') as UntypedFormControl)
                            .setValue(model, {emitEvent: false}));
                } else {
                    (this.formGroup.get('control') as UntypedFormControl)
                        .setValue(data, {emitEvent: false});
                }
            }
            this._subscription = this.formGroup.get('control').valueChanges.subscribe(v => {
                this.control.setValue(new FilterAndData(this.filter as Filter, v, this.property));
            });
        }
    }

    ngOnDestroy(): void {
        this._subscription && this._subscription.unsubscribe();
    }
}
