import {Component, DoCheck, Input, IterableDiffer, IterableDiffers, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {ReplaySubject} from "rxjs";
import {Router} from "@angular/router";
import { MatExpansionPanel } from "@angular/material/expansion";
import {HttpParams} from "@angular/common/http";
import {SessionService} from "../../session/session.service";
import {Usuario} from "../../model/personas/usuario.model";
import {Persona} from "../../model/personas/persona.model";

export const MENU_ENDPOINT = 'items';

export interface MenuItem {

}

@Component({
    selector: 'app-sidenavHeader',
    templateUrl: './sidenavHeader.component.html',
    styleUrls: ['./sidenavHeader.component.scss']
})
export class SidenavHeaderComponent {
    private static nextId = 0;
    private _id;

    constructor(private _router: Router,private _sesion:SessionService) {
    }

    public logout() {
        this._sesion.logout();
        this._router.navigateByUrl('/');
        window.location.reload();

    }

    public get profileName() {
        const profile = this._sesion.profile;
        if (profile instanceof Usuario) {
            return profile.username;
        } else if (profile instanceof Persona) {
            if(profile.nombre !== null || profile.apellido1 !== null) {
                return `${profile.nombre} ${profile.apellido1}`
            } else {
                return profile.usuario.username;
            }
        }
        return null;
    }
}
