import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {Empleado} from "./empleado.model";
import {Uri} from "../../api/uri";
import * as moment from "moment";
import {ExactSearchFilter} from "../../basic-entity-back/filters/search-filter";
import {DateFilters} from "../../basic-entity-back/filters/date-filter";
import {Resource} from "../../api/resource";

@BasicEntity({
    name: 'Registros de empleados',
    endpoint: 'registro_empleados',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    acceptsBulkIriSearch: true,
    type: 'RegistroEmpleado'
})
export class RegistroEmpleado extends Resource {
    @BasicProperty({
        name: 'ID',
        type: PropertyType.Integer(),
        filters: [ExactSearchFilter],
        isId: true
    })
    private _id: number;

    @BasicProperty({
        name: 'Empleado',
        filters: [ExactSearchFilter],
        type: PropertyType.Uri(Empleado)
    })
    private _empleado: Uri;

    @BasicProperty({
        name: 'Fecha y hora',
        sortable: true,
        filters: [DateFilters, ExactSearchFilter],
        type: PropertyType.DateTime()
    })
    private _timestamp: moment.Moment = moment();

    @BasicProperty({
        name: 'Acción',
        filters: [ExactSearchFilter],
        type: PropertyType.Choice('entrada', 'salida')
    })
    private _accion: string;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get empleado(): Uri {
        return this._empleado;
    }

    set empleado(value: Uri) {
        this._empleado = value;
    }

    get timestamp(): moment.Moment {
        return this._timestamp;
    }

    set timestamp(value: moment.Moment) {
        this._timestamp = value;
    }

    get accion(): string {
        return this._accion;
    }

    set accion(value: string) {
        this._accion = value;
    }
}
