import {Telefono} from "./telefono.model";
import {PropertyMap, ReadWrite} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";

@BasicEntity({
    name: 'Teléfonos',
    endpoint: 'telefono_personas',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    modelNameProperty: 'completo',
    type: 'TelefonoPersona'

})
export class TelefonoPersona extends Telefono {
    public static readonly PERSONA_MAP: PropertyMap = {
        type: PropertyType.FutureUri('persona.model.ts'),
        readWrite: ReadWrite.ReadOnly
    };
    @BasicProperty(TelefonoPersona.PERSONA_MAP)
    private _persona;

    @BasicProperty({
        name: 'Departamento',
        type: PropertyType.String(),
        nullable: true
    })
    private _departamento: string;

    get persona() {
        return this._persona;
    }

    set persona(value) {
        this._persona = value;
    }

    get departamento(): string {
        return this._departamento;
    }

    set departamento(value: string) {
        this._departamento = value;
    }
}
