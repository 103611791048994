import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {Resource} from "../../api/resource";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {ReadWrite, SearchType} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {Uri} from "../../api/uri";
import {Pais} from "./pais.model";
import {Zona} from "./zona.model";
import {Festivo} from "./festivo.model";

/**
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */
@BasicEntity({
    name: 'Comunidades',
    endpoint: 'comunidads',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    autogeneratedId: false,
    modelNameProperty: 'nombre',
    acceptsBulkIriSearch: true,
    type: 'Comunidad'
})
export class Comunidad extends Resource {
    @BasicProperty({
        name: 'País',
        isId: true,
        type: PropertyType.Uri(Pais),
        sortable: true,
        searchable: SearchType.Exact
    })
    private _pais: Uri;

    @BasicProperty({
        name: 'Código',
        isId: true,
        type: PropertyType.String(),
        sortable: true,
        searchable: SearchType.Exact
    })
    private _codigoComunidad: string;

    @BasicProperty({
        keyInApi: 'nombreComunidad',
        name: 'Nombre',
        type: PropertyType.String(),
        sortable: true,
        searchable: SearchType.Partial
    })
    private _nombre: string;

    @BasicProperty({
        name: 'Zona propia',
        nullable: true,
        type: PropertyType.Uri(Zona)
    })
    private _zonaPropia: Uri;

    @BasicProperty({
        paramName: 'zona.id',
        name: 'Zona',
        nullable: true,
        readWrite: ReadWrite.ReadOnly,
        type: PropertyType.Uri(Zona),
        sortable: true,
    })
    private _zona;

    @BasicProperty({
        tag: 'Festivos',
        name: 'Festivos propios',
        type: PropertyType.NestedModel(Festivo),
        array: true
    })
    private _festivosPropios: object = [];

    @BasicProperty({
        tag: 'Festivos',
        name: 'Festivos heredados',
        type: PropertyType.NestedModel(Festivo),
        readWrite: ReadWrite.ReadOnly,
        array: true
    })
    private _festivosHeredados: object = [];

    get pais(): Uri {
        return this._pais;
    }

    set pais(value: Uri) {
        this._pais = value;
    }

    get codigoComunidad(): string {
        return this._codigoComunidad;
    }

    set codigoComunidad(value: string) {
        this._codigoComunidad = value;
    }

    get nombre(): string {
        return this._nombre;
    }

    set nombre(value: string) {
        this._nombre = value;
    }

    get zonaPropia() {
        return this._zonaPropia;
    }

    set zonaPropia(value) {
        this._zonaPropia = value;
    }

    get zona() {
        return this._zona;
    }

    set zona(value) {
        this._zona = value;
    }

    get festivosPropios(): object {
        return this._festivosPropios;
    }

    set festivosPropios(value: object) {
        this._festivosPropios = value;
    }

    get festivosHeredados(): object {
        return this._festivosHeredados;
    }

    set festivosHeredados(value: object) {
        this._festivosHeredados = value;
    }
}
