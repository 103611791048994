import {Persona} from "./persona.model";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {Uri} from "../../api/uri";
import {PropertyMap} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {PartialSearchFilter} from "../../basic-entity-back/filters/search-filter";

@BasicEntity({
    name: 'Empleados',
    endpoint: 'empleados',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    modelNameProperty: 'nombreCompleto',
    acceptsBulkIriSearch: true,
    type: 'Empleado'
})
export class Empleado extends Persona {
    constructor(iri?: Uri, entityType?: string) {
        super(iri, entityType);
    }

    @BasicProperty({
        name: 'Cargo',
        type: PropertyType.String(),
        filters: [PartialSearchFilter],
        sortable: true
    })
    private _cargo: string;

    // public static readonly CENTRO_MAP: PropertyMap = {
    //     name: 'Centro',
    //     type: PropertyType.FutureUri('centro-produccion.model.ts'),
    //     array: true
    // }
    // @BasicProperty(Empleado.CENTRO_MAP)
    // private _centros;

    get cargo(): string {
        return this._cargo;
    }

    set cargo(value: string) {
        this._cargo = value;
    }

    // get centros() {
    //     return this._centros;
    // }
    //
    // set centros(value) {
    //     this._centros = value;
    // }
}
