import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatProgressBar} from '@angular/material/progress-bar';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../../api/api.service";
import {Usuario} from "../../model/personas/usuario.model";
import {Subscription} from "rxjs";
import {InterfaceProviderService} from "../../basic-entity-back/services/interface-provider.service";
import {Cliente} from "../../model/personas/cliente.model";
import {Uri} from "../../api/uri";
import {Idioma} from "../../model/localizacion/idioma.model";
import {ErrorDisplayService} from "../../basic-entity-front/services/error-display.service";

@Component({
    selector: 'app-registro',
    templateUrl: './registro.component.html',
    styleUrls: ['./registro.component.scss']
})
export class RegistroComponent implements OnInit, OnDestroy {

    @ViewChild(MatProgressBar) progressBar: MatProgressBar;
    @ViewChild(MatButton) submitButton: MatButton;

    public errors: { general: string[], username: string[], password: string[], mail: string[] } =
        {general: [], username: [], password: [], mail: []};
    public success: string;

    signupForm: UntypedFormGroup;

    public usuario: Usuario;

    private subscriptions: Subscription[] = [];

    constructor(protected router: Router,
                private errorDisplayService: ErrorDisplayService,
                private _int: InterfaceProviderService,
                private _activatedRoute: ActivatedRoute,
                public api: ApiService) {
    }

    ngOnInit() {
        this.success = '';
        this.signupForm = new UntypedFormGroup({
            email: new UntypedFormControl('', Validators.required),
            password: new UntypedFormControl('', Validators.required),
            confirmPassword: new UntypedFormControl('', Validators.required),
            agreed: new UntypedFormControl('', Validators.requiredTrue)
        })
    }

    signup(form) {
        if (form.form.value.password === form.form.value.confirmPassword) {
            const clientManager = this._int.managerForModel(Cliente);
            const signupData = this.signupForm.value;
            const cliente = clientManager.loader.entityInterface.serialiser.getEmptyModel();
            cliente.idioma = new Uri(['es'], this._int.interfaceForModel(Idioma).uriPattern, Idioma)
            cliente.nombre = signupData.usuario;
            cliente.email = signupData.email;
            const user = new Usuario();
            user.username = signupData.email;
            // user.email = signupData.email;
            user.plainPassword = signupData.password;
            cliente.usuario = user;
            this.subscriptions.push(clientManager.add(cliente).subscribe(respuesta => {
                    this.errorDisplayService.displayRaw("Registro efectuado correctamente. Revise su email y active su cuenta.")
                    this.router.navigateByUrl('/login').then();
                },
                error => {
                    this.errorDisplayService.displayError(error);
                    console.log(error)
                }));
        } else {
            if (this.errors.password.length > 0)
                this.errors.password.pop();
            this.errors.password.push('Las contraseñas no coinciden');
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subs => subs.unsubscribe());
    }
}
