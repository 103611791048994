import {Component, DoCheck, Input, IterableDiffer, IterableDiffers, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {ReplaySubject} from "rxjs";
import {Router} from "@angular/router";
import {MatExpansionPanel} from "@angular/material/expansion";
import {HttpParams} from "@angular/common/http";
import {SessionService} from "../../session/session.service";
import {Usuario} from "../../model/personas/usuario.model";
import {Persona} from "../../model/personas/persona.model";
import {ApiInterface} from "../../api/api-interface";

export const MENU_ENDPOINT = 'items';

export interface MenuItem {
    id: number;
    name: string;
    link?: string | null;
    icono?: string | null;
    queryParams: string | null;
    requiredRole: string;
    orden?: string | null;
    children: MenuItem[];
}

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements DoCheck, OnInit {
    private static nextId = 0;
    private _id;

    @Input() public items: MenuItem[];
    @Output() public itemClicked$ = new ReplaySubject<void>();

    @ViewChildren(MatExpansionPanel) private _panels: QueryList<MatExpansionPanel>;

    private _expandableItems: MenuItem[];

    private _finalItems: MenuItem[];
    private _iterableDiffer: IterableDiffer<MenuItem>;


    constructor(private _iterableDiffers: IterableDiffers, private _router: Router, private _sesion: SessionService) {
        this._expandableItems = [];
        this._finalItems = [];
        this.items = [];
        this._id = MenuComponent.nextId++;

    }

    ngOnInit(): void {

        this._iterableDiffer = this._iterableDiffers.find(this.items).create(MenuComponent.sTrackItemsBy);


    }

    static sTrackItemsBy(index: number, item: MenuItem) {

        return item.id;
    }

    ngDoCheck(): void {
        const changes = this._iterableDiffer.diff(this.items);
        if (changes) {
            let finalsChanged = false;
            changes.forEachRemovedItem(rec => {
                let idx = this._expandableItems.findIndex(it => it.id === rec.trackById);
                if (idx >= 0) {
                    this._expandableItems.splice(idx, 1);
                    return;
                }
                idx = this._finalItems.findIndex(it => it.id === rec.trackById);
                if (idx >= 0) {
                    this._finalItems.splice(idx, 1);
                    finalsChanged = true;
                }
            });
            changes.forEachAddedItem(rec => {
                this._addCounting(rec.item);
                finalsChanged = finalsChanged || rec.item.children.length === 0;
            });
        }
    }

    private _addCounting(item: MenuItem) {
        let index = 0;
        let comp = (next) => next.children.length === 0;
        if (item.children.length > 0) {
            comp = (next) => next.children.length > 0;
        }
        for (const next of this.items) {
            if (next.id === item.id) {
                break;
            }
            if (comp(next)) {
                index++;
            }
        }
        if (item.children.length > 0) {
            this._expandableItems.splice(index, 0, item);
        } else {
            this._finalItems.splice(index, 0, item);
        }
    }

    public get expandableItems(): MenuItem[] {
        return this._expandableItems;
    }

    public get finalItems(): MenuItem[] {
        return this._finalItems;
    }

    public queryParams(query: string | null) {
        if (query) {
            const params = new HttpParams({fromString: query});
            const obj = {};
            for (const key of params.keys()) {
                obj[key] = params.getAll(key);
            }
            return obj;
        }
        return undefined;
    }

    public trackByFn(index: number, item: MenuItem) {
        return MenuComponent.sTrackItemsBy(index, item);
    }
}
