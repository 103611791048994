/**
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */

import {Resource} from "../../api/resource";
import {FilterList} from "../../api/filter-list";
import {InterfaceMapping, InternalPropertyMap} from "./mapping-internal";
import {Filter, FilterInterface} from "../../api/filter-interface";

/**
 * This class manages all that is relative to the filtering (filters, search, order...) for the BasicEntityInterface
 */
export class BasicEntityFilterManager<ModelType extends Resource> {
    private _filters = new Map<Filter, FilterInterface>();

    constructor(public readonly mappingModelToApi: InterfaceMapping) {
    }

    public canSortBy(property?: string): boolean {
        if (!property) {
            return true;
        }
        const mapped = this.mappingModelToApi[property];
        return mapped && mapped.sortable;
    }

    public paramToSort(property?: string): string|null {
        if (!property) {
            return null;
        }
        const mapped = this.mappingModelToApi[property];
        if (!(mapped && mapped.sortable)) {
            throw Error(`Tried to get the sort by model key ${property}, but it is not a sortable property in ${this.constructor.name}`);
        }
        return mapped.paramName;
    }

    public filtersToParams(filters: FilterList): { [p: string]: string | string[] } {
        const params = {};
        filters.forEach(valuatedFilter => {
            let property: InternalPropertyMap | null = null;
            if (valuatedFilter.property) {
                // To check it belongs to us
                property = this.mappingModelToApi[valuatedFilter.property.modelKey];
                if (!property || property !== valuatedFilter.property) {
                    throw new Error(`The property ${valuatedFilter.property} does not exist in this entity.`);
                }
            }
            try {
                const newParams = this.getFilter(valuatedFilter.filter).dataToParams(property, valuatedFilter.data);
                for (const key of Object.keys(newParams)) {
                    if (params[key] !== undefined) {
                        console.warn(`Repeated param key: ${key}. Value will be overwritten.`);
                    }
                    params[key] = newParams[key];
                }
            } catch (e) {
                console.error(e);
            }
        });
        return params;
    }

    public getFilter(constructor: Filter): FilterInterface {
        let instance = this._filters.get(constructor);
        if (instance === undefined) {
            instance = new constructor();
            this._filters.set(constructor, instance);
        }
        return instance;
    }
}
