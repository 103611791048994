import {Resource} from "../../api/resource";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {ManagerCachingStrategy, SearchType} from "../../basic-entity-back/basic-entity-interface/mapping-external";

@BasicEntity({
    name: 'Idiomas',
    endpoint: 'idiomas',
    isPaginated: true,
    autogeneratedId: false,
    modelNameProperty: 'nombreIdioma',
    acceptsBulkIriSearch: true,
    managerCaching: ManagerCachingStrategy.Lazy,
    type: 'Idioma'
})
export class Idioma extends Resource {
    @BasicProperty({
        isId: true,
        name: 'Código',
        type: PropertyType.String(),
        searchable: SearchType.Exact
    })
    private _codigoIdioma: string;

    @BasicProperty({
        name: 'Nombre',
        type: PropertyType.String()
    })
    private _nombreIdioma: string;

    @BasicProperty({
        name: 'Icono',
        type: PropertyType.Image(),
        nullable: true
    })
    private _icono: string;

    get codigoIdioma(): string {
        return this._codigoIdioma;
    }

    set codigoIdioma(value: string) {
        this._codigoIdioma = value;
    }

    get nombreIdioma(): string {
        return this._nombreIdioma;
    }

    set nombreIdioma(value: string) {
        this._nombreIdioma = value;
    }

    get icono(): string {
        return this._icono;
    }

    set icono(value: string) {
        this._icono = value;
    }
}
