import {Pipe, PipeTransform} from '@angular/core';

/**
 * If the value is null or undefined, returns null,
 * if not, it returns the value of the given property.
 * Usage:
 *      value | nullableProp: property
 * Example:
 *      {{ foo | nullableProp: 'bar'}}
 *      formats to:
 *          - foo[bar] if foo is not null and not undefined
 *          - null otherwise
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */
@Pipe({
    name: 'nullableProp'
})
export class NullablePropPipe implements PipeTransform {
    transform(value: any, property: string): any {
        return value !== null && value !== undefined ? value[property] : null;
    }
}
