import {Component, Inject, OnInit} from '@angular/core';
import {EditionDialogComponent} from '../../../../basic-entity-front/edition-dialog/edition-dialog.component';
import {Municipio} from '../../../../model/localizacion/municipio.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BaseDialogData} from '../../../../basic-entity-front/basic-entity-table/base-dialog-data';
import {ApiModuleFactory} from '../../../../api/api-module-factory.service';
import {InterfaceProviderService} from '../../../../basic-entity-back/services/interface-provider.service';
import {UntypedFormBuilder} from '@angular/forms';
import {ErrorDisplayService} from '../../../../basic-entity-front/services/error-display.service';
import {FilterAndData} from '../../../../api/filter-list';
import {MultipleSearchFilter} from '../../../../basic-entity-back/filters/search-filter';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {GeneralSearchFilter} from '../../../../basic-entity-back/filters/general-search-filter';
import {map} from 'rxjs/operators';
import {IriSearchFilter} from "../../../../basic-entity-back/filters/iri-search-filter";

@Component({
    selector: 'app-municipio-edition',
    templateUrl: './municipio-edition.component.html',
    styleUrls: ['./municipio-edition.component.scss']
})
export class MunicipioEditionComponent extends EditionDialogComponent<Municipio> implements OnInit {


    constructor(@Inject(MAT_DIALOG_DATA) public data: BaseDialogData<Municipio>, dialogRef: MatDialogRef<EditionDialogComponent<Municipio>>,
                apiFactory: ApiModuleFactory, public interfaceProvider: InterfaceProviderService, fb: UntypedFormBuilder, private _myErrorDisplay: ErrorDisplayService) {
        super(data, dialogRef, apiFactory, interfaceProvider, fb, _myErrorDisplay);
    }

    ngOnInit() {
        super.ngOnInit();



    }


}
