<ng-container *ngIf="value === null || value === undefined; else notNullTmp">
    <span [matTooltip]="tooltip$ | async" class="basic-entity-cell-null">
        <mat-icon>not_interested</mat-icon>
    </span>
</ng-container>
<ng-template #notNullTmp>
    <ng-container *ngIf="canBeLinked; else elseTmp">
        <a [style.fontWeight]="column.isId ? 'bold' : 'none'"
           [matTooltip]="tooltip$ | async"
           [routerLink]="link" [queryParams]="linkParams">
            {{valueToPrint$ | async}}
        </a>
    </ng-container>
    <ng-template #elseTmp>
        <ng-container [ngSwitch]="column.type.toString()">

            <span
                [matTooltip]="tooltip$ | async"
                [attr.class]="(valueToPrint$ | async) ? 'basic-entity-cell-checked' : 'basic-entity-cell-not-checked'"
                *ngSwitchCase="TypeStr.Boolean">
                <mat-icon>{{ (valueToPrint$ | async) ? 'check' : 'close'}}</mat-icon>
            </span>

            <table *ngSwitchCase="TypeStr.Dictionary"
                   [matTooltip]="tooltip$ | async">
                <tr *ngFor="let item of value| keyvalue">
                    <td class="dictionary-key">{{item.key}}:</td>
                    <td class="dictionary-value">{{item.value}}</td>
                </tr>
            </table>

            <table *ngSwitchCase="TypeStr.Translation"
                   [matTooltip]="tooltip$ | async">
                <tr *ngFor="let item of value| keyvalue">
                    <td class="dictionary-key">{{getLanguageName(item.key)}}:</td>
                    <td class="dictionary-value">{{item.value}}</td>
                </tr>
            </table>

            <a [matTooltip]="tooltip$ | async"
               [attr.href]="valueToPrint$ | async"
               target="_blank"
               (click)="$event.stopPropagation()"
               *ngSwitchCase="TypeStr.ExternalLink"
               [style.fontWeight]="column.isId ? 'bold' : 'none'">
                {{valueToPrint$ | async}}
            </a>

            <be-photo-preview *ngSwitchCase="TypeStr.Image" [photo]="valueToPrint$|async">
            </be-photo-preview>

            <span *ngSwitchDefault
                  [matTooltip]="tooltip$ | async"
                  [style.fontWeight]="column.isId ? 'bold' : 'none'">
                {{valueToPrint$ | async}}
            </span>

        </ng-container>
    </ng-template>
</ng-template>
