import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PaisComponent} from './pais/pais.component';
import {ComunidadComponent} from './comunidad/comunidad.component';
import {ZonaComponent} from './zona/zona.component';
import {ProvinciaComponent} from './provincia/provincia.component';
import {MunicipioComponent} from './municipio/municipio.component';
import {IdiomaComponent} from './idioma/idioma.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../material/material.module';
import {ZonaEditionComponent} from '../edition-list-dialog/zona-edition.component';
import {FestivoComponent} from './festivo/festivo.component';
import {FestivoEditionComponent} from '../edition-list-dialog/festivo-edition.component';
import {BasicEntityFrontModule} from '../../basic-entity-front/basic-entity-front.module';
import {MunicipioEditionComponent} from './municipio/municipio-edition/municipio-edition.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {EnviosComponent} from "./envios/envios.component";


@NgModule({
    declarations: [
        PaisComponent,
        ComunidadComponent,
        ZonaComponent,
        ProvinciaComponent,
        MunicipioComponent,
        IdiomaComponent,
        FestivoComponent,
        ZonaEditionComponent,
        FestivoEditionComponent,
        MunicipioEditionComponent,
        EnviosComponent
    ],
    imports: [
        CommonModule,
        BasicEntityFrontModule,
        ReactiveFormsModule,
        MaterialModule,
        DragDropModule,
        FormsModule,
    ]
})
export class LocalizacionModule {
}
