<mat-accordion [displayMode]="'flat'" *ngIf="expandableItems.length > 0">
    <mat-expansion-panel ngClass="no-shadow" *ngFor="let item of expandableItems; trackBy: trackByFn"
                         [hideToggle]="true">
        <mat-expansion-panel-header class="menu-expansion-header" [collapsedHeight]="'56px'"
                                    *ngIf="((item.id!==8 && item.id!==12 && item.id!==18))">
            <mat-icon class="menu-expansion-icon" *ngIf="item.icono">{{item.icono}}</mat-icon>
            <span class="menu-expansion-name">{{item.name}}</span>
        </mat-expansion-panel-header>
        <app-menu [items]="item.children"
                  (itemClicked$)="itemClicked$.next()"></app-menu>
    </mat-expansion-panel>
</mat-accordion>
<mat-nav-list class="menu-final-items" *ngIf="(finalItems.length > 0)">
    <a [routerLink]="item.link"
       [queryParams]="queryParams(item.queryParams)"
       (click)="itemClicked$.next()" routerLinkActive="active" mat-list-item
       *ngFor="let item of finalItems; trackBy: trackByFn">
        <mat-icon matListIcon>{{item.icono}}</mat-icon>
        {{item.name}}
    </a>
</mat-nav-list>


