<div class="container">
<div class="titulo">
    <span>
        <mat-icon class="mat-icon">local_shipping</mat-icon>
            Direcciones de envío
        </span>

</div>

    <div>
        <form #formEdit="ngForm" class="contenedorTabla">

    <table mat-table [dataSource]="direcciones" multiTemplateDataRows matSort class="table">
        <!-- Position Column -->
        <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns" class="cabeceraCell">
            <th mat-header-cell *matHeaderCellDef class="headerCell text-center" > {{getHeaderTitle(column)}}</th>
            <td  mat-cell *matCellDef="let element" class="text-center"> <span class="text-center">{{element[column]}}</span>
               <button mat-icon-button aria-label="expand row" *ngIf="((expandedElement !== element)|| (expandedElement === element)) && column==='editar'"
                       (click)="(expandedElement = expandedElement === element ? null : element);
                       editar($event,formEdit,element) ;$event.stopPropagation()" [disabled]="sePuedeBorrar">
                    <mat-icon id="edit" *ngIf="(expandedElement !== element) && column==='editar'">edit</mat-icon>
                    <mat-icon id="save" *ngIf="(expandedElement === element) && column==='editar'">save</mat-icon>

                </button>

                <button mat-icon-button (click)="borrar(element);$event.stopPropagation()" [disabled]="sePuedeBorrar">
                    <mat-icon *ngIf="column==='borrar'" >delete_forever</mat-icon>
                </button>
            </td>

        </ng-container>

        <ng-container matColumnDef="expand">
            <th mat-header-cell class="headerCellActions" *matHeaderCellDef aria-label="row actions"></th>
            <td  mat-cell class="headerCellActions" *matCellDef="let element">


            </td>

        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsExpand.length" >
                <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="container-elements" >



                         <div class="element-provincia">
                             <mat-form-field>
                                 <mat-label>Provincia</mat-label>
                                 <mat-select name="editar-provincia" (selectionChange)="seleccionProvincia($event.value);setModificable()" [(ngModel)]="provinciaEdit" required>
                                     <mat-option *ngFor="let provincia of provincias" [value]="provincia.codigoProvincia" >
                                         {{provincia.nombreProvincia}}
                                     </mat-option>
                                 </mat-select>
                                 <mat-error *ngFor="let error of errors.general">{{error}}</mat-error>
                             </mat-form-field>
                             </div>


                        <div class="element-poblacion">  <mat-form-field>
                            <mat-label>Población</mat-label>
                            <mat-select name="poblacion" (selectionChange)="seleccionMunicipio($event.value);setModificable()" [(ngModel)]="poblacionEdit" required>
                                <mat-option *ngFor="let municipio of municipios" [value]="municipio.nombreMunicipio">
                                    {{municipio.nombreMunicipio}}
                                </mat-option>

                            </mat-select>
                            <mat-error *ngFor="let error of errors.general">{{error}}</mat-error>
                        </mat-form-field>
                        </div>
                        <div class="element-cp"> <mat-form-field>
                            <mat-label>CP</mat-label>
                            <input matInput  [(ngModel)]="cpEdit" placeholder="Código Postal" name="cp"
                                   (change)="setModificable()" required>
                            <mat-error *ngFor="let error of errors.general">{{error}}</mat-error>
                        </mat-form-field></div>
                         <div class="element-direccion">     <mat-form-field class="direccion">
                             <mat-label>Dirección, planta, número...</mat-label>
                             <input matInput name="direccion" placeholder="Dirección" [(ngModel)]="direccionEdit"
                                    (change)="setModificable()" required>
                             <mat-error *ngFor="let error of errors.general">{{error}}</mat-error>
                         </mat-form-field></div>


                </div>
                    </div>
            </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumnsExpand"></tr>

        <tr mat-row  *matRowDef="let element; columns: displayedColumnsExpand;"
            [class.example-expanded-row]="expandedElement === element"
            [class.light-row]="true"
            (click)="expandedElement = expandedElement === element ? null : element">

        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

        <!-- Row shown when there is no matching data. -->
   <!--     <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No hay direcciones almacenadas</td>
        </tr> -->


    </table>
        </form>
       <!-- <mat-paginator class="centro-paginacion" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Selecciona página"></mat-paginator>-->
    </div>
    <div class="error">
        <span>{{errorGuardar}}</span>
    </div>


<div>
    <form *ngIf="mostrarAdd" #form="ngForm" (ngSubmit)="onSubmit(form)">

        <div class="titulo">
    <span>
        <mat-icon class="mat-icon">local_shipping</mat-icon>
           Nueva dirección
        </span>

        </div>
<div class="columnascontenedor">
        <div class="trescolumnas">
                  <mat-form-field class="col_izq">
                   <mat-label>Provincia</mat-label>
                <mat-select name="provinciaSave" (selectionChange)="seleccionProvincia($event.value);setModificable()" ngModel>
                       <mat-option *ngFor="let provincia of provincias" [value]="provincia.codigoProvincia">
                           {{provincia.nombreProvincia}}
                       </mat-option>
                   </mat-select>
                      <mat-error *ngFor="let error of errors.general">{{error}}</mat-error>
            </mat-form-field>
        </div>
        <div class="trescolumnas">
            <mat-form-field class="col_med">
                <mat-label>Población</mat-label>
                <mat-select name="poblacionSave" (selectionChange)="seleccionMunicipio($event.value);setModificable()" ngModel>
                    <mat-option *ngFor="let municipio of municipios" [value]="municipio.nombreMunicipio">
                        {{municipio.nombreMunicipio}}
                    </mat-option>

                </mat-select>
                <mat-error *ngFor="let error of errors.general">{{error}}</mat-error>
           </mat-form-field>
        </div>
        <div class="trescolumnas">
            <mat-form-field class="col_der">
                <mat-label>CP</mat-label>
                <input matInput  ngModel placeholder="Código Postal" name="cpSave" (change)="setModificable()" required>
                <mat-error *ngFor="let error of errors.general">{{error}}</mat-error>
            </mat-form-field>
        </div>
</div>
        <mat-form-field class="direccion">
            <mat-label>Dirección, planta, número...</mat-label>
            <input matInput name="direccionSave" placeholder="Dirección" ngModel
                   (change)="setModificable()" required>
            <mat-error *ngFor="let error of errors.general">{{error}}</mat-error>
        </mat-form-field>

            <mat-form-field class="direccion">
                <mat-label>Observaciones</mat-label>
                <input type="text" matInput name="observacionesSave" ngModel placeholder="Observaciones"
                       (change)="setModificable()" required>
                <mat-error *ngFor="let error of errors.general">{{error}}</mat-error>
          </mat-form-field>


        <div class="boton">
            <button mat-flat-button   class="mat-primary full-width mb-16" [disabled]="modificable" >Añadir dirección</button>
        </div>


    </form>

    <div class="boton">
        <button mat-flat-button *ngIf="!mostrarAdd" (click)="mostrarAddForm()"  class="mat-primary full-width mb-16" >Añadir dirección</button>
    </div>
</div>
</div>
