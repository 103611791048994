import {AfterViewInit, Component, Input, QueryList, ViewChildren} from '@angular/core';
import {ControlValueAccessor, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators} from "@angular/forms";
import {KeyValueInputComponent, KeyValuePair} from "../key-value-input/key-value-input.component";
import {KeyProviderService} from "../../../basic-entity-back/services/key-provider.service";
import { FloatLabelType } from "@angular/material/form-field";

@Component({
    selector: 'be-dictionary-input',
    templateUrl: './dictionary-input.component.html',
    styleUrls: ['./dictionary-input.component.scss'],
    providers: [{provide: NG_VALUE_ACCESSOR, useExisting: DictionaryInputComponent, multi: true}]
})
export class DictionaryInputComponent implements ControlValueAccessor, AfterViewInit {
    public readonly internalGroup: UntypedFormGroup;

    @Input() public keyProvider: KeyProviderService;
    @Input() public placeholder: string;
    @Input() public floatLabel: FloatLabelType;

    @ViewChildren(KeyValueInputComponent, { read: true}) keyValues: QueryList<KeyValueInputComponent>;

    private _onChange = (v) => {};

    constructor(private _formBuilder: UntypedFormBuilder) {
        this.internalGroup = this._formBuilder.group({
            entries: this._formBuilder.array([])
        });
    }

    ngAfterViewInit() {
        this.internalGroup.valueChanges.subscribe(v => {
            this._onChange(this.value);
            this.keyValues.forEach( item => {
                item.setUsedKeys(this.usedKeys);
            });
        });
    }

    public get areThereKeysAvailable(): boolean {
        return !this.keyProvider || this.internalArray.length < this.keyProvider.keys.size;
    }

    public get usedKeys(): string[] {
        return Object.keys(this.value);
    }

    public get value() {
        const val = {};
        for (const control of this.internalArray.controls) {
            if (control.value['entry'] === null) {
                return null;
            }
            const entry = control.value['entry'];
            val[entry[0]] = entry[1];
        }
        return val;
    }

    public set value(val) {
        let i = 0;
        for (const keyValue of Object.entries(val)) {

            if (typeof keyValue[1] !== "string") {
                continue;
            }

            if (i < this.internalArray.controls.length) {
                // Set the existent
                this.internalArray.controls[i].setValue({entry: keyValue});
            } else {
                // Create the ones missing
                this.internalArray.push(this.createItem(keyValue as KeyValuePair));
            }
            i++;
        }
        // Remove the leftover
        while (i < this.internalArray.controls.length) {
            this.internalArray.removeAt(i);
        }
    }

    public get internalArray(): UntypedFormArray {
        return this.internalGroup.get('entries') as UntypedFormArray;
    }

    private createItem(keyValue: KeyValuePair): UntypedFormGroup {
        return this._formBuilder.group({
            entry: this._formBuilder.control(keyValue, Validators.required)
        });
    }

    public addItem() {
        const keyValue: [string, string] = ['', ''];
        if (this.keyProvider) {
            this.keyProvider.keys.forEach( (v, k) => {
                if (!this.usedKeys.includes(k)) {
                    keyValue[0] = k;
                }
            });
        }
        this.internalArray.push(this.createItem(keyValue));
    }

    public deleteItem(index: number): void {
        this.internalArray.removeAt(index);
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        // ignored
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.internalGroup.disable();
        } else {
            this.internalGroup.enable();
        }
    }

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        }
    }
}
