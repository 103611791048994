import {Resource} from "../../api/resource";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {Pais} from "../localizacion/pais.model";
import {
    ManagerCachingStrategy,
    PropertyMap,
    ReadWrite
} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {Uri} from "../../api/uri";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {ExactSearchFilter} from "../../basic-entity-back/filters/search-filter";
import {Provincia} from "../localizacion/provincia.model";

@BasicEntity({
    name: 'Direcciones',
    endpoint: 'direccion_personas',
    isPaginated: true,
    paginationSizes: [20, 30, 50],
    modelNameProperty: 'lectura',
    type: 'DireccionPersona',
    managerCaching: ManagerCachingStrategy.Lazy
})
export class DireccionPersona extends Resource {
    public static readonly TIPOS_DIRECCION = {
        ENVIO: 'Envío',
        FACTURACION: 'Facturación'
    }

    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer()
    })
    private _id: number;

    @BasicProperty({
        name: 'Tipo de Dirección',
        type: PropertyType.Choice(...Object.keys(DireccionPersona.TIPOS_DIRECCION)),
        filters: [ExactSearchFilter]
        // readWrite: ReadWrite.ReadOnly
    })
    private _tipoDireccion: string;

    @BasicProperty({
        name: 'Calle',
        type: PropertyType.String()
    })
    private _calle: string;

    @BasicProperty({
        name: 'Población',
        type: PropertyType.String()
    })
    private _poblacion: string;

    @BasicProperty({
        name: 'Demarcación',
        type: PropertyType.NestedModel(Provincia, null, NestedBehavior.SendIriOnly)
    })
    private _demarcacion;

    @BasicProperty({
        name: 'Código postal',
        type: PropertyType.String()
    })
    private _cp: string;

    @BasicProperty({
        name: 'País',
        type: PropertyType.NestedModel(Pais, null, NestedBehavior.SendIriOnly)
    })
    private _pais: Pais;

    public static readonly PERSONA_MAP: PropertyMap = {
        keyInApi: 'persona',
        type: PropertyType.FutureUri('persona.model.ts'),
        readWrite: ReadWrite.ReadOnly
    };
    @BasicProperty(DireccionPersona.PERSONA_MAP)
    private _uriPersona: Uri;

    @BasicProperty({
        type: PropertyType.String(),
        readWrite: ReadWrite.ReadOnly,
        dontReadFromApi: true
    })
    public get lectura(): string {
        return `${this._calle}, ${this._cp}, ${this._poblacion}, ${this._demarcacion?.nombre}, ${this._pais?.nombreDefault}.`;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get tipoDireccion(): string {
        return this._tipoDireccion;
    }

    set tipoDireccion(value: string) {
        this._tipoDireccion = value;
    }

    get calle(): string {
        return this._calle;
    }

    set calle(value: string) {
        this._calle = value;
    }

    get poblacion(): string {
        return this._poblacion;
    }

    set poblacion(value: string) {
        this._poblacion = value;
    }

    get demarcacion() {
        return this._demarcacion;
    }

    set demarcacion(value) {
        this._demarcacion = value;
    }

    get cp(): string {
        return this._cp;
    }

    set cp(value: string) {
        this._cp = value;
    }

    get pais(): Pais {
        return this._pais;
    }

    set pais(value: Pais) {
        this._pais = value;
    }

    get uriPersona(): Uri {
        return this._uriPersona;
    }

    set uriPersona(value: Uri) {
        this._uriPersona = value;
    }
}
