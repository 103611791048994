import {Caster} from './caster';
import * as moment from 'moment-timezone';

/**
 * Casts between moments and strings
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */
export class MomentCaster implements Caster<moment.Moment, string> {
    /**
     * @param _format - The casters to output when passing from
     * moment to string
     */
    constructor(private _format: string = null) {
    }

    fromModel(modelValue: moment.Moment | null): string | null {
        if (!modelValue || !moment.isMoment(modelValue)) {
            return null;
        }

        const res = this._format ? modelValue.local(true).format(this._format) : modelValue.local(true).format('Y-MM-DDTHH:mm:ss');
        return res;
    }

    toModel(apiValue: string | null): moment.Moment | null {
        if (!apiValue) {
            return null;
        }

        const result = moment(apiValue, this._format).tz('Europe/Madrid');
        return result.isValid() ? result.local(true) : null;
    }
}

