import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './login/login.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import {RouterModule} from "@angular/router";
import {RegistroComponent} from "./registro/registro.component";
import {DatosPersonalesComponent} from "./datosPersonales/datosPersonales.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDividerModule} from "@angular/material/divider";
import {MatSelectModule} from "@angular/material/select";
import {ActivationComponent} from "./activation/activation.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {PasswordRecoveryComponent} from "./password-recovery/password-recovery.component";
import {SessionRoutingModule} from "./session-routing.module";


@NgModule({
    declarations: [LoginComponent,RegistroComponent,DatosPersonalesComponent, ActivationComponent, PasswordRecoveryComponent],
    imports: [
        CommonModule,
        FormsModule,
        SessionRoutingModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        RouterModule,
        MatProgressBarModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatDividerModule,
        MatSelectModule,
        MatCardModule,
        MatProgressSpinnerModule
    ]
})
export class SessionModule {
}
