import {Component, Input, OnDestroy, OnInit, Type} from '@angular/core';
import {
    ControlValueAccessor,
    UntypedFormBuilder,
    NG_VALUE_ACCESSOR,
    Validators,
    UntypedFormGroup
} from '@angular/forms';
import {InternalPropertyMap} from "../../../basic-entity-back/basic-entity-interface/mapping-internal";
import {FilterAndData} from "../../../api/filter-list";
import {FilterInterface} from "../../../api/filter-interface";
import {Subscription} from "rxjs";
import {
    AfterDateFilter,
    BeforeDateFilter,
    BetweenDateFilter,
    StrictlyAfterDateFilter,
    StrictlyBeforeDateFilter
} from "../../../basic-entity-back/filters/date-filter";
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {BetweenRangeFilter} from "../../../basic-entity-back/filters/range-filters";

@Component({
    selector: 'be-date-filtering',
    templateUrl: './date-filtering.component.html',
    styleUrls: ['./date-filtering.component.scss'],
    providers: [{provide: NG_VALUE_ACCESSOR, useExisting: DateFilteringComponent, multi: true}]
})
export class DateFilteringComponent implements OnInit, OnDestroy, ControlValueAccessor {
    private _onChange: (value) => void = () => {
    };
    private _onTouch: () => void = () => {
    };
    private _subscription: Subscription;
    @Input() property: InternalPropertyMap;

    public formGroup: UntypedFormGroup;
    public static readonly sManagedFilters: { [s: string]: Type<FilterInterface> } = {
        '<': StrictlyBeforeDateFilter,
        '<=': BeforeDateFilter,
        '>': StrictlyAfterDateFilter,
        '>=': AfterDateFilter,
        '..': BetweenDateFilter
    };

    constructor(fb: UntypedFormBuilder) {
        this.formGroup = fb.group({
            comp: fb.control('>='),
            main: fb.control(null),
            other: fb.control(null)
        });
    }

    ngOnInit(): void {
        this._subscription = this.formGroup.valueChanges.subscribe(v => {
            if (this.formGroup.valid) {
                const filter = DateFilteringComponent.sManagedFilters[v.comp];
                console.log(filter)
                const comp = this._compForFilter(filter)
                if (v.main) {
                    if (comp === '<=' || comp === '>') {
                        v.main.set({'hour': 23, 'minutes': 59, 'seconds': 59});
                    } else if (comp === '>=' || comp === '<') {
                        v.main.set({'hour': 0, 'minutes': 0, 'seconds': 0});
                    }

                }
                v.other?.set({'hour': 23, 'minutes': 59, 'seconds': 59});
                let data = v.comp === '..' ? [v.main || 0, v.other || 0] : v.main || 0;
                this._onChange(new FilterAndData(filter, data, this.property));
            }
        });
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled && this.formGroup.enabled) {
            this.formGroup.disable({emitEvent: false});
        } else if (!isDisabled && this.formGroup.disabled) {
            this.formGroup.enable({emitEvent: false});
        }
    }

    writeValue(fad: any): void {
        if (fad instanceof FilterAndData
            && fad.property.modelKey === this.property.modelKey
            && Object.values(DateFilteringComponent.sManagedFilters).includes(fad.filter)) {
            const obj = fad.data;
            if (obj != null) {
                if (Array.isArray(obj) && obj.length == 2) {
                    obj[0].set({'hour': 0, 'minutes': 0, 'seconds': 0});
                    obj[1].set({'hour': 23, 'minutes': 59, 'seconds': 59});
                    this.formGroup.setValue({comp: '..', main: obj[0], other: obj[1]});
                } else {
                    const comp = this._compForFilter(fad.filter);
                    if ((comp === '<=') || (comp === '>')) {
                        obj.set({'hour': 23, 'minutes': 59, 'seconds': 59});
                    } else if ((comp === '>=') || (comp === '<')) {
                        obj.set({'hour': 0, 'minutes': 0, 'seconds': 0});
                    }
                    this.formGroup.setValue({comp: comp, main: obj, other: null});
                }
            } else {
                this.formGroup.setValue({comp: '>=', main: null, other: null});
            }
        } else {
            // console.error('Invalid value ', fad);
        }
    }

    private _compForFilter(filter: Type<FilterInterface>): string {
        const pair = Object.entries(DateFilteringComponent.sManagedFilters).find(([k, v]) => v === filter);
        return pair ? pair[0] : null;
    }
}
