import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../api/api.service';
import {HttpErrorResponse} from '@angular/common/http';
import {switchMap} from 'rxjs/operators';
import {ErrorDisplayService} from '../../basic-entity-front/services/error-display.service';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-password-recovery',
    templateUrl: './password-recovery.component.html',
    styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {
    public formGroup: UntypedFormGroup;
    public done = false;
    public notEnabled = false;
    public passwordStillValid = false;
    public resendActivationLink: string = null;
    public notFound = false;
    public userComesFromOldSystem = false;

    constructor(fb: UntypedFormBuilder,
                private _route: ActivatedRoute,
                private _api: ApiService,
                private _errorDisplay: ErrorDisplayService,
                private _matSnackbar: MatSnackBar) {
        this.formGroup = fb.group({
            email: fb.control(this._route.snapshot.queryParams['email'], [Validators.required, Validators.email])
        });

        this.formGroup.valueChanges.subscribe(() => this.notFound = this.notEnabled = this.passwordStillValid = false);
    }

    submit() {
        console.log(this.formGroup)
        if (this.formGroup.valid) {
            this._api.post('password_recovery', this.formGroup.value)
                .subscribe(
                    () => this.done = true,
                    err => {
                        if (err instanceof HttpErrorResponse) {
                            switch (err.status) {
                                case 409:
                                    this.notEnabled = true;
                                    this.resendActivationLink = err.error.payload.resendActivation;
                                    break;
                                case 400:
                                    this.passwordStillValid = true;
                                    break;
                                case 404:
                                    this.notFound = true;
                                    break;
                            }
                        } else {
                            console.error(err);
                        }
                    });
        }
    }

    public resendActivationClick(event: MouseEvent): void {
        event.stopPropagation();
        event.preventDefault();
        this.resendActivation();
    }

    public resendActivation() {
        this._api.put(this.resendActivationLink, {})
            .pipe(switchMap(() => "Email enviado"))
            .subscribe(
                v => this._matSnackbar.open(v, 'Ok', {duration: 2000, panelClass: 'success-snackbar'}),
                err => this._errorDisplay.displayError(err)
            );
    }

    ngOnInit() {
    }
}
