import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Filter} from "../../api/filter-interface";
import {FilterGroup, ReadWrite} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {BooleanFilter} from "../../basic-entity-back/filters/boolean-filter";
import {
    AfterDateFilter,
    BeforeDateFilter, DateFilters,
    StrictlyAfterDateFilter,
    StrictlyBeforeDateFilter,
    BetweenDateFilter
} from "../../basic-entity-back/filters/date-filter";
import {ExistsFilter} from "../../basic-entity-back/filters/exists-filter";
import {NumericFilter} from "../../basic-entity-back/filters/numeric-filter";
import {
    BetweenRangeFilter,
    GreaterThanOrEqRangeFilter,
    GreaterThanRangeFilter, LowerThanOrEqRangeFilter,
    LowerThanRangeFilter, RangeFilters
} from "../../basic-entity-back/filters/range-filters";
import {
    EndSearchFilter,
    ExactSearchFilter,
    MultipleSearchFilter, PartialSearchFilter,
    SimpleSearchFilter,
    StartSearchFilter, WordStartSearchFilter
} from "../../basic-entity-back/filters/search-filter";
import {InternalPropertyMap} from "../../basic-entity-back/basic-entity-interface/mapping-internal";
import {EntityDatasource} from "../../api/entity.datasource";
import {FormGroup, UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {SearchService} from "../../services/search.service";
import {RouteFilterTranslator} from "./route-filter-translator";
import {Subscription} from "rxjs";
import {FilterAndData} from "../../api/filter-list";
import {GeneralSearchFilter} from "../../basic-entity-back/filters/general-search-filter";
import {BasicEntityTableConstants} from "../basic-entity-table/basic-entity-table-constants";
import {TypeStr} from "../../basic-entity-back/property-type/type-str";
import {InterfaceProviderService} from "../../basic-entity-back/services/interface-provider.service";
import {FilterListComponent} from "./filter-list/filter-list.component";


interface FilterRepresentation {
    name: string;
    icon?: string;
    description: string;
}


@Component({
    selector: 'be-filtering2',
    templateUrl: './basic-entity-filtering2.component.html',
    styleUrls: ['./basic-entity-filtering2.component.scss']
})
export class BasicEntityFiltering2Component<T> implements OnInit {
    /** Time that the user has to stay without typing before the search is performed */
    private static readonly TIMEOUT_BEFORE_SEARCHING = 500;
    private static readonly FILTER_REPRESENTATION = new Map<Filter | FilterGroup, FilterRepresentation>([
        [BooleanFilter, {
            name: 'Exacto', icon: 'fa-toggle-on',
            description: 'Busca elementos con un valor en esta propiedad de "verdadero" o "falso".'
        }],
        [AfterDateFilter, {
            name: 'Después', icon: 'fa-calendar',
            description: 'Busca elementos cuyo valor en esta propiedad es posterior o igual a cierta fecha.'
        }],
        [BeforeDateFilter, {
            name: 'Antes', icon: 'fa-calendar',
            description: 'Busca elementos cuyo valor en esta propiedad es previo o igual a cierta fecha.'
        }],
        [StrictlyAfterDateFilter, {
            name: 'Estrictamente después', icon: 'fa-calendar',
            description: 'Busca elementos cuyo valor en esta propiedad es estrictamente posterior a cierta fecha.'
        }],
        [StrictlyBeforeDateFilter, {
            name: 'Estrictamente antes', icon: 'fa-calendar',
            description: 'Busca elementos cuyo valor en esta propiedad es estrictamente previo a cierta fecha.'
        }],
        [BetweenDateFilter, {
            name: 'Entre', icon: 'fa-calendar',
            description: 'Busca elementos cuyo valor en esta propiedad está entre dos fechas.'
        }],
        [ExistsFilter, {
            name: '¿Nulo?', icon: 'fa-times',
            description: 'Busca elementos cuyo valor en esta propiedad es (o no es) nulo.'
        }],
        [NumericFilter, {
            name: 'Exacto', icon: 'fa-equals',
            description: 'Busca elementos cuyo valor en esta propiedad es exactamente el número indicado.'
        }],
        [GreaterThanRangeFilter, {
            name: 'Mayor', icon: 'fa-greater-than',
            description: 'Busca elementos cuyo valor en esta propiedad es mayor que cierto valor.'
        }],
        [GreaterThanOrEqRangeFilter, {
            name: 'Mayor o igual', icon: 'fa-greater-than-equal',
            description: 'Busca elementos cuyo valor en esta propiedad es mayor o igual que cierto valor.'
        }],
        [LowerThanRangeFilter, {
            name: 'Menor', icon: 'fa-less-than',
            description: 'Busca elementos cuyo valor en esta propiedad es menor que cierto valor.'
        }],
        [LowerThanOrEqRangeFilter, {
            name: 'Menor o igual', icon: 'fa-less-than-equal',
            description: 'Busca elementos cuyo valor en esta propiedad es menor o igual que cierto valor.'
        }],
        [BetweenRangeFilter, {
            name: 'Entre', icon: 'fa-band-aid',
            description: 'Busca elementos cuyo valor en esta propiedad se sitúa entre dos valores.'
        }],
        [MultipleSearchFilter, {
            name: 'Filtrar', icon: 'fa-search',
            description: 'Busca elementos cuyo valor en esta propiedad contiene un valor dado.'
        }],
        [SimpleSearchFilter, {
            name: 'Filtrar', icon: 'fa-search',
            description: 'Busca elementos cuyo valor en esta propiedad contiene un valor dado.'
        }],
        [StartSearchFilter, {
            name: 'Filtrar', icon: 'fa-search',
            description: 'Busca elementos cuyo valor en esta propiedad comienza con un valor dado.'
        }],
        [WordStartSearchFilter, {
            name: 'Filtrar', icon: 'fa-search',
            description: 'Busca elementos cuyo valor en esta propiedad tiene alguna palabra que comience por los carácteres dados.'
        }],
        [ExactSearchFilter, {
            name: 'Filtrar', icon: 'fa-search',
            description: 'Busca elementos cuyo valor en esta propiedad es exactamente alguna de las palabras dadas (separando por espacios, si cabe).'
        }],
        [EndSearchFilter, {
            name: 'Filtrar', icon: 'fa-search',
            description: 'Busca elementos cuyo valor en esta propiedad termina con un valor dado.'
        }],
        [PartialSearchFilter, {
            name: 'Filtrar', icon: 'fa-search',
            description: 'Busca elementos cuyo valor en esta propiedad contiene un valor determinado.'
        }],
        [DateFilters, {
            name: 'Filtrar', icon: 'fa-calendar',
            description: 'Busca elementos cuya fecha es anterior, posterior o igual a la fecha dada.'
        }],
        [RangeFilters, {
            name: 'Rango', icon: 'fa-band-aid',
            description: 'Busca elementos cuyo valor es mayor que cierto valor, menor que cierto valor o se sitúa entre dos valores.'
        }]
    ]);


    /** Properties to offer filters for */
    @Input() public properties: InternalPropertyMap[];
    /** Properties which can be filtered (obtained from the input properties) */
    public filtrableProperties: InternalPropertyMap[];
    /** The datasource this component should manage to make the filtering */
    @Input() protected dataSource: EntityDatasource<T>;
    /** Timeout to reload the filters (while you type this timeout is renovated) */
    private _filtersReloadTimeout = null;
    /** Subscription to the general search in the search bar */
    private _searchSubscription: Subscription = null;
    private _queryParamsSubscription: Subscription = null;
    formGroupFiltering: FormGroup;
    dateOperator: any;
    protected readonly TypeStr = TypeStr;
    @ViewChild(FilterListComponent, {static: true}) filterList: FilterListComponent;
    withFilters: any;

    constructor(private _router: Router,
                private _activatedRoute: ActivatedRoute,
                private _searchService: SearchService,
                private _routeFilterTranslator: RouteFilterTranslator,
                private _interfaceProvider: InterfaceProviderService,
                private _fb: UntypedFormBuilder) {
    }

    ngOnInit(): void {
        this.filtrableProperties = this.properties.filter(prop => prop.filters.length > 0);
        this.filtrableProperties.forEach(fp => this.filterList.addFilter(fp, fp.filters[0]))
        this._searchService.hideSearch = false;
        this._searchSubscription = this._searchService.search.subscribe(
            newSearch => this._redirectGeneralSearch(newSearch)
        );
        this._queryParamsSubscription = this._activatedRoute
            .queryParams.subscribe(this._manageParamsChange.bind(this));
    }


    private _manageParamsChange(params: Params) {
        const filters = {};
        Object.entries(this._routeFilterTranslator.paramsToFilters(params, this.properties))
            .filter(([k, f]) => f.data != null)
            .forEach(([k, f]) => filters[k] = f);

        this.dataSource.filtros = Object.values(filters);
        this.filterList.filters.forEach(fp => {
            const res = this.dataSource.filtros.find(f2 => f2.property === fp.property);
            if (res !== undefined) {
                fp.control.setValue(res, {emitEvent: false});
            }
        });

        const generalSearch = this.dataSource.filtros.find(f => f.filter === GeneralSearchFilter);
        if (generalSearch) {
            this._searchService.currentSearch = generalSearch.data;
        } else {
            this._searchService.currentSearch = null;
        }
        clearTimeout(this._filtersReloadTimeout);
        this._filtersReloadTimeout = setTimeout(
            () => this.dataSource.load(),
            BasicEntityFiltering2Component.TIMEOUT_BEFORE_SEARCHING);
    }


    /**
     * @inheritDoc
     */
    ngOnDestroy(): void {
        this._searchSubscription.unsubscribe();
        this._queryParamsSubscription.unsubscribe();
        // this._searchService.hideSearch = true;
    }

    /**
     * Performs the navigation to set the filter for the general search.
     * If str is null it does nothing.
     * @private
     */
    private _redirectGeneralSearch(str: string | null) {
        if (str == null) {
            return;
        }

        if (str !== '') {
            this._filterNavigation({'gs': new FilterAndData(GeneralSearchFilter, str)});
        } else {
            this._filterNavigation({});
        }
    }

    private _filterNavigation(list: { [id: string]: FilterAndData }) {
        const filtered = {};
        Object.entries(list)
            .filter(([id, f]) => f && f.data != null)
            .forEach(([id, f]) => filtered[id] = f);

        const params = RouteFilterTranslator.sFiltersToParams(filtered);

        // Preserve pagination
        if (this.dataSource.pagination.pageSize) {
            params[BasicEntityTableConstants.PAGINATION_SIZE] = this.dataSource.pagination.pageSize;
        }

        this.withFilters = Object.values(filtered).length > 0;

        this._router.navigate([], {
            queryParams: params,
            queryParamsHandling: ""
        }).catch(reason =>
            console.error("Navigation rejected", reason)
        );
    }

    clearFilters() {
        this._filterNavigation({});
        this.filterList.setFilters({});
        this.filtrableProperties.forEach(fp => this.filterList.addFilter(fp, fp.filters[0]))
    }

    applyFilters() {
        let filterList: { [id: string]: FilterAndData } = {};
        for (const filter of this.filterList.filters) {
            filterList[filter.id] = filter.control.value;
        }
        this._filterNavigation(filterList);
    }
}
