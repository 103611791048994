import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {EditionDialogComponent} from "../../../../basic-entity-front/edition-dialog/edition-dialog.component";
import {InternalPropertyMap} from "../../../../basic-entity-back/basic-entity-interface/mapping-internal";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {BehaviorSubject, Subscription} from "rxjs";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {BaseDialogData} from "../../../../basic-entity-front/basic-entity-table/base-dialog-data";
import {ApiModuleFactory} from "../../../../api/api-module-factory.service";
import {InterfaceProviderService} from "../../../../basic-entity-back/services/interface-provider.service";
import {ErrorDisplayService} from "../../../../basic-entity-front/services/error-display.service";
import {Usuario} from "../../../../model/personas/usuario.model";
import {ApiService} from "../../../../api/api.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {EnviosElement} from "../../../localizacion/envios/envios.component";
import {Municipio} from "../../../../model/localizacion/municipio.model";


export interface DireccionEnvioElement {
    provincia: string;
    poblacion: string;
    cp: number;
    direccion: string;
    observaciones:string;
}
@Component({
    selector: 'app-usuarios-edition',
    templateUrl: './usuarios-edition.component.html',
    styleUrls: ['./usuarios-edition.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0',display:'none'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class UsuariosEditionComponent extends EditionDialogComponent<Usuario> implements OnDestroy {

    public readonly propMap: Map<string, InternalPropertyMap>;
    private subscriptions = new Subscription();
    public direccionesEnvio =[];
    public empresaForm = new UntypedFormGroup({
        usuario : new UntypedFormControl(''),
        contacto : new UntypedFormControl(''),
        contactoMail : new UntypedFormControl(''),
        contactoTelefono : new UntypedFormControl(''),
        denominacionSocial: new UntypedFormControl(''),
        cifNif: new UntypedFormControl(''),
        provincia: new UntypedFormControl(''),
        poblacion: new UntypedFormControl(''),
        cp: new UntypedFormControl(''),
        direccion: new UntypedFormControl(''),
        telefono: new UntypedFormControl(''),
        mail: new UntypedFormControl('')
    });

    public facturacionForm = new UntypedFormGroup({

        denominacionSocial: new UntypedFormControl(''),
        cif: new UntypedFormControl(''),
        iban: new UntypedFormControl(''),
        provincia: new UntypedFormControl(''),
        poblacion: new UntypedFormControl(''),
        cp: new UntypedFormControl(''),
        direccion: new UntypedFormControl(''),
        telefono: new UntypedFormControl(''),
        mail: new UntypedFormControl('')
    });

    public municipios= [];

    public enviosForm = new UntypedFormGroup({

        provinciaEnvios: new UntypedFormControl(''),
        poblacionEnvios: new UntypedFormControl(''),
        cpEnvios: new UntypedFormControl(''),
        direccionEnvios: new UntypedFormControl(''),
        observacionesEnvios: new UntypedFormControl('')
    });
    public keys : String[];
    public keysFacturacion: String[];
    public empresaLabels:String[];
    public facturacionLabels:String[];
    displayedColumns: string[] = [ 'provincia','poblacion', 'cp', 'direccion','observaciones','editar','borrar'];
    displayedColumnsExpand: string[] = [...this.displayedColumns,'expand'];
    private _original: Usuario;
    public saving$ = new BehaviorSubject<boolean>(false);
    public updatedOnceAtLeast = false;
    /** Se pone a true cuando hay una actualización pendiente después de la que está en curso, se pone a false cuando se inicia una nueva actualización */
    private _pendentUpdate = false;
    public saved = false;
    sePuedeBorrar:boolean;
    modificable:boolean;
    public provinciaEdit:string;
    public provincias= [];

    public provinciaSelected:string;
    public poblacionEdit:string;
    public cpEdit:string;
    public direccionEdit:string;
    public observacionesEdit:string;


    expandedElement: EnviosElement | null;

    public errors: { general: string[],username: string[], password: string[] } = {general: [], username: [], password: []};
    public errorGuardar :string;


    constructor(@Inject(MAT_DIALOG_DATA) public data: BaseDialogData<Usuario>,
                dialogRef: MatDialogRef<EditionDialogComponent<Usuario>>, private _dialogService: MatDialog,
                private apiFactory: ApiModuleFactory, public interfaceProvider: InterfaceProviderService, private fb: UntypedFormBuilder,
                private _myErrorDisplay: ErrorDisplayService, private apiService: ApiService) {

        super(data, dialogRef, apiFactory, interfaceProvider, fb, _myErrorDisplay);
        const propMap: [string, InternalPropertyMap][] = this.properties.map(p => [p.modelKey, p]) as [string, InternalPropertyMap][];
        this.propMap = new Map(propMap);

        this._original = this.manager.loader.entityInterface.serialiser.clone(this.model);

        this.errors.general.push('El campo es obligatorio.')
    }

    public property(key: string): InternalPropertyMap {
        return this.propMap.get(key);
    }

    acceptDialog() {

    }



    ngOnDestroy() {

        super.ngOnDestroy();
        this.subscriptions.unsubscribe();
    }

}
