import {Component} from '@angular/core';
import {BasicEntityTableColumnDescriptionOrString, ColumnJustification} from "../../../basic-entity-front/basic-entity-table/basic-entity-table.component";
import {Usuario} from "../../../model/personas/usuario.model";
import {UsuariosEditionComponent} from "./usuarios-edition/usuarios-edition.component";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: 'app-usuarios',
    template: '<be-table [editionDialog]="dialog" [columnsToDisplay]="columns" [modelType]="model"  [reloadDataSource]="onUpdate"></be-table>'
})
export class UsuariosComponent {
    public onUpdate = new BehaviorSubject<boolean>(false);
    public model = Usuario;
    public dialog = UsuariosEditionComponent;
    public columns: BasicEntityTableColumnDescriptionOrString[] = [
        {key: 'id', hiddenDisplayable: true},
        {key: 'username', weight: 5,justification:ColumnJustification.Left},
        'password',
        {key: 'email', weight: 5,justification:ColumnJustification.Left},
        {key: 'telefono', weight: 5,justification:ColumnJustification.Left},
    //    { key: 'locale', justification: ColumnJustification.Center},
        //{key: 'empresa', hiddenDisplayable: true},
       // {key: 'passwordValidUntil', hiddenDisplayable: true},
        { key: 'enabled', weight: 1, justification: ColumnJustification.Left},


    ];




}
