<button mat-icon-button [matMenuTriggerFor]="propsMenu" matTooltip="Añadir filtro"
        *ngIf="filtrableProperties.length > 0">
    <mat-icon fontSet="material-icons-outlined">filter_alt</mat-icon>
</button>
<be-filter-list (newValue)="filtersChanged($event)" #filterList></be-filter-list>
<mat-menu #propsMenu="matMenu">
    <ng-container *ngFor="let prop of filtrableProperties">
        <button mat-menu-item *ngIf="prop.filtersUnflatted.length > 1"
                [matMenuTriggerFor]="filtersMenu" [matMenuTriggerData]="{property: prop}">
            <i class="fas fa-folder"></i>{{prop.name}}
        </button>
        <button mat-menu-item *ngIf="prop.filtersUnflatted.length === 1"
                [matTooltip]="filterOrGroupRepr(prop.filtersUnflatted[0]).description"
                (click)="filterList.addFilter(prop, prop.filtersUnflatted[0])">
            <i *ngIf="filterOrGroupRepr(prop.filtersUnflatted[0]).icon"
               [attr.class]="'fas '+filterOrGroupRepr(prop.filtersUnflatted[0]).icon">
            </i>
            <span>{{prop.name}}</span>
        </button>
    </ng-container>
</mat-menu>
<mat-menu #filtersMenu="matMenu">
    <ng-template matMenuContent let-prop="property">
        <button mat-menu-item *ngFor="let filter of prop.filtersUnflatted"
                [matTooltip]="filter.description"
                (click)="filterList.addFilter(prop, filter)">
            <i *ngIf="filterOrGroupRepr(filter).icon"
               [attr.class]="'fas '+filterOrGroupRepr(filter).icon">
            </i>
            <span>{{filterOrGroupRepr(filter).name}}</span>
        </button>
    </ng-template>
</mat-menu>
