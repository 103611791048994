import {Injectable} from '@angular/core';
import {Resource} from "./resource";
import {EntityLoader} from "./entity-loader";
import {EntityInterface} from "./entity-interface";
import {ApiService} from "./api.service";
import {EntityDatasource} from "./entity.datasource";

@Injectable({
    providedIn: 'root'
})
export class ApiModuleFactory {
    constructor(private _apiService: ApiService) {
    }

    public createEntityLoader<T extends Resource, I extends EntityInterface<T> = EntityInterface<T>>(interf: I): EntityLoader<T, I> {
        return new EntityLoader<T, I>(interf, this._apiService);
    }

    public createDataSource<T extends Resource, I extends EntityInterface<T> = EntityInterface<T>>(interfOrLoader: I | EntityLoader<T>): EntityDatasource<T, I> {
        if (!(interfOrLoader instanceof EntityLoader)) {
            interfOrLoader = this.createEntityLoader(interfOrLoader);
        }
        return new EntityDatasource(interfOrLoader) as EntityDatasource<T, I>;
    }
}
