import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

/**
 * Este servicio coordina la búsqueda entre el header y el table component
 * que se esté mostrando.
 */
@Injectable({
    providedIn: 'root'
})
export class SearchService {
    private _currentSearch = '';
    private _search: Subject<string> = new Subject();
    public hideSearch = false;

    get search(): Observable<string> {
        return this._search;
    }
    get currentSearch(): string|null {
        return this._currentSearch;
    }
    set currentSearch(value: string|null) {
        if (this.currentSearch !== value) {
            this._currentSearch = value;
            this._search.next(this._currentSearch);
        }
    }
}
