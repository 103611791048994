<div [formGroup]="parts">
  <input class="area" formControlName="key" type="text" *ngIf="!keySet">
  <mat-select *ngIf="keySet" formControlName="key">
    <mat-option *ngFor="let keyValue of keySet" [value]="keyValue[0]">
      {{keyValue[1]}}
    </mat-option>
  </mat-select>
  <span>: </span>
  <input class="exchange" formControlName="value" type="text">
</div>
