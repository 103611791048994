import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormGroup} from "@angular/forms";
import {InternalPropertyMap} from "../../../basic-entity-back/basic-entity-interface/mapping-internal";
import {FormManager} from "../edition-row/edition-row.component";

@Component({
    selector: 'be-edition-tab',
    templateUrl: './edition-tab.component.html',
    styleUrls: ['./edition-tab.component.scss']
})
export class EditionTabComponent {
    /** FormGroup which controls all the dialog values */
    @Input() public readonly formGroup: UntypedFormGroup;
    /** Columns to show for edition */
    @Input() public readonly propertiesToShow: InternalPropertyMap[];
    @Input() public readonly formManager: FormManager;
    @Input() public callbackFunctionForDeleteButton: (...args: any) => void;
    @Output() public deleteButton = new EventEmitter<UntypedFormArray>();

    @HostBinding('class.be-edition-tab') public readonly classValue = true;

    public trackByModelKey(index, item: InternalPropertyMap) {

        return item.modelKey;
    }
}
