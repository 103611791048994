import {BasicProperty} from "../basic-entity-back/annotations";
import {Resource} from "../api/resource";
import {PropertyType} from "../basic-entity-back/property-type/property-type";
import {
    AfterDateFilter,
    BeforeDateFilter, BetweenDateFilter,
    DateFilters,
    StrictlyBeforeDateFilter
} from "../basic-entity-back/filters/date-filter";
import {BetweenRangeFilter, RangeFilters} from "../basic-entity-back/filters/range-filters";
import {ReadWrite} from "../basic-entity-back/basic-entity-interface/mapping-external";


export class BaseEntity extends Resource {

    @BasicProperty({
        type: PropertyType.Integer()
    })
    private _version: number;

    @BasicProperty({
        name: "Fecha Creación",
        type: PropertyType.Date(),
        filters: [BetweenDateFilter]
    })
    private _fechaCreacion;

    @BasicProperty({
        name: "Fecha Creación",
        type: PropertyType.DateTime(),
        readWrite: ReadWrite.ReadOnly
    })
    private _fechaCreacionTime;

    @BasicProperty({
        name: "Fecha Modificacion",
        type: PropertyType.Date()
    })
    private _fechaModificacion;


    @BasicProperty({
        name: "Creador",
        type: PropertyType.String()
    })
    private _creadoPor: string;

    @BasicProperty({
        name: "Modificado por",
        type: PropertyType.String()
    })
    private _modificadoPor: string;

    @BasicProperty({
        name: "Bloqueado por",
        type: PropertyType.String()
    })
    private _bloqueadoPor: string;

    get version(): number {
        return this._version;
    }

    set version(value: number) {
        this._version = value;
    }

    get fechaCreacion() {
        return this._fechaCreacion;
    }

    set fechaCreacion(value) {
        this._fechaCreacion = value;
    }

    get fechaModificacion() {
        return this._fechaModificacion;
    }

    set fechaModificacion(value) {
        this._fechaModificacion = value;
    }

    get creadoPor(): string {
        return this._creadoPor;
    }

    set creadoPor(value: string) {
        this._creadoPor = value;
    }

    get modificadoPor(): string {
        return this._modificadoPor;
    }

    set modificadoPor(value: string) {
        this._modificadoPor = value;
    }

    get bloqueadoPor(): string {
        return this._bloqueadoPor;
    }

    set bloqueadoPor(value: string) {
        this._bloqueadoPor = value;
    }

    get fechaCreacionTime() {
        return this._fechaCreacionTime;
    }

    set fechaCreacionTime(value) {
        this._fechaCreacionTime = value;
    }
}
