<div class="dialog-cell field-name">
    <label [attr.for]="property.modelKey">{{property.name}}</label>
</div>
<div class="dialog-cell field-value" [formGroup]="formGroup">
    <be-input *ngIf="!property.array; else arrayTemplate" [datePickerFilter]="datePickerFilter" [controlName]="property.modelKey" [property]="property" [formGroup]="formGroup">
    </be-input>

    <ng-template #arrayTemplate>
        <ng-container [formArrayName]="property.modelKey">
            <div ngClass="array-input-item" *ngFor="let control of getControls(); let i = index">
                <be-input [property]="property" [formGroup]="getControlAsFormGroup(control)" [controlName]="UNIQUE_CONTROL_NAME" [datePickerFilter]="datePickerFilter" [forceRequired]="true"></be-input>
                <button mat-icon-button color="warn" (click)="deleteArrayElement(property, i)"  *ngIf="!deleteDisabled(property, i)" >
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
            <button [ngClass]="getControls().length > 0 ? 'btn-add' : 'btn-add-no-items'"
                    mat-icon-button color="primary" (click)="addClick(property)" *ngIf="formGroup.controls[property.modelKey].enabled">
                <mat-icon>add</mat-icon>
            </button>
        </ng-container>
    </ng-template>
</div>
