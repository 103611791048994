<div>

    <div mat-dialog-content>
        <div style="display: flex;flex-direction: column; margin:1rem auto; max-width: 600px; padding: 1rem;">
            <h1 class="info" mat-dialog-title>¿Está seguro de que desea eliminar los datos?</h1>

        </div>
    </div>
    <div mat-dialog-actions>

        <button mat-button class="button" [mat-dialog-close]="data"  (click)="dialogRef.close(true)" color="primary" cdkFocusInitial>
            <mat-icon>delete_forever</mat-icon>Borrar
        </button>
        <button mat-button class="button" [mat-dialog-close]="data" (click)="dialogRef.close(false)" cdkFocusInitial>
            <mat-icon>cancel</mat-icon>Cancelar
        </button>
    </div>
</div>
