import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {InternalPropertyMap} from "../../../basic-entity-back/basic-entity-interface/mapping-internal";
import {ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Subscription} from "rxjs";
import {FilterAndData} from "../../../api/filter-list";
import {ExistsFilter} from "../../../basic-entity-back/filters/exists-filter";

@Component({
    selector: 'be-exists-filtering',
    templateUrl: './exists-filtering.component.html',
    styleUrls: ['./exists-filtering.component.scss'],
    providers: [{provide: NG_VALUE_ACCESSOR, useExisting: ExistsFilteringComponent, multi: true}]
})
export class ExistsFilteringComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() property: InternalPropertyMap;
    @Input() formControl: UntypedFormControl;
    public formGroup;

    private _subscription: Subscription;
    private _onChange = _ => {
    };
    private _onTouch = () => {
    };

    public get indeterminate(): boolean {
        return this.formGroup.get('null').value == null;
    }

    constructor(fb: UntypedFormBuilder) {
        this.formGroup = fb.group({
            null: undefined
        });
    }

    ngOnInit() {
        this._subscription = this.formGroup.valueChanges.subscribe(v => {
            if (this.formGroup.valid) {
                this._onChange(new FilterAndData(ExistsFilter, !v.null, this.property));
            }
        });
    }

    ngOnDestroy(): void {
        this._subscription && this._subscription.unsubscribe();
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.formGroup.setDisabledState(isDisabled);
    }

    writeValue(fad: any): void {
        if (fad instanceof FilterAndData
            && fad.property.modelKey === this.property.modelKey
            && fad.filter === ExistsFilter) {
            this.formGroup.setValue({null: !fad.data});
        } else {
            console.error('Invalid value ', fad);
        }
    }

}
