import {Injectable} from '@angular/core';
import * as THREE from "three";
import {MeshStandardMaterial, PerspectiveCamera, Scene, Vector2, WebGLRenderer} from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {STLLoader} from "three/examples/jsm/loaders/STLLoader";
import {Subject} from "rxjs";
import {PLYLoader} from "three/examples/jsm/loaders/PLYLoader";
import {SessionService} from "../session/session.service";

@Injectable({providedIn: 'root'})

export class ObjViewerService {

    scene: any;
    renderer: any;
    camera: any;
    controls: any;
    container: any;
    private Z_INIT = 100;
    loadedPercent: Subject<{ name: string, percent: number }[]> = new Subject();
    name: string;
    meshes: { name: string, percent: number }[] = [];
    animating = false;
    rendererSize = [];
    private authorization: string;

    constructor(private sessionService: SessionService) {
        this.authorization = 'Bearer ' + sessionService.token.token;
    }


    public iniciarEscena(rendererHTML, objViewer) {
        console.log("Iniciando escena")
        this.scene = new Scene();
        this.camera = new PerspectiveCamera(75, objViewer.offsetWidth / objViewer.offsetHeight, 1, 1000);
        this.renderer = new WebGLRenderer({alpha: true});
        this.renderer.setClearColor(0xffffff, 0);
        this.renderer.setSize(objViewer.offsetWidth, objViewer.offsetHeight);
        this.rendererSize = [objViewer.offsetWidth, objViewer.offsetHeight];
        rendererHTML.appendChild(objViewer, this.renderer.domElement);

        // Lights
        this._lightning();

        // Controls
        this._controls();

        this.animating = true;
        this._animate();
        // console.log(this.scene)

    }

    public escenaIniciada() {
        return this.scene !== null && this.rendererSize[0] > 0 && this.rendererSize[1] > 0;
    }

    public limpiarObj() {
        this.animating = false;
        if (this.scene) {
            this.scene.children.forEach(child => {
                if (child instanceof THREE.Group) {
                    this.scene.remove(child);
                }
            });

            this.renderer.render(this.scene, this.camera);
        }
    }

    public limpiarEscena() {
        this.animating = false;
        if (this.scene) {
            this.scene.children.forEach(child => {
                this.scene.remove(child);
            });

            this.renderer.render(this.scene, this.camera);
        }

        this.scene = null;
        this.renderer = null;
        this.container = null;
    }


    private _controls() {
        if (!this.scene) {
            return;
        }
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.camera.position.z = this.Z_INIT;

        // Update controls
        this.controls.update();
        this.controls.saveState();
    }

    private _lightning() {
        this.scene.add(new THREE.AmbientLight(0x00ff00));
        const directionalLightRightBack = new THREE.DirectionalLight(0xffffff, 1);
        const directionalLightLeftBack = new THREE.DirectionalLight(0xffffff, 1);
        const directionalLight3 = new THREE.DirectionalLight(0x64B0EB, 1);
        const distanceLight = this.Z_INIT;
        directionalLightRightBack.position.x = this.camera.position.x + distanceLight;
        directionalLightRightBack.position.y = this.camera.position.y - distanceLight;
        directionalLightRightBack.position.z = this.camera.position.z - 400;
        directionalLightLeftBack.position.x = this.camera.position.x - distanceLight;
        directionalLightLeftBack.position.y = this.camera.position.y + distanceLight + 100;
        directionalLightLeftBack.position.z = this.camera.position.z - 400;
        directionalLight3.position.x = this.camera.position.x - distanceLight;
        directionalLight3.position.y = this.camera.position.y;
        directionalLight3.position.z = this.camera.position.z + 200;
        this.scene.add(directionalLightRightBack);
        this.scene.add(directionalLightLeftBack);
        this.scene.add(directionalLight3);
    }

    private _animate() {
        const that = this;
        (function render() {
            if (that.animating) {
                requestAnimationFrame(render);
                that.controls.update();
                // console.log(that.camera.position);
                that.renderer.render(that.scene, that.camera);
            }
        }());
    }

    private _getContainerObjectByName(obj) {
        let objNew = null;
        this.container.traverse((child) => {
            if (child.name === obj) {
                objNew = child;
            }
        });
        return objNew;
    }

    private _getSceneObjectByName(name) {
        let obj = null;
        this.scene.traverse((child) => {
            if (child.name === name) {
                obj = child;
            }
        });
        return obj;
    }

    public loadObj(elementoDiseno, nombre) {
        if (this.container) {
            this.container.children = [];
        }
        const obj = elementoDiseno;
        this.meshes.push({name: nombre, percent: 0});

        let ext = nombre.split('.');
        ext = ext[ext.length - 1];
        if (ext.toLowerCase() === 'stl') {
            this._loadStl(obj, nombre);
        } else if (ext.toLowerCase() === 'ply' || ext.toLowerCase() === 'obj') {
            this._loadPly(obj, nombre)
        }
    }


    private _loadStl(stl: string, name: string) {
        const lo = new THREE.CubeTextureLoader();
        lo.setPath('/assets/entorno/cubemap/');

        // const textureCube = loader.load(['px.jpeg', 'nx.jpeg', 'py.jpeg', 'ny.jpeg', 'pz.jpeg', 'nz.jpeg']);
        const textureCube = lo.load(['px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png']);
        textureCube.encoding = THREE.sRGBEncoding;
        const material = new THREE.MeshStandardMaterial({
            color: 0x777777,
            emissive: 0x555555,
            metalness: 1,
            roughness: 0.7,
            envMap: textureCube
        });

        const loader = new STLLoader();
        loader.setRequestHeader({'Authorization': this.authorization})
        loader.load(
            stl,
            (geometry) => {
                const mesh = new THREE.Mesh(geometry, material);
                mesh.name = name;
                this._addObject(mesh);
            },
            (xhr) => {
                const percent = (xhr.loaded / xhr.total) * 100;
                this.meshes = this.meshes.map(objChild => {
                    if (objChild.name === name) {
                        objChild.percent = percent;
                        // console.log(obj.name + ' -> ' + percent + '% loaded');
                    }
                    return objChild;
                });
                this.loadedPercent.next(this.meshes);
            },
            (error) => {
                console.log(error);
            }
        );
    }


    private _loadPly(obj: string, name: string) {
        const material = new THREE.MeshStandardMaterial({
            color: 0x777777,
            emissive: 0x555555,
            metalness: 1,
            roughness: 0.8,
            flatShading: true
        });

        const loader = new PLYLoader();
        loader.setRequestHeader({'Authorization': this.authorization})
        loader.load(
            obj,
            (geometry) => {
                const mesh = new THREE.Mesh(geometry, material);
                mesh.name = name;
                this._addObject(mesh);
                this.rotatePosition('back')
            },
            (xhr) => {
                const percent = (xhr.loaded / xhr.total) * 100;
                this.meshes = this.meshes.map(objChild => {
                    // console.log(obj);
                    if (objChild.name === name) {
                        objChild.percent = percent;
                        // console.log(obj.name + ' -> ' + percent + '% loaded');
                    }
                    return objChild;
                });
                this.loadedPercent.next(this.meshes);

            },
            (error) => {
                console.log(error);
            }
        );
    }


    private _populateAndCenterContainer(meshes) {
        // Elimino el contenedor
        const obj = this._getSceneObjectByName('Container');
        if (obj) {
            this.scene.remove(obj);
        }

        // Creo un nuevo contenedor con los meshes
        this.container = new THREE.Group();
        this.container.name = 'Container';
        meshes.forEach(mesh => this.container.add(mesh));

        // Lo centro
        const box = new THREE.Box3().setFromObject(this.container).getCenter(this.container.position).multiplyScalar(-1);

        // Lo añado
        this.scene.add(this.container);
        this.renderer.render(this.scene, this.camera);

        // Zoom de la cámara para ver todo el objeto
        this._zoomCameraToSelection();
    }

    private _zoomCameraToSelection(fitOffset = 1.2) {

        const box = new THREE.Box3();
        box.expandByObject(this.container);

        const size = box.getSize(new THREE.Vector3());
        const center = box.getCenter(new THREE.Vector3());

        const maxSize = Math.max(size.x, size.y, size.z);
        const fitHeightDistance = maxSize / (2 * Math.atan(Math.PI * this.camera.fov / 360));
        const fitWidthDistance = fitHeightDistance / this.camera.aspect;
        const distance = fitOffset * Math.max(fitHeightDistance, fitWidthDistance);

        const direction = this.controls.target.clone()
            .sub(this.camera.position)
            .normalize()
            .multiplyScalar(distance);

        this.controls.maxDistance = distance * 10;
        this.controls.target.copy(center);

        this.camera.near = distance / 100;
        this.camera.far = distance * 100;
        this.camera.updateProjectionMatrix();

        this.camera.position.copy(this.controls.target).sub(direction);

        this.controls.update();

    }


    private _addObject(mesh) {
        // Obtengo las meshes del contenedor y los clono
        let meshes = [];
        if (this.container) {
            meshes = this.container.children.map(child => child.clone());
        }

        // Añado el nuevo
        meshes.push(mesh);

        this._populateAndCenterContainer(meshes);
    }


    public rotatePosition(pos) {
        this.controls.reset();
        if (pos === 'back') {
            this.camera.position.x = 0;
            this.camera.position.y = 0;
            this.camera.position.z = -this.Z_INIT;
            this.controls.update();
        }
        if (pos === 'front') {
            this.camera.position.x = 0;
            this.camera.position.y = 0;
            this.camera.position.z = this.Z_INIT;
            this.controls.update();
        }
        if (pos === 'left') {
            this.camera.position.x = -this.Z_INIT;
            this.camera.position.y = 0;
            this.camera.position.z = 0;
            this.controls.update();
        }
        if (pos === 'right') {
            this.camera.position.x = this.Z_INIT;
            this.camera.position.y = 0;
            this.camera.position.z = 0;
            this.controls.update();
        }
        if (pos === 'center') {
            this.controls.update();
        }
        // Zoom de la cámara para ver todo el objeto
        this._zoomCameraToSelection();
    }

    public removeObj(obj) {
        // console.log(this.container)
        const objNew = this._getContainerObjectByName(obj);
        if (objNew) {
            this.container.remove(objNew);
        }
    }

    public ver(obj) {
        const objNew = this._getContainerObjectByName(obj);
        if (objNew) {
            objNew.visible = true;
        }
    }

    public ocultar(obj) {
        const objNew = this._getContainerObjectByName(obj);
        if (objNew) {
            objNew.visible = false;
        }
    }


}
