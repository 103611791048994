<div class="date-time-input" [formGroup]="internalGroup">
    <mat-form-field>
        <input matInput
               [formControlName]="'time'"
               [value]="selectedTime ? selectedTime.format('HH:mm') : ''"
               readonly/>
        <mat-icon matSuffix
                  (click)="picker.open()">
            watch_later
        </mat-icon>
    </mat-form-field>
    <ngx-mat-timepicker #picker [format]="24" (timeSet)="setTime($event)"></ngx-mat-timepicker>
</div>
