import {Resource} from "../../api/resource";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {ManagerCachingStrategy} from "../../basic-entity-back/basic-entity-interface/mapping-external";

@BasicEntity({
    name: "Marca",
    endpoint: "marcas",
    isPaginated: false,
    modelNameProperty: "marca",
    managerCaching: ManagerCachingStrategy.PrecacheOnStart
})
export class Marca extends Resource {

    @BasicProperty({
        name: "ID",
        isId: true,
        type: PropertyType.Integer()
    })
    private _id: number;

    @BasicProperty({
        name: "Marca",
        type: PropertyType.String()
    })
    private _marca: string;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get marca(): string {
        return this._marca;
    }

    set marca(value: string) {
        this._marca = value;
    }
}
