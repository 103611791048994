import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Resource} from "../../api/resource";
import {BasicEntityInterface} from "../../basic-entity-back/basic-entity-interface/basic-entity-interface";
import {EntityManager} from "../../basic-entity-back/entity-manager/entity-manager";

export interface Section<T extends Resource> {
    name: string;
    list: T[];
    manager: EntityManager<T>;
}

export interface DeleteElementEvent<M extends Resource, S extends Section<M>> {
    index: number;
    section: S;
    element: M;
}

/**
 * This component displays a list of models by name
 */
@Component({
    selector: 'app-multi-model-list',
    templateUrl: './multi-model-list.component.html',
    styleUrls: ['./multi-model-list.component.scss']
})
export class MultiModelListComponent<T extends Section<Resource>> {
    @Input() public sections: T[] = [];
    @Input() public showDeletionButtons = true;

    @Output() public deleteElement = new EventEmitter<DeleteElementEvent<Resource, T>>();

    /**
     * Obtiene el name del section dado
     * @param section
     * @param entidad
     */
    public getName(section: T, entidad: Resource): string {
        if (!section.manager.loader.entityInterface || entidad.isTemporalEntity) {
            return '';
        }
        const interf = section.manager.loader.entityInterface as BasicEntityInterface<any>;
        return interf.getName(entidad);
    }
}
