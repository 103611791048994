import {Component, Inject, Type} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {BaseDialog} from "../basic-entity-table/base-dialog";
import {Resource} from "../../api/resource";
import {BaseDialogData} from "../basic-entity-table/base-dialog-data";
import {ApiModuleFactory} from "../../api/api-module-factory.service";
import {InterfaceProviderService} from "../../basic-entity-back/services/interface-provider.service";
import {ErrorDisplayService} from "../services/error-display.service";
import {InternalPropertyMap} from "../../basic-entity-back/basic-entity-interface/mapping-internal";
import {Subscription} from "rxjs";
import {ReadWrite} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {EntityManager} from "../../basic-entity-back/entity-manager/entity-manager";
import {EditionDialogFormManager} from "./edition-dialog-form-manager";
import {BloqueadorService} from "../../services/bloqueador.service";

/**
 * Default dialog for the BasicEntityTable to edit a row of the table,
 * which internally corresponds to edit one of the models.
 * This dialog uses BasicEntityInputComponent to allow the user
 * to edit the values and modify, this way, the model.
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */
@Component({
    selector: "be-edition-dialog",
    templateUrl: "./edition-dialog.component.html",
    styleUrls: ['./edition-dialog.component.scss']
})
export class EditionDialogComponent<T extends Resource> extends BaseDialog<T> {
    /** Title of the dialog */
    public title: string;
    /** FormGroup which controls all the dialog values */
    public readonly formGroup: UntypedFormGroup;
    /** Setted to true when the dialog is saving the item */
    public saving = false;
    /** Columns to show for edition */
    public readonly colsToShow: InternalPropertyMap[];
    /** A list of all the different tags found in the properties */
    public readonly tags: Set<string>;

    public get formManager(): EditionDialogFormManager<T> {
        return this._formManager;
    }

    private readonly _formManager: EditionDialogFormManager<T>;
    private readonly _manager: EntityManager<T>;
    public get manager(): EntityManager<T> {
        return this._manager;
    }

    private _changesSubscription: Subscription | null = null;

    public colsForTag(tag: string) {
        return this.colsToShow.filter(col => col.tag === tag);
    }

    /**
     * Constructor, it receives by injection the dialog reference, the dialog data and a form builder
     * to build the FormGroup
     * @param dialogRef - Reference to the created dialog (injected)
     * @param data - Data passed from the invoker (injected)
     * @param apiFactory - The factory of the api module to construct the loader
     * @param interfaceProvider - The interface provider from the basic entity module to get the interface
     * @param fb - Form builder (injected)
     * @param _errorDisplay - Service to display errors
     * @param _bloqueador
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) data: BaseDialogData<T>,
        dialogRef: MatDialogRef<EditionDialogComponent<T>>,
        apiFactory: ApiModuleFactory,
        public interfaceProvider: InterfaceProviderService,
        fb: UntypedFormBuilder,
        private _errorDisplay: ErrorDisplayService,
        _bloqueador: BloqueadorService = null
    ) {
        super(data, dialogRef, _bloqueador);
        this._manager = this.interfaceProvider.managerForModel(this.model.modelType as Type<T>);
        this.title = 'Edición de ' + this._manager.loader.entityInterface.name;
        if (!this.model.isTemporalEntity) {
            this.title += ' - #' + this.model.iri.id.join(';');
        }
        this.colsToShow = this.allowIdEdition ? this.properties : this.properties.filter(col => !col.isId);
        if (this.isNew) {
            this.colsToShow = this.colsToShow.filter(col => col.readWrite !== ReadWrite.ReadOnly);
        }
        this.tags = new Set(this.colsToShow.map(col => col.tag));
        this._formManager = new EditionDialogFormManager(
            this.allFieldsDisabled, this.allowIdEdition,
            fb, this._manager, this.interfaceProvider
        );
        this.formGroup = this._formManager.createFormGroup(this.model, this.colsToShow);
        this._changesSubscription = this.formGroup.valueChanges.subscribe(this._valueChanges.bind(this));
    }

    private _valueChanges() {
        this.modified = true;
    }

    /**
     * Accepts the dialog, modifying the model accordingly to the fields of
     * the form. If the form validation status is not VALID it does nothing.
     */
    public acceptDialog() {

        if (this.modified) {
            if (this.formGroup.status === "VALID") {
                const clone = this._manager.loader.entityInterface.serialiser.clone(this.model);
                this._formManager.formToModel(clone);
                if (this.shouldManageSaving) {
                    let observable;
                    if (this.isNew) {
                        observable = this._manager.add(clone);
                    } else {
                        observable = this._manager.update(clone);
                    }
                    this.saving = true;
                    observable.subscribe(
                        (received) => {
                            this._manager.loader.entityInterface.serialiser.copyInto(this.model, received);
                            this.closeDialog(true);
                        },
                        err => {
                            this.saving = false;
                            this._errorDisplay.displayError(err);
                        }
                    );
                } else {
                    this._manager.loader.entityInterface.serialiser.copyInto(this.model, clone);
                    this.closeDialog(true);
                }
            }
        } else {
            this.closeDialog(false);
        }
    }
}
