<div class="uri-input-content">
        <mat-select [ngClass]="'show'" *ngIf="dataSources.length > 1 && inputControl.enabled" [formControl]="selectControl">
            <mat-option *ngFor="let dataSource of dataSources" [value]="dataSource">
                {{dataSource.loader.entityInterface.name}}
            </mat-option>
        </mat-select>
        <input type="text" [placeholder]="placeholder" (focus)="selectAll($event)" [formControl]="inputControl" [matAutocomplete]="auto" [attr.id]="idAttr" [title]="title" #inputElement matInput>
</div>

<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayOption">
    <mat-option @fadeInFadeOut *ngFor="let option of options | async" [value]="modelToUri(option)">
        <span *ngIf="!contenido">{{getName(option)}}</span>
        <div style="display: flex; flex-direction: row; " *ngIf="contenido">
            <div *ngIf="!getContenido(option).matIcon.toString().includes('_svg')">
                <mat-icon [color]="getContenido(option).matIconColor">{{getContenido(option).matIcon}}</mat-icon>
            </div>
            <div *ngIf="getContenido(option).matIcon.toString().includes('_svg')">
                <mat-icon [color]="getContenido(option).matIconColor" svgIcon="{{getContenido(option).matIcon}}"></mat-icon>
            </div>
            <div>
                <div class="autocompleteTitulo">{{getContenido(option).titulo}}</div>
                <div class="autocompleteSegundaLinea">{{getContenido(option).segundaLinea}}</div>
            </div>
        </div>
    </mat-option>
</mat-autocomplete>
