import {Resource} from "../../api/resource";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {ManagerCachingStrategy} from "../../basic-entity-back/basic-entity-interface/mapping-external";

@BasicEntity({
    name: "Cabeza",
    endpoint: "cabezas",
    isPaginated: false,
    modelNamePattern: '\u2205{{cabeza}}',
    managerCaching: ManagerCachingStrategy.Lazy
})
export class Cabeza extends Resource {

    @BasicProperty({
        name: "ID",
        isId: true,
        type: PropertyType.Integer()
    })
    private _id: number;

    @BasicProperty({
        name: "Cabeza",
        type: PropertyType.String()
    })
    private _cabeza: string;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get cabeza(): string {
        return this._cabeza;
    }

    set cabeza(value: string) {
        this._cabeza = value;
    }
}
