import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {Resource} from "../../api/resource";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {ManagerCachingStrategy} from "../../basic-entity-back/basic-entity-interface/mapping-external";


@BasicEntity({
    name: "Asiento",
    endpoint: "asientos",
    isPaginated: false,
    managerCaching: ManagerCachingStrategy.Lazy,
    modelNameProperty: 'tipoAsiento'
})
export class Asiento extends Resource {

    @BasicProperty({
        name: "ID",
        isId: true,
        type: PropertyType.Integer()
    })
    private _id: number;

    @BasicProperty({
        name: "Tipo Asiento",
        type: PropertyType.String()
    })
    private _tipoAsiento: string;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get tipoAsiento(): string {
        return this._tipoAsiento;
    }

    set tipoAsiento(value: string) {
        this._tipoAsiento = value;
    }
}
