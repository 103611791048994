/**
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */
/**
 * Error thrown when we try to map a property which is not present in the mapping
 */
export class UnknownParam extends Error {
    constructor(modelKey: string) {
        super(`The model key '${modelKey}' is not present in the property mapping.`);
    }
}

/**
 * The IRI was necessary but its value inside the model is null or undefined
 */
export class IriNotPresent extends Error {
    constructor(msg: string = '') {
        super(`The IRI should have value but it has not. ${msg}`);
    }
}
