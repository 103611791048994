import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {Resource} from "../../api/resource";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {ManagerCachingStrategy} from "../../basic-entity-back/basic-entity-interface/mapping-external";


@BasicEntity({
    name: "Angulacion",
    endpoint: "angulacions",
    isPaginated: false,
    managerCaching: ManagerCachingStrategy.Lazy,
    modelNamePattern: '{{angulacion}}\xB0'
})
export class Angulacion extends Resource {

    @BasicProperty({
        name: "ID",
        isId: true,
        type: PropertyType.Integer()
    })
    private _id: number;

    @BasicProperty({
        name: "Tipo Angulacion",
        type: PropertyType.Integer()
    })
    private _angulacion;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get angulacion() {
        return this._angulacion;
    }

    set angulacion(value) {
        this._angulacion = value;
    }
}
