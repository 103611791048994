import {Component, HostBinding, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {EntityDatasource} from "../../api/entity.datasource";
import {Subscription} from "rxjs";
import {SearchService} from "../../services/search.service";
import {InternalPropertyMap} from "../../basic-entity-back/basic-entity-interface/mapping-internal";
import {GeneralSearchFilter} from "../../basic-entity-back/filters/general-search-filter";
import {FilterAndData} from "../../api/filter-list";
import {RouteFilterTranslator} from "./route-filter-translator";
import {FilterDefinition, FilterGroup} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {Filter} from "../../api/filter-interface";
import {BooleanFilter} from "../../basic-entity-back/filters/boolean-filter";
import {AfterDateFilter, BeforeDateFilter, DateFilters, StrictlyAfterDateFilter, StrictlyBeforeDateFilter} from "../../basic-entity-back/filters/date-filter";
import {ExistsFilter} from "../../basic-entity-back/filters/exists-filter";
import {NumericFilter} from "../../basic-entity-back/filters/numeric-filter";
import {BetweenRangeFilter, GreaterThanOrEqRangeFilter, GreaterThanRangeFilter, LowerThanOrEqRangeFilter, LowerThanRangeFilter, RangeFilters} from "../../basic-entity-back/filters/range-filters";
import {
    EndSearchFilter,
    ExactSearchFilter,
    MultipleSearchFilter,
    PartialSearchFilter,
    SimpleSearchFilter,
    StartSearchFilter,
    WordStartSearchFilter
} from "../../basic-entity-back/filters/search-filter";
import {FilterListComponent} from "./filter-list/filter-list.component";
import {BasicEntityTableConstants} from "../basic-entity-table/basic-entity-table-constants";

interface FilterRepresentation {
    name: string;
    icon?: string;
    description: string;
}

/**
 * This component filters the items in the indicated dataSource by all the possible
 * filters given the entities interface.
 * This component manages the query parameters and the general search in an stateless
 * way, using always the router to perform the changes, and listening to route changes
 * to reload the filtering in the DataSource.
 *
 * The params to filter in the route are preceded by "$f-" so they dont collide with
 * other params of the system.
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */
@Component({
    selector: "be-filtering",
    templateUrl: "./basic-entity-filtering.component.html",
    styleUrls: ["./basic-entity-filtering.component.scss"]
})
export class BasicEntityFilteringComponent<T> implements OnInit, OnDestroy {
    /** Time that the user has to stay without typing before the search is performed */
    private static readonly TIMEOUT_BEFORE_SEARCHING = 500;
    private static readonly FILTER_REPRESENTATION = new Map<Filter | FilterGroup, FilterRepresentation>([
        [BooleanFilter, {
            name: 'Exacto', icon: 'fa-toggle-on',
            description: 'Busca elementos con un valor en esta propiedad de "verdadero" o "falso".'
        }],
        [AfterDateFilter, {
            name: 'Después', icon: 'fa-calendar',
            description: 'Busca elementos cuyo valor en esta propiedad es posterior o igual a cierta fecha.'
        }],
        [BeforeDateFilter, {
            name: 'Antes', icon: 'fa-calendar',
            description: 'Busca elementos cuyo valor en esta propiedad es previo o igual a cierta fecha.'
        }],
        [StrictlyAfterDateFilter, {
            name: 'Estrictamente después', icon: 'fa-calendar',
            description: 'Busca elementos cuyo valor en esta propiedad es estrictamente posterior a cierta fecha.'
        }],
        [StrictlyBeforeDateFilter, {
            name: 'Estrictamente antes', icon: 'fa-calendar',
            description: 'Busca elementos cuyo valor en esta propiedad es estrictamente previo a cierta fecha.'
        }],
        [ExistsFilter, {
            name: '¿Nulo?', icon: 'fa-times',
            description: 'Busca elementos cuyo valor en esta propiedad es (o no es) nulo.'
        }],
        [NumericFilter, {
            name: 'Exacto', icon: 'fa-equals',
            description: 'Busca elementos cuyo valor en esta propiedad es exactamente el número indicado.'
        }],
        [GreaterThanRangeFilter, {
            name: 'Mayor', icon: 'fa-greater-than',
            description: 'Busca elementos cuyo valor en esta propiedad es mayor que cierto valor.'
        }],
        [GreaterThanOrEqRangeFilter, {
            name: 'Mayor o igual', icon: 'fa-greater-than-equal',
            description: 'Busca elementos cuyo valor en esta propiedad es mayor o igual que cierto valor.'
        }],
        [LowerThanRangeFilter, {
            name: 'Menor', icon: 'fa-less-than',
            description: 'Busca elementos cuyo valor en esta propiedad es menor que cierto valor.'
        }],
        [LowerThanOrEqRangeFilter, {
            name: 'Menor o igual', icon: 'fa-less-than-equal',
            description: 'Busca elementos cuyo valor en esta propiedad es menor o igual que cierto valor.'
        }],
        [BetweenRangeFilter, {
            name: 'Entre', icon: 'fa-band-aid',
            description: 'Busca elementos cuyo valor en esta propiedad se sitúa entre dos valores.'
        }],
        [MultipleSearchFilter, {
            name: 'Filtrar', icon: 'fa-search',
            description: 'Busca elementos cuyo valor en esta propiedad contiene un valor dado.'
        }],
        [SimpleSearchFilter, {
            name: 'Filtrar', icon: 'fa-search',
            description: 'Busca elementos cuyo valor en esta propiedad contiene un valor dado.'
        }],
        [StartSearchFilter, {
            name: 'Filtrar', icon: 'fa-search',
            description: 'Busca elementos cuyo valor en esta propiedad comienza con un valor dado.'
        }],
        [WordStartSearchFilter, {
            name: 'Filtrar', icon: 'fa-search',
            description: 'Busca elementos cuyo valor en esta propiedad tiene alguna palabra que comience por los carácteres dados.'
        }],
        [ExactSearchFilter, {
            name: 'Filtrar', icon: 'fa-search',
            description: 'Busca elementos cuyo valor en esta propiedad es exactamente alguna de las palabras dadas (separando por espacios, si cabe).'
        }],
        [EndSearchFilter, {
            name: 'Filtrar', icon: 'fa-search',
            description: 'Busca elementos cuyo valor en esta propiedad termina con un valor dado.'
        }],
        [PartialSearchFilter, {
            name: 'Filtrar', icon: 'fa-search',
            description: 'Busca elementos cuyo valor en esta propiedad contiene un valor determinado.'
        }],
        [DateFilters, {
            name: 'Filtrar', icon: 'fa-calendar',
            description: 'Busca elementos cuya fecha es anterior, posterior o igual a la fecha dada.'
        }],
        [RangeFilters, {
            name: 'Rango', icon: 'fa-band-aid',
            description: 'Busca elementos cuyo valor es mayor que cierto valor, menor que cierto valor o se sitúa entre dos valores.'
        }]
    ]);

    /** Properties to offer filters for */
    @Input() public properties: InternalPropertyMap[];
    /** Properties which can be filtered (obtained from the input properties) */
    public filtrableProperties: InternalPropertyMap[];
    /** The datasource this component should manage to make the filtering */
    @Input() protected dataSource: EntityDatasource<T>;

    @HostBinding('class.be-filtering') public classBiding = true;

    @ViewChild(FilterListComponent, { static:true }) filterList: FilterListComponent;

    /** Timeout to reload the filters (while you type this timeout is renovated) */
    private _filtersReloadTimeout = null;
    /** Subscription to the general search in the search bar */
    private _searchSubscription: Subscription = null;
    private _queryParamsSubscription: Subscription = null;

    public static sFilterOrGroupRepr(filter: FilterDefinition): FilterRepresentation {
        const name = BasicEntityFilteringComponent.FILTER_REPRESENTATION.get(filter);
        return name || {icon: 'fa-exclamation', name: 'Filtro desconocido', description: 'Este filtro no se encuentra en la lista de filtros.'};
    }

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _searchService: SearchService,
        private _routeFilterTranslator: RouteFilterTranslator
    ) {
    }

    public filterOrGroupRepr(filter: Filter | FilterGroup): FilterRepresentation {
        return BasicEntityFilteringComponent.sFilterOrGroupRepr(filter);
    }

    public addFilter(property: InternalPropertyMap, filter: FilterDefinition): void {
        this.filterList.addFilter(property, filter);
    }

    ngOnInit() {
        this.filtrableProperties = this.properties.filter(prop => prop.filters.length > 0);
        this._searchService.hideSearch = false;
        this._searchSubscription = this._searchService.search.subscribe(
            newSearch => this._redirectGeneralSearch(newSearch)
        );
        this._queryParamsSubscription = this._activatedRoute
            .queryParams.subscribe(this._manageParamsChange.bind(this));
    }

    public filtersChanged(list: { [id: string]: FilterAndData }) {
        this._filterNavigation(list);
    }

    private _manageParamsChange(params: Params) {
        const filters = {};
        Object.entries(this._routeFilterTranslator.paramsToFilters(params, this.properties))
            .filter(([k, f]) => f.data != null)
            .forEach(([k, f]) => filters[k] = f);

        this.dataSource.filtros = Object.values(filters);
        this.filterList.setFilters(filters);
        const generalSearch = this.dataSource.filtros.find(f => f.filter === GeneralSearchFilter);
        if (generalSearch) {
            this._searchService.currentSearch = generalSearch.data;
        } else {
            this._searchService.currentSearch = null;
        }
        clearTimeout(this._filtersReloadTimeout);
        this._filtersReloadTimeout = setTimeout(
            () => this.dataSource.load(),
            BasicEntityFilteringComponent.TIMEOUT_BEFORE_SEARCHING);
    }

    /**
     * @inheritDoc
     */
    ngOnDestroy(): void {
        this._searchSubscription.unsubscribe();
        this._queryParamsSubscription.unsubscribe();
        // this._searchService.hideSearch = true;
    }

    /**
     * Performs the navigation to set the filter for the general search.
     * If str is null it does nothing.
     * @private
     */
    private _redirectGeneralSearch(str: string | null) {
        if (str == null) {
            return;
        }

        if (str !== '') {
            this._filterNavigation({'gs': new FilterAndData(GeneralSearchFilter, str)});
        } else {
            this._filterNavigation({});
        }
    }

    private _filterNavigation(list: { [id: string]: FilterAndData }) {
        const filtered = {};
        Object.entries(list)
            .filter(([id, f]) => f.data != null)
            .forEach(([id, f]) => filtered[id] = f);

        const params = RouteFilterTranslator.sFiltersToParams(filtered);
        // Preserve pagination
        if (this.dataSource.pagination.pageSize) {
            params[BasicEntityTableConstants.PAGINATION_SIZE] = this.dataSource.pagination.pageSize;
        }

        this._router.navigate([], {
            queryParams: params,
            queryParamsHandling: ""
        }).catch(reason =>
            console.error("Navigation rejected", reason)
        );
    }
}
