import {Caster} from './caster';
import {Resource} from '../../api/resource';
import {BasicEntityInterface} from "../basic-entity-interface/basic-entity-interface";

/**
 * Converts from a nested model to its string, gives an error on the opposite direction
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */
export class NestedModelStringCaster implements Caster<Resource, string> {
    constructor(private _interf: BasicEntityInterface<Resource>) {
    }

    fromModel(modelValue: Resource): string {
        if (modelValue === null) {
            return null;
        }
        return this._interf.getName(modelValue);
    }

    toModel(apiValue: string): Resource {
        throw new Error('NestedModelStringCaster should be used only fromModel and not toModel');
    }
}
