import {Component} from '@angular/core';
import {BasicEntityTableColumnDescriptionOrString} from "../../../basic-entity-front/basic-entity-table/basic-entity-table.component";
import {Idioma} from "../../../model/localizacion/idioma.model";

@Component({
    selector: 'app-idioma',
    template: '<be-table [columnsToDisplay]="columns" [modelType]="model"></be-table>'
})
export class IdiomaComponent {
    public model = Idioma;
    public columns: BasicEntityTableColumnDescriptionOrString[] = [
        'codigoIdioma', 'nombreIdioma', 'icono'
    ];
}
