<mat-card class="recovery_card">
    <mat-card-title>
        Recuperación de contraseña
    </mat-card-title>
    <form [formGroup]="formGroup" (ngSubmit)="submit()" *ngIf="!done">
        <mat-card-content>
            <mat-form-field>
                <mat-label>{{'Email'}}</mat-label>
                <input matInput type="email" name="email" formControlName="email" required>
                <mat-error
                        *ngIf="formGroup.get('email').hasError('email')">{{'Dirección de correo inválida'}}</mat-error>
                <mat-error *ngIf="formGroup.get('email').hasError('required')">{{'El campo es requerido.'}}</mat-error>
            </mat-form-field>
        </mat-card-content>
        <mat-card-actions class="botonera">
            <button mat-flat-button color="primary" type="submit">Generar contraseña temporal</button>
            <button mat-flat-button color="accent" [routerLink]="'/login'">Volver</button>
        </mat-card-actions>
    </form>
    <div class="info sent" *ngIf="done">
        <p>Contraseña temporal enviada. Por favor, revise su bandeja de entrada.</p>
        <app-login>

        </app-login>
    </div>
</mat-card>
