<ng-container [ngSwitch]="property.type.toString()" [formGroup]="formGroup">

    <mat-form-field *ngSwitchCase="TypeStr.Choice" [floatLabel]="floatLabel">
        <mat-select [formControlName]="controlName" [placeholder]="placeholder" [required]="controlRequired()">
            <mat-option *ngFor="let choice of property.type.asChoice().choices" [value]="choice">
                {{choice}}
            </mat-option>
            <mat-option *ngIf="!controlRequired()">
                -- Ninguno --
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="TypeStr.Date" [floatLabel]="floatLabel">
        <input *ngIf="datePickerFilter != null" matInput [matDatepicker]="picker" [formControlName]="controlName"
               [matDatepickerFilter]="datePickerFilter" [min]="datePickerMin" [required]="controlRequired()"
               [placeholder]="placeholder || property.name">
        <input *ngIf="datePickerFilter == null" matInput [matDatepicker]="picker" [formControlName]="controlName"
               [min]="datePickerMin" [max]="datePickerMax" [required]="controlRequired()" [placeholder]="placeholder">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <be-time-input *ngSwitchCase="TypeStr.Time" [formControlName]="controlName" [required]="controlRequired()"
                   [placeholder]="placeholder"></be-time-input>

    <be-date-time-input *ngSwitchCase="TypeStr.DateTime" [formControlName]="controlName"
                        [placeholder]="placeholder"></be-date-time-input>

    <div *ngSwitchCase="TypeStr.Boolean" class="fake-form-field">
        <mat-checkbox [ngClass]="booleanClass" [formControlName]="controlName" [indeterminate]="booleanIndeterminate"
                      [color]="booleanRight ? 'primary' : 'warn'">
            {{booleanText}}
        </mat-checkbox>
    </div>

    <mat-form-field *ngSwitchCase="TypeStr.Float" [floatLabel]="floatLabel">
        <input matInput [formControlName]="controlName" [placeholder]="placeholder" type="number"
               [min]="property.type.asRange().min" [max]="property.type.asRange().max" step="0.001"
               [required]="controlRequired()">
    </mat-form-field>


    <mat-form-field *ngSwitchCase="TypeStr.Financial" [floatLabel]="floatLabel">
        <input matInput [formControlName]="controlName" [placeholder]="placeholder" type="number"
               [min]="property.type.asRange().min" [max]="property.type.asRange().max" step="0.01"
               [required]="controlRequired()">
        <span matSuffix>€</span>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="TypeStr.Decimal" [floatLabel]="floatLabel">
        <input matInput [formControlName]="controlName" [placeholder]="placeholder" type="text"
               [maxlength]="property.type.asDecimal().maxLength" [required]="controlRequired()">
        <ng-container *ngTemplateOutlet="matErrorTemplate"></ng-container>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="TypeStr.Integer" [floatLabel]="floatLabel">
        <input matInput [formControlName]="controlName" [placeholder]="placeholder" type="number"
               [min]="property.type.asRange().min" [max]="property.type.asRange().max" step="1"
               [required]="controlRequired()">
    </mat-form-field>

    <mat-form-field *ngSwitchCase="TypeStr.String" [floatLabel]="floatLabel">
        <input matInput [formControlName]="controlName" [placeholder]="placeholder"
               [minlength]="property.type.asWithLength().minLength" [maxlength]="property.type.asWithLength().maxLength"
               type="text" [attr.id]="property.modelKey" [title]="property.name" [required]="controlRequired()">
    </mat-form-field>

    <mat-form-field *ngSwitchCase="TypeStr.Image" [floatLabel]="floatLabel">
        <input matInput [formControlName]="controlName" [placeholder]="placeholder" type="text"
               [attr.id]="property.modelKey" [title]="property.name" [required]="controlRequired()">
        <mat-icon matSuffix [inline]="true">photo</mat-icon>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="TypeStr.Text" [floatLabel]="floatLabel">
        <textarea matInput [formControlName]="controlName" cdkTextareaAutosize [cdkAutosizeMinRows]="2"
                  [cdkAutosizeMaxRows]="7" [placeholder]="placeholder"
                  [minlength]="property.type.asWithLength().minLength"
                  [maxlength]="property.type.asWithLength().maxLength"
                  [attr.id]="property.modelKey" [title]="property.name" [required]="controlRequired()"></textarea>
    </mat-form-field>

    <be-dictionary-input *ngSwitchCase="TypeStr.Dictionary" [placeholder]="placeholder || ''" [floatLabel]="floatLabel"
                         [formControlName]="controlName" [required]="controlRequired()">
    </be-dictionary-input>

    <be-dictionary-input *ngSwitchCase="TypeStr.Translation" [placeholder]="placeholder || ''" [floatLabel]="floatLabel"
                         [keyProvider]="translationsProvider" [formControlName]="controlName"
                         [required]="controlRequired()">
    </be-dictionary-input>

    <mat-form-field *ngSwitchCase="TypeStr.Email" [floatLabel]="floatLabel">
        <input matInput [formControlName]="controlName" [pattern]="property.type.asPattern().pattern"
               [minlength]="property.type.asWithLength().minLength" [maxlength]="property.type.asWithLength().maxLength"
               [placeholder]="placeholder" type="email" [attr.id]="property.modelKey"
               [title]="property.name" [required]="controlRequired()">
    </mat-form-field>

    <mat-form-field *ngSwitchCase="TypeStr.ExternalLink" [floatLabel]="floatLabel">
        <input matInput [formControlName]="controlName" [pattern]="property.type.asPattern().pattern"
               [minlength]="property.type.asWithLength().minLength" [maxlength]="property.type.asWithLength().maxLength"
               [placeholder]="placeholder" type="url" [attr.id]="property.modelKey"
               [title]="property.name" [required]="controlRequired()">
        <mat-icon matSuffix>link</mat-icon>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="TypeStr.PhoneNumber" [floatLabel]="floatLabel">
        <input matInput [formControlName]="controlName" [minlength]="property.type.asWithLength().minLength"
               [maxlength]="property.type.asWithLength().maxLength" [placeholder]="placeholder" type="tel"
               [attr.id]="property.modelKey" [title]="property.name" [required]="controlRequired()">
    </mat-form-field>

    <ng-container *ngSwitchCase="TypeStr.Uri">
        <mat-form-field *ngIf="!shouldBeSelect" [floatLabel]="floatLabel">
            <be-uri-input [idAttr]="property.modelKey" [title]="property.name" [placeholder]="placeholder"
                          [modelTypeOrParent]="property.type.asUri().modelTypeOrParent"
                          (interfaz)="propagarInterfaz($event)" [formControlName]="controlName"
                          [required]="controlRequired()" [contenido]="contenido" [forcedInterface]="forcedInterface">
            </be-uri-input>
        </mat-form-field>
        <mat-form-field *ngIf="shouldBeSelect" [floatLabel]="floatLabel">
            <!--            <mat-label>{{property.name}} *</mat-label>-->
            <be-cached-input [onlyUri]="true" [placeholder]="placeholder" [formControlName]="controlName"
                             [required]="controlRequired()" [filters]="filters" [selectedValue]="selectedValue"
                             [modelTypeOrParent]="property.type.asUri().modelTypeOrParent">
            </be-cached-input>
        </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="TypeStr.NestedModel">
        <be-nested-input *ngIf="!shouldBeSelect" [id]="property.modelKey" [title]="property.name"
                         [placeholder]="placeholder" [floatLabel]="floatLabel"
                         [nestedModelType]="property.type.asNestedModel()" [formControlName]="controlName"
                         [required]="controlRequired()"
                         [contenido]="contenido">
        </be-nested-input>
        <mat-form-field *ngIf="shouldBeSelect" [floatLabel]="floatLabel">
            <be-cached-input [onlyUri]="false" [placeholder]="placeholder" [formControlName]="controlName"
                             [required]="controlRequired()" [filters]="filters" [selectedValue]="selectedValue"
                             [modelTypeOrParent]="property.type.asNestedModel().modelType">
            </be-cached-input>
        </mat-form-field>
    </ng-container>
</ng-container>

<ng-template #matErrorTemplate>
    <ng-container *ngIf="doesControlHaveErrors()">
        <ng-container *ngFor="let error of getControlErrors()">
            <mat-error>
                {{error[1].message ? error[1].message : (error[0] === 'required' ? 'Requerido' : 'Unknown error')}}
            </mat-error>
        </ng-container>
    </ng-container>
</ng-template>
