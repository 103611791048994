import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators} from "@angular/forms";
import {TypeStr} from "../../basic-entity-back/property-type/type-str";
import {TranslationInputService} from "../../basic-entity-back/services/translation-input.service";
import {InternalPropertyMap} from "../../basic-entity-back/basic-entity-interface/mapping-internal";
import {FloatLabelType} from "@angular/material/form-field";
import {Moment} from "moment";
import {InterfaceProviderService} from "../../basic-entity-back/services/interface-provider.service";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {MatOptionModel} from './nested-input/mat-option-model';
import {EntityDatasource} from "../../api/entity.datasource";
import {Resource} from "../../api/resource";
import {BasicEntityInterface} from "../../basic-entity-back/basic-entity-interface/basic-entity-interface";
import {BehaviorSubject, Subject} from "rxjs";
import {FilterAndData} from "../../api/filter-list";

/**
 * The EditionDialogComponent provides control of a single value inside
 * the model properties. It can be part of an array or not. It constructs its own
 * control through static methods and chooses the right display corresponding
 * to the column type.
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */
@Component({
    selector: "be-input",
    templateUrl: "./basic-entity-input.component.html",
    styleUrls: ["./basic-entity-input.component.scss"]
})
export class BasicEntityInputComponent implements OnInit, OnChanges {
    /** Name to give to the control in the arrays, where we have to create a group per index with a control inside */
    public static readonly UNIQUE_CONTROL_NAME = "uniqueElement";

    /** FormGroup which controls the input */
    @Input() public formGroup: UntypedFormGroup;
    /** Property which relates the control to the model */
    @Input() public property: InternalPropertyMap;
    /** Name to give to the control */
    @Input() public controlName: string;
    /** Use this input to force the required of the element to a value */
    @Input() public forceRequired: boolean = null;
    /** Placeholder for the input */
    @Input() public placeholder: string = null;
    /** Float label for mat-form-field */
    @Input() public floatLabel: FloatLabelType = 'never';
    /** Date picker filter for the date picker to set (if any) */
    @Input() public datePickerFilter: (date: Moment | null) => boolean = undefined;
    @Input() public datePickerMin: Moment;
    @Input() public datePickerMax: Moment;
    @Input() public contenido: MatOptionModel;
    @Input() public forcedInterface;
    @Input() public choiceType: string;
    @Input() public filters = [];
    @Input() public selectedValue = null;
    @Output() interfaz = new EventEmitter<EntityDatasource<Resource, BasicEntityInterface<Resource>>>();
    /** Little workaround to use TypeStr from the template */
    public TypeStr: typeof TypeStr = TypeStr;

    /**
     * This static method creates the right FormControl for the dialog input component,
     * prefer this method rather than creating it manually.
     * @param fb - Form builder to construct the control
     * @param property - Property the control will be related to
     * @param val - Initial value for the control
     * @param disabled - Whether the control is disabled or not
     * @param i - Index of the control inside the array (if it is inside an array)
     * @param forceRequired - If different from null, it will force the required value of the component
     */
    public static sFormControlForColumn(
        fb: UntypedFormBuilder,
        property: InternalPropertyMap,
        val: any,
        disabled: boolean,
        i: number = null,
        forceRequired: boolean | null = null
    ): AbstractControl {
        const validators = this._sValidatorsFor(property, forceRequired);
        if (i !== null) {
            if (!property.array) {
                throw new Error("Index given, but the passed column has not array type.");
            }
            // Just the way FormArray works: a FormArray contains an array of FormGroups,
            // and each FormGroup contains the controls. In this case, we create a single
            // control and introduce it in the group which we return.
            const formControl = fb.control(
                {value: val, disabled: disabled},
                validators
            );
            const formGroup = fb.group({});
            formGroup.addControl(
                BasicEntityInputComponent.UNIQUE_CONTROL_NAME,
                formControl
            );
            return formGroup;
        } else {
            // Just a simple control
            return fb.control({value: val, disabled: disabled}, validators);
        }
    }

    public static sRequiredDependingOn(otherProperty: string): ValidatorFn {
        return (control: AbstractControl) => {
            if (control.parent && control.parent.get(otherProperty)) {
                if (!control.parent.get(otherProperty).value) {
                    return null;
                } else {
                    return control.value ? null : {isRequired: true};
                }
            }
            return null;
        };
    }

    /**
     * Gets the validators to apply to a FormControl for a given column with a given forceRequired value
     */
    private static _sValidatorsFor(
        property: InternalPropertyMap,
        forceRequired: boolean | null
    ): ValidatorFn[] | ValidatorFn | null {
        // Turns out we need to make sure to create the control with 'required' validator
        // if it is going to be required, since adding it later when this component is added
        // as a subcomponent of another one may generate an ExpressionChangedAfterItHasBeenCheckedError
        const typeValidators = property.type.validators;
        if (this._sControlRequired(property, forceRequired)) {
            const requireValidator = property.dependsOn == null ?
                Validators.required :
                BasicEntityInputComponent.sRequiredDependingOn(property.dependsOn);
            if (typeValidators === null) {
                return requireValidator;
            } else if (typeValidators instanceof Array) {
                return [requireValidator, ...typeValidators];
            } else {
                return [requireValidator, typeValidators];
            }
        } else {
            return typeValidators;
        }
    }

    /**
     * Returns whether the control will be required or not
     */
    private static _sControlRequired(
        property: InternalPropertyMap,
        forceRequired: boolean | null
    ): boolean {
        if (forceRequired === null) {
            return !property.nullable && !property.array;
        } else {
            return forceRequired;
        }
    }

    /**
     * @param translationsProvider Used for translation dictionaries
     * @param _intProv
     */
    constructor(public translationsProvider: TranslationInputService, private _intProv: InterfaceProviderService) {
    }

    ngOnInit(): void {
        if (this.placeholder == null) {
            this.placeholder = this.property.name;
        }
        if (this.requireDependsOnAnotherProperty()) {
            this.placeholder += ' *'; // Looks like required
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.filters && changes.filters.currentValue) {
            this.filters = changes.filters.currentValue;
        }
        if (changes.selectedValue && changes.selectedValue.currentValue) {
            this.selectedValue = changes.selectedValue.currentValue;
        }
    }

    public get booleanText() {
        const value = this.formGroup.controls[this.controlName].value;
        if (value === false) {
            return 'No';
        } else if (value) {
            return 'Sí';
        } else {
            return 'No escogido';
        }
    }

    public get booleanRight(): boolean {
        const value = this.formGroup.controls[this.controlName].value;
        return !this.controlRequired() || value != null;
    }

    public get booleanClass() {
        const value = this.formGroup.controls[this.controlName].value;
        if (value === false || value === true) {
            return 'chosen';
        } else {
            return this.controlRequired() ? 'not-chosen-required' : 'not-chosen';
        }
    }

    public get booleanIndeterminate(): boolean {
        return this.formGroup.controls[this.controlName].value == null;
    }

    /**
     * Used to determine, in URI and NestedModel types, whether we should show a select
     * or an autocomplete
     */
    public get shouldBeSelect(): boolean {
        const t = this.property.type;
        if (t.toString() === TypeStr.Uri) {
            return this._intProv.allAcceptedInterfaces(t.asUri().modelTypeOrParent)
                .map(i => !!i.managerCaching || this.filters.length > 0)
                .reduce((prev, cur) => prev && cur, true);
        } else if (t.toString() === TypeStr.NestedModel) {
            const type = t.asNestedModel();
            const intrf = this._intProv.interfaceForModel(type.modelType);
            return !!intrf.managerCaching && type.behavior !== NestedBehavior.CreateOnly;
        }
        return false;
    }

    /** Returns whether this control corresponds to a required property or not */
    controlRequired(): boolean {
        const required = BasicEntityInputComponent._sControlRequired(
            this.property,
            this.forceRequired
        );
        // dependsOn causes some problems, so we just don't require them, and we
        // simply change the placeholder to contain an extra ' *'
        if (this.requireDependsOnAnotherProperty()) {
            return false;
        } else {
            return required;
        }
    }

    protected requireDependsOnAnotherProperty(): boolean {
        // If it gives not required, it is never required, even if dependsOn value is true
        if (!BasicEntityInputComponent._sControlRequired(this.property, this.forceRequired)) {
            return false;
        }
        return this.forceRequired == null && !!this.property.dependsOn;
    }

    /** Checks whether the control has errors or not */
    public doesControlHaveErrors(): boolean {
        const control = this.formGroup.controls[this.controlName];
        if (!control) {
            return false;
        }
        return control.invalid;
    }

    /** Gets the controls errors as an array of pairs [key, error] */
    public getControlErrors(): any[] {
        const control = this.formGroup.controls[this.controlName];
        if (!control) {
            return [];
        }
        return Object.entries(control.errors);
    }

    public propagarInterfaz($event) {
        this.interfaz.emit($event);
    }
}
