/**
 * Nedaware S.L.
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RegistroComponent} from './registro/registro.component';
import {ActivationComponent} from './activation/activation.component';
import {LoginComponent} from './login/login.component';
import {PasswordRecoveryComponent} from './password-recovery/password-recovery.component';
import {LoginGuard} from "./login.guard";

const routes: Routes = [
    {
        path: 'login',
        canActivate: [LoginGuard],
        data: {ifLogged: "/inicio", animation: 'flip'},
        component: LoginComponent
    },
    {
        path: 'registro',
        component: RegistroComponent
    },
    {
        path: 'password-recovery',
        canActivate:[LoginGuard],
        component: PasswordRecoveryComponent,
    },
    {
        path: 'activation/:userId',
        component: ActivationComponent
    },
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SessionRoutingModule {
}
