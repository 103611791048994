import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {SessionService} from './session.service';
import {Cliente} from "../model/personas/cliente.model";
import {Usuario} from "../model/personas/usuario.model";

@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {
    constructor(private _router: Router, private _sessionService: SessionService) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this._canActivate(next, state);
    }

    private _canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this._sessionService.loggedIn) {
            if (next.data.ifLogged) {
                let options = undefined;
                if (next.data.saveUrl) {
                    options = {queryParams: {return: state.url}};
                }
                this._router.navigate(next.data.ifLogged, options)
                    .catch(err => console.error(err));
                return false;
            }
            return true;
        } else {
            if (next.data.ifNotLogged) {
                console.log("No logueado")
                let options = undefined;
                if (next.data.saveUrl) {
                    options = {queryParams: {return: state.url}};
                }
                this._router.navigate([next.data.ifNotLogged], options).then().catch(err => console.error(err));
                return false;
            }
            return true;
        }
    }
}
