import {Caster} from './caster';

/**
 * Casts any parseable object from and to a JSON string
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */
export class JsonCaster implements Caster<any, string> {
    fromModel(modelValue: any): string {
        return JSON.stringify(modelValue);
    }

    toModel(apiValue: string): any {
        return JSON.parse(apiValue);
    }
}
