import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FlatTreeControl} from "@angular/cdk/tree";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import {MENU_ENDPOINT, MenuItem} from "../../../shell/menu/menu.component";
import {BehaviorSubject, Observable, of} from "rxjs";
import {ApiService} from "../../../api/api.service";
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from "@angular/forms";
import {Route, Router} from "@angular/router";
import {ErrorDisplayService} from "../../../basic-entity-front/services/error-display.service";

export class ItemFlatNode {
    constructor(
        public id: number,
        public level: number,
        public expandable: boolean,
        public icon: string | null,
        public link: string | null,
        public text: string,
        public requiredRole: string,
        public queryParams: string | null) {
    }
}

@Component({
    selector: "app-items",
    templateUrl: "./items.component.html",
    styleUrls: ["./items.component.scss"]
})
export class ItemsComponent implements OnInit {
    public treeControl: FlatTreeControl<ItemFlatNode>;
    public treeFlattener: MatTreeFlattener<MenuItem, ItemFlatNode>;
    public dataSource: MatTreeFlatDataSource<MenuItem, ItemFlatNode>;

    @ViewChild("deletionConfirmation") private _dialogTempRef: TemplateRef<any>;
    private _dialogRef: MatDialogRef<any, any>;
    public linkEditable = true;
    public linkRequerido = false;
    public templateForm: UntypedFormGroup;
    public loading$ = new BehaviorSubject(true);
    public rutasAplicacion: string[] = [];

    public requiredLink(control: AbstractControl): ValidationErrors | null {
        if (this.linkRequerido && (!control.value || control.value === "")) {
            return { required: true };
        }
        return null;
    }

    constructor(
        fb: UntypedFormBuilder,
        router: Router,
        public api: ApiService,
        private _dialogService: MatDialog,
        private _errorService: ErrorDisplayService
    ) {
        this.templateForm = fb.group({
            name: fb.control("", Validators.required),
            link: fb.control("", this.requiredLink.bind(this)),
            queryParams: fb.control(null),
            role: fb.control("", Validators.required),
            icon: ""
        });
        this.rutasAplicacion = this._extraerRutas(router.config);
        this.treeFlattener = new MatTreeFlattener(
            this.transformer,
            this._getLevel,
            this._isExpandable,
            this._getChildren
        );
        this.treeControl = new FlatTreeControl<ItemFlatNode>(
            this._getLevel,
            this._isExpandable
        );
        this.dataSource = new MatTreeFlatDataSource<MenuItem, ItemFlatNode>(
            this.treeControl,
            this.treeFlattener
        );
        this.reload();
    }

    ngOnInit() {}

    private _extraerRutas(rutas: Route[], parentPath: string = null): string[] {
        return rutas
            .map(ruta => {
                const rutaPath = parentPath
                    ? parentPath + "/" + ruta.path
                    : ruta.path;
                if (ruta.children) {
                    return [
                        ...this._extraerRutas(ruta.children, rutaPath),
                        rutaPath
                    ];
                } else {
                    return [rutaPath];
                }
            })
            .reduce((p, c) => [...c, ...p], []);
    }

    public reload() {
        this.loading$.next(true);
        this.api
            .getCollection<MenuItem>(MENU_ENDPOINT)
            .pipe(ApiService.sTakeBody())
            .subscribe(menuItems => {
                this.loading$.next(false);
                this.dataSource.data = menuItems.member;
            });
    }

    public transformer = (node: MenuItem, level: number) => {
        return new ItemFlatNode(
            node.id,
            level,
            node.children.length > 0,
            node.icono,
            node.link,
            node.name,
            node.requiredRole,
            node.queryParams
        );
    };

    private _getLevel = (node: ItemFlatNode) => node.level;
    private _isExpandable = (node: ItemFlatNode) => node.expandable;
    private _getChildren = (node: MenuItem): Observable<MenuItem[]> =>
        of(node.children);
    hasChild = (_: number, _nodeData: ItemFlatNode) => _nodeData.expandable;

    public add(item: ItemFlatNode | null) {
        const pos = item
            ? this.treeControl.getDescendants(item).length
            : this.dataSource.data.length;
        this.templateForm.setValue({
            name: item ? item.text + pos : "",
            link: "",
            role: item ? item.requiredRole : "",
            queryParams: null,
            icon: ""
        });
        this.linkEditable = true;
        this.linkRequerido = false;
        this._dialogRef = this._dialogService.open(this._dialogTempRef);
        this._dialogRef.afterClosed().subscribe(accepted => {
            if (accepted) {
                const obj = {
                    name: this.templateForm.value.name,
                    link: this.templateForm.value.link,
                    icono: this.templateForm.value.icon,
                    requiredRole: this.templateForm.value.role,
                    orden: pos
                };
                if (item) {
                    obj["parent"] = MENU_ENDPOINT + "/" + item.id;
                }
                this.api
                    .post(MENU_ENDPOINT, obj)
                    .subscribe(() => this.reload(), error => this._errorService.displayRaw(error.error));
            }
        });
    }

    public edit(item: ItemFlatNode) {
        console.log(item)
        this.templateForm.setValue({
            name: item.text,
            link: item.link,
            queryParams: item.queryParams ?? "",
            role: item.requiredRole,
            icon: item.icon
        });
        this.linkEditable = !item.expandable;
        this.linkRequerido = this.linkEditable;
        this._dialogRef = this._dialogService.open(this._dialogTempRef);
        this._dialogRef.afterClosed().subscribe(accepted => {
            if (accepted) {
                this.api
                    .put(MENU_ENDPOINT + "/" + item.id, {
                        name: this.templateForm.value.name,
                        link: this.templateForm.value.link,
                        icono: this.templateForm.value.icon,
                        requiredRole: this.templateForm.value.role,
                        queryParams: this.templateForm.value.queryParams
                    })
                    .subscribe(() => this.reload());
            }
        });
    }

    public move(item: ItemFlatNode, dir: number) {
        const parent = this._searchParent(item.id);
        const siblings = parent ? parent.children : this.dataSource.data;

        const idx = siblings.findIndex(s => s.id === item.id);
        const newIdx = idx + dir;
        if (newIdx < 0 || newIdx >= siblings.length) {
            return;
        }
        const exchangedWith = siblings[newIdx];
        let reload = false;
        this.api
            .put(MENU_ENDPOINT + "/" + item.id, { orden: newIdx })
            .subscribe(() => (reload ? this.reload() : (reload = true)));
        this.api
            .put(MENU_ENDPOINT + "/" + exchangedWith.id, { orden: idx })
            .subscribe(() => (reload ? this.reload() : (reload = true)));
    }

    private _searchParent(
        id: number,
        currentParent: MenuItem = null
    ): MenuItem | null | false {
        const children = currentParent
            ? currentParent.children
            : this.dataSource.data;
        for (const item of children) {
            if (item.id === id) {
                return currentParent;
            } else {
                const deep = this._searchParent(id, item);
                if (deep !== false) {
                    return deep;
                }
            }
        }
        return false; // not found
    }

    public remove(item: ItemFlatNode) {
        this.api
            .del(MENU_ENDPOINT + "/" + item.id)
            .subscribe(() => this.reload());
    }

    public cancelEdition() {
        this._dialogRef.close(false);
    }

    public acceptEdition() {
        this._dialogRef.close(true);
    }
}
