import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatest, of, Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {ApiService} from '../../api/api.service';
import {SessionService} from '../session.service';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.css']
})
export class ActivationComponent implements OnInit, OnDestroy {
  private _subscription: Subscription;
  public error = false;

  constructor(private _route: ActivatedRoute,
              private _api: ApiService,
              private _session: SessionService,
              private _router: Router) {
  }

  ngOnInit() {
    this._subscription = combineLatest([ this._route.params, this._route.queryParams ])
      .pipe(
      switchMap(([params, query]) => {
        if (params['userId'] && query['c']) {
          return this._api.put(
            `/usuarios/${params['userId']}/activate`,
            {code: query['c']})
            .pipe(ApiService.sTakeBody());
        } else {
          return of(null);
        }
      }),
    ).subscribe(result => {
      if (result) {
        this._session.useToken(result['token']);
        this._router
          .navigate(['/welcome'], {queryParamsHandling: ''})
          .catch(err => console.error(err));
      } else {
        this.error = true;
      }
    }, err => {
      console.error(err);
      this.error = true;
    });
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }
}
