import {NestedBehavior} from '../../basic-entity-back/property-type/nested-model-type';
import {BasicEntity, BasicProperty} from '../../basic-entity-back/annotations';
import {PropertyType} from '../../basic-entity-back/property-type/property-type';
import {Provincia} from './provincia.model';
import {Zona} from './zona.model';
import {ReadWrite, SearchType} from '../../basic-entity-back/basic-entity-interface/mapping-external';
import {Festivo} from './festivo.model';
import {PuntoRecogida} from '../envios/punto-recogida.model';
import {GeneralSearchFilter} from "../../basic-entity-back/filters/general-search-filter";

@BasicEntity({
    name: 'Municipios',
    endpoint: 'municipios',
    isPaginated: true,
    paginationSizes: [20, 40],
    acceptsBulkIriSearch: true,
    modelNameProperty: 'resumen',
    type: 'Municipio'
})
export class Municipio extends PuntoRecogida {
    @BasicProperty({
        isId: true,
        name: 'ID',
        keyInApi: 'idMunicipio',
        searchable: SearchType.Exact,
        sortable: true,
        type: PropertyType.Integer(),
    })
    private _id;

    @BasicProperty({
        name: 'Nombre',
        keyInApi: 'nombreMunicipio',
        filters: [GeneralSearchFilter],
        sortable: true,
        type: PropertyType.String()
    })
    private _nombre;

    @BasicProperty({
        name: 'Alias',
        type: PropertyType.String(),
        filters: [GeneralSearchFilter],
        sortable: true,
        nullable: true,
        keyInApi: 'aliasMunicipio'
    })
    private _alias;

    @BasicProperty({
        name: 'Provincia',
        searchable: SearchType.Exact,
        type: PropertyType.Uri(Provincia),
        sortable: true,
    })
    private _provincia;

    @BasicProperty({
        name: 'Zona propia',
        nullable: true,
        type: PropertyType.NestedModel(Zona, undefined, NestedBehavior.SendIriOnly),
        sortable: true,
    })
    private _zonaPropia;

    @BasicProperty({
        name: 'Zona',
        readWrite: ReadWrite.ReadOnly,
        nullable: true,
        type: PropertyType.NestedModel(Zona, undefined, NestedBehavior.SendIriOnly)
    })
    private _zona;

    @BasicProperty({
        tag: 'Festivos',
        name: 'Festivos propios',
        type: PropertyType.Uri(Festivo),
        array: true
    })
    private _festivosPropios: object = [];

    @BasicProperty({
        tag: 'Festivos',
        name: 'Festivos heredados',
        type: PropertyType.Uri(Festivo),
        readWrite: ReadWrite.ReadOnly,
        array: true
    })
    private _festivosHeredados: object = [];

    @BasicProperty({
        name: 'Nombres país',
        type: PropertyType.Translation(),
        nullable: false,
        readWrite: ReadWrite.ReadOnly
    })
    private _nombresPais: boolean;

    @BasicProperty({
        name: 'Nombre del país',
        type: PropertyType.String(),
        readWrite: ReadWrite.ReadOnly,
        dontReadFromApi: true
    })
    public get nombrePaisDefault(): string {
        if (!this._nombresPais) {
            return '';
        }
        const nombre = this._nombresPais['es'];
        if (!nombre) {
            const names = Object.values(this._nombresPais);
            return names.length > 0 ? names[1] : 'Desconocido';
        }
        return nombre;
    }

    public get resumen(): string {
        return `${this.nombre} (${this.provincia.nombre})`;
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get nombre() {
        return this._nombre;
    }

    set nombre(value) {
        this._nombre = value;
    }

    get alias() {
        return this._alias;
    }

    set alias(value) {
        this._alias = value;
    }

    get provincia() {
        return this._provincia;
    }

    set provincia(value) {
        this._provincia = value;
    }

    get zonaPropia() {
        return this._zonaPropia;
    }

    set zonaPropia(value) {
        this._zonaPropia = value;
    }

    get zona() {
        return this._zona;
    }

    set zona(value) {
        this._zona = value;
    }

    get festivosPropios(): object {
        return this._festivosPropios;
    }

    set festivosPropios(value: object) {
        this._festivosPropios = value;
    }

    get festivosHeredados(): object {
        return this._festivosHeredados;
    }

    set festivosHeredados(value: object) {
        this._festivosHeredados = value;
    }

    get nombresPais() {
        return this._nombresPais;
    }

    set nombresPais(value) {
        this._nombresPais = value;
    }
}
