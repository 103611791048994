import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {ApiService} from "../../../../api/api.service";

@Component({
    selector: "be-photo-preview",
    templateUrl: "./photo-preview.component.html",
    styleUrls: ["./photo-preview.component.scss"]
})
export class PhotoPreviewComponent {
    @Input() photo: string;
    @Input() onlyIcon: boolean = false;
    @ViewChild("overlay") private _overlayRef: ElementRef;

    public get photoUrl(): string {
        return ApiService.sCompleteUrl(this.photo);
    }
}
