import {Component, Input, OnDestroy, OnInit, Type} from '@angular/core';
import {ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {InternalPropertyMap} from "../../../basic-entity-back/basic-entity-interface/mapping-internal";
import {Subscription} from "rxjs";
import {FilterAndData} from "../../../api/filter-list";
import {BetweenRangeFilter, GreaterThanOrEqRangeFilter, GreaterThanRangeFilter, LowerThanOrEqRangeFilter, LowerThanRangeFilter} from "../../../basic-entity-back/filters/range-filters";
import {FilterInterface} from "../../../api/filter-interface";

@Component({
    selector: 'be-range-filtering',
    templateUrl: './range-filtering.component.html',
    styleUrls: ['./range-filtering.component.scss'],
    providers: [{provide: NG_VALUE_ACCESSOR, useExisting: RangeFilteringComponent, multi: true}]
})
export class RangeFilteringComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() property: InternalPropertyMap;
    @Input() formControl: UntypedFormControl;

    public formGroup;
    public static readonly sManagedFilters: { [s: string]: Type<FilterInterface> } = {
        '<': LowerThanRangeFilter,
        '<=': LowerThanOrEqRangeFilter,
        '>': GreaterThanRangeFilter,
        '>=': GreaterThanOrEqRangeFilter,
        '..': BetweenRangeFilter
    };

    private _subscription: Subscription;
    private _onChange = _ => {
    };
    private _onTouch = () => {
    };

    constructor(fb: UntypedFormBuilder) {
        this.formGroup = fb.group({
            comp: fb.control('<'),
            main: fb.control(0),
            other: fb.control(0)
        });
    }

    ngOnInit() {
        this._subscription = this.formGroup.valueChanges.subscribe(v => {
            if (this.formGroup.valid) {
                const filter = RangeFilteringComponent.sManagedFilters[v.comp];
                let data = v.comp === '..' ? [v.main || 0, v.other || 0] : v.main || 0;
                this._onChange(new FilterAndData(filter, data, this.property));
            }
        });
    }

    ngOnDestroy(): void {
        this._subscription && this._subscription.unsubscribe();
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.formGroup.setDisabledState(isDisabled);
    }

    writeValue(fad: any): void {
        if (fad instanceof FilterAndData
            && fad.property.modelKey === this.property.modelKey
            && Object.values(RangeFilteringComponent.sManagedFilters).includes(fad.filter)) {
            const obj = fad.data;
            if (obj != null) {
                if (Array.isArray(obj) && obj.length == 2) {
                    this.formGroup.setValue({comp: '..', main: obj[0], other: obj[1]});
                } else {
                    this.formGroup.setValue({comp: this._compForFilter(fad.filter), main: obj, other: 0});
                }
            } else {
                this.formGroup.setValue({comp: '>=', main: 0, other: 0});
            }
        } else {
            console.error('Invalid value ', fad);
        }
    }

    private _compForFilter(filter: Type<FilterInterface>): string {
        const pair = Object.entries(RangeFilteringComponent.sManagedFilters).find(([k, v]) => v === filter);
        return pair ? pair[0] : null;
    }
}
