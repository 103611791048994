import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {Resource} from "../../api/resource";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {Moment} from "moment";
import {ReadWrite} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {FORMATS} from "../../basic-entity-front/const";

@BasicEntity({
    name: 'Festivos',
    endpoint: 'festivos',
    isPaginated: true,
    acceptsBulkIriSearch: true,
    paginationSizes: [20, 30, 50],
    modelNameProperty: 'nombreFecha',
    type: 'Festivo'
})
export class Festivo extends Resource {
    @BasicProperty({
        isId: true,
        name: 'ID',
        type: PropertyType.Integer()
    })
    private _idFestivo: number;

    @BasicProperty({
        name: 'Nombre',
        type: PropertyType.String()
    })
    private _nombreFestivo: string;

    @BasicProperty({
        name: 'Fecha',
        type: PropertyType.Date(),
        sortable: true
    })
    private _fechaFestivo: Moment;

    @BasicProperty({
        type: PropertyType.String(),
        readWrite: ReadWrite.ReadOnly,
        dontReadFromApi: true
    })
    get nombreFecha(): string {
        return `${this._nombreFestivo} (${this._fechaFestivo.format(FORMATS.date)})`;
    }

    get idFestivo(): number {
        return this._idFestivo;
    }

    set idFestivo(value: number) {
        this._idFestivo = value;
    }

    get nombreFestivo(): string {
        return this._nombreFestivo;
    }

    set nombreFestivo(value: string) {
        this._nombreFestivo = value;
    }

    get fechaFestivo(): Moment {
        return this._fechaFestivo;
    }

    set fechaFestivo(value: Moment) {
        this._fechaFestivo = value;
    }
}
