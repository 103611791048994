<be-dialog-shell (close)="closeDialog(saved)" [title]="title">
    <form class="top-section" autocomplete="off" (submit)="$event.preventDefault();"
          [formGroup]="formGroupInfo">
        <div class="field" *ngFor="let prop of this.editableProperties" [attr.id]="prop.modelKey">
            <label>{{prop.name}}: </label>
            <be-input [formGroup]="formGroupInfo" [controlName]="prop.modelKey" [property]="prop"></be-input>
        </div>
    </form>
    <be-edition-tab *ngIf="arrayCols.length > 0"
                    [propertiesToShow]="arrayCols" [formGroup]="formGroupInfo"
                    [formManager]="formManager">
    </be-edition-tab>
    <div class="top-save" *ngIf="modified">
        <button mat-flat-button color="primary" (click)="guardar()" [disabled]="formGroupInfo.invalid">
            <mat-icon>save</mat-icon>
            Guardar
        </button>
    </div>
    <mat-divider></mat-divider>

    <form class="add-section" autocomplete="off" (submit)="$event.preventDefault();"
          [formGroup]="formGroupAddElement" *ngIf="!entity.isTemporalEntity">
        <mat-form-field id="pick-model" appearance="outline"
                        [style.display]="listSections.length > 1 ? 'block' : 'none'">
            <mat-select formControlName="picker" #picker>
                <mat-option *ngFor="let option of listSections" [value]="option">
                    {{option.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <be-nested-input class="model-input" *ngFor="let option of listSections"
                         [formControlName]="option.name"
                         [hint]="addHint"
                         [style.display]="formGroupAddElement.value.picker === option ? 'block' : 'none'"
                         [nestedModelType]="fakeNestedModelType(option)"
                         placeholder="Buscar {{option.name.toLowerCase()}}..."
                         [required]="false">
        </be-nested-input>

        <button mat-flat-button color="primary"
                (click)="addCurrentElement();"
                [disabled]="cantAdd">
            <mat-icon>add</mat-icon>
            Añadir
        </button>
    </form>
    <mat-progress-bar mode="indeterminate" color="primary"
                      [style.visibility]="(loading$ | async) && !entity.isTemporalEntity ? 'visible' : 'hidden'"></mat-progress-bar>
    <app-multi-model-list class="list-section"
                          [sections]="listSections"
                          (deleteElement)="deleteElement($event.element, $event.section)"></app-multi-model-list>
    <dialog-actions>
        <div *ngIf="info" class="info mat-body">
            <mat-icon>info</mat-icon>
            <span>{{info}}</span></div>
        <button mat-button (click)="closeDialog(saved)">Cerrar</button>
    </dialog-actions>
</be-dialog-shell>
