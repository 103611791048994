import {Resource} from "../../api/resource";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {ManagerCachingStrategy} from "../../basic-entity-back/basic-entity-interface/mapping-external";

@BasicEntity({
    name: "Metrica",
    endpoint: "metricas",
    isPaginated: false,
    modelNamePattern: "M{{metrica}}",
    managerCaching: ManagerCachingStrategy.Lazy
})
export class Metrica extends Resource {

    @BasicProperty({
        name: "ID",
        isId: true,
        type: PropertyType.Integer()
    })
    private _id: number;

    @BasicProperty({
        name: "Metrica",
        type: PropertyType.String()
    })
    private _metrica: string;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get metrica(): string {
        return this._metrica;
    }

    set metrica(value: string) {
        this._metrica = value;
    }
}
