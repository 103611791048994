export module LoginInterface {
    export const LOGIN_ENDPOINT = '/login_check';
}
export interface LoginRequestInterface {
    username: string;
    password: string;
}
export interface LoginAnswerInterface {
    token: string;
    idCliente: number;
}
