import {Caster} from './caster';
import {Uri} from "../../api/uri";
import {Resource} from "../../api/resource";
import {BasicEntityInterface} from "../basic-entity-interface/basic-entity-interface";

/**
 * Casts a Uri to string and from string/object
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */
export class UriCaster implements Caster<Uri, string> {
    /***
     * @param casterFor - for debug purposes
     * @param acceptedModelsInterfaces - accepted interfaces by the caster
     * @param onlyId - If this is true, the number of accepted models needs to be strictly one for toModel to work.
     */
    constructor(public casterFor: string,
                public acceptedModelsInterfaces: BasicEntityInterface<Resource>[],
                public onlyId = false) {
    }

    fromModel(modelValue: Uri | null): string | null {
        if ( !(modelValue instanceof Uri)) {
            return null;
        }
        return this.onlyId ? modelValue.id.join(';') : modelValue.toString();
    }

    toModel(apiValue: string | null): Uri | null {
        if (apiValue === null) {
            return null;
        }
        if (this.onlyId) {
            if (this.acceptedModelsInterfaces.length !== 1) {
                throw new Error(
                    'UriCaster::toModel can\'t be used when more than one interfaces available. Current interfaces: ' +
                    this.acceptedModelsInterfaces.map(interf => interf.endpoint).join('; '));
            }
            return new Uri(
                apiValue.split(';').map(v => v.trim()),
                this.acceptedModelsInterfaces[0].uriPattern,
                this.acceptedModelsInterfaces[0].serialiser.model);
        } else {
            if (typeof apiValue === 'object') {
                for (const interf of this.acceptedModelsInterfaces) {
                    if (interf.serialiser.entityType === apiValue['@type']) {
                        return Uri.fromUriStr(apiValue['@id'], interf.uriPattern, interf.serialiser.model);
                    }
                }
                throw new Error(`UriCaster for ${this.casterFor}: Invalid uri object with @type ${apiValue['@type']}.\n` +
                    `Accepted entity types: ${this.acceptedModelsInterfaces.map(i => `${i.serialiser.entityType} (${i.name})`).join(', ')}`);
            } else {
                for (const interf of this.acceptedModelsInterfaces) {
                    if (Uri.uriMatchsPattern(apiValue, interf.uriPattern)) {
                        return Uri.fromUriStr(apiValue, interf.uriPattern, interf.serialiser.model);
                    }
                }
                throw new Error(`UriCaster for ${this.casterFor}: Invalid uri ${apiValue}.\n` +
                    `Accepted patterns: ${this.acceptedModelsInterfaces.map(i => `${i.uriPattern} (${i.name})`).join(', ')}`);
            }
        }
    }
}
