import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DatosPersonalesComponent} from "../../session/datosPersonales/datosPersonales.component";

@Component({
    selector: 'app-dialogsuccess',
    templateUrl: './dialogSuccess.component.html',
    styleUrls: ['./dialogSuccess.component.scss']
})
export class DialogSuccessComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<DialogSuccessComponent>,
        @ Inject(MAT_DIALOG_DATA) public data: String) {}

    ngOnInit() {
        console.log(this)
    }

    cancelar() {
        this.dialogRef.close();
    }

}
