import {Pais} from './model/localizacion/pais.model';
import {PaisComponent} from './pages/localizacion/pais/pais.component';
import {SessionGuard} from './session/session.guard';
import {Comunidad} from './model/localizacion/comunidad.model';
import {ComunidadComponent} from './pages/localizacion/comunidad/comunidad.component';
import {ZonaComponent} from './pages/localizacion/zona/zona.component';
import {Zona} from './model/localizacion/zona.model';
import {Provincia} from './model/localizacion/provincia.model';
import {ProvinciaComponent} from './pages/localizacion/provincia/provincia.component';
import {MunicipioComponent} from './pages/localizacion/municipio/municipio.component';
import {Municipio} from './model/localizacion/municipio.model';
import {IdiomaComponent} from './pages/localizacion/idioma/idioma.component';
import {Idioma} from './model/localizacion/idioma.model';
import {FestivoComponent} from './pages/localizacion/festivo/festivo.component';
import {Festivo} from './model/localizacion/festivo.model';
import {RegistrosEmpleadosComponent} from './pages/personas/registros-empleados/registros-empleados.component';
import {RegistroEmpleado} from './model/personas/registro-empleado.model';

const LOCALIZACIONES = [
    {
        path: 'idiomas',
        component: IdiomaComponent,
        model: Idioma,
        canActivate: [SessionGuard],
        data: {title: 'Idioma'}
    },
    {
        path: 'paises',
        component: PaisComponent,
        model: Pais,
        canActivate: [SessionGuard],
        data: {title: 'Países'}
    },
    {
        path: 'comunidades',
        component: ComunidadComponent,
        model: Comunidad,
        canActivate: [SessionGuard],
        data: {title: 'Comunidades'}
    },
    {
        path: 'provincias',
        component: ProvinciaComponent,
        model: Provincia,
        canActivate: [SessionGuard],
        data: {title: 'Provincias'}
    },
    {
        path: 'municipios',
        component: MunicipioComponent,
        model: Municipio,
        canActivate: [SessionGuard],
        data: {title: 'Municipios'}
    },

    {
        path: 'zonas',
        component: ZonaComponent,
        model: Zona,
        canActivate: [SessionGuard],
        data: {title: 'Zonas'}
    },
    {
        path: 'festivos',
        component: FestivoComponent,
        model: Festivo,
        canActivate: [SessionGuard],
        data: {title: 'Festivos'}
    },
];

const PERSONAS = [
    {
        path: 'registros-empleados',
        component: RegistrosEmpleadosComponent,
        model: RegistroEmpleado,
        canActivate: [SessionGuard],
        data: {title: 'Registros'}
    },
];

export const TABLES = [
    ...LOCALIZACIONES,
    ...PERSONAS
];
