import {Injectable, Type} from '@angular/core';
import {BasicEntityInterface} from "../basic-entity-interface/basic-entity-interface";
import {Resource} from "../../api/resource";
import {ApiService} from "../../api/api.service";
import {Paginated} from "../../api/collection-answer";
import {FilterAndData, FilterList} from "../../api/filter-list";
import {BehaviorSubject, Observable} from "rxjs";
import {KeyProviderService} from "./key-provider.service";
import {InterfaceProviderService} from "./interface-provider.service";
import {ExactSearchFilter, MultipleSearchFilter} from "../filters/search-filter";
import {Filter} from "../../api/filter-interface";
import {EntityManager} from "../entity-manager/entity-manager";

@Injectable({
    providedIn: 'root'
})
export class TranslationInputService implements KeyProviderService {
    private _languageInterface: BasicEntityInterface<Resource>;
    private _entityManager: EntityManager<Resource>;
    private _filterToUse: Filter | null;
    private _languageMap: Map<string, string> = new Map();
    private _changes: BehaviorSubject<void> = new BehaviorSubject<void>(null);

    public get languageInterface(): BasicEntityInterface<Resource> {
        return this._languageInterface;
    }

    public get entityManager(): EntityManager<Resource> {
        return this._entityManager;
    }

    public get keys(): Map<string, string> {
        return this._languageMap;
    }
    public get keyChanges(): Observable<void> {
        return this._changes;
    }

    constructor(private _apiService: ApiService, private _interfaceService: InterfaceProviderService) {}

    public initialise(languageModel: Type<Resource>) {
        if (!(this._languageInterface && this._entityManager)) {
            const manager = this._interfaceService.managerForModel(languageModel);
            const interf = manager.loader.entityInterface;
            if (interf.idProperties.length > 1) {
                throw new Error('The language model provided has a compound id, language models should have ONLY the language ISO code as ID.');
            }

            const mapping = interf.mappingModelToApi[interf.idProperties[0]];

            this._filterToUse = mapping.filters.find(filter =>
                ([MultipleSearchFilter, ExactSearchFilter] as Filter[]).includes(filter)) || null;

            if (!this._filterToUse) {
                // We prefer to have a filter, to be able to request several values
                console.warn('The language model provided cannot be filtered by the id, we recommend to set up an "exact" search type for this property.');
            }

            this._languageInterface = interf;
            this._entityManager = manager;
        }
    }

    /**
     * Gets from the cache or from the network the name for the requested language
     * @param languageCode
     */
    public get(languageCode: string): Promise<string> {
        return new Promise( (resolve, reject) => {
            if (this._languageMap.has(languageCode)) {
                resolve(this._languageMap.get(languageCode));
            } else {
                this.entityManager
                    .getById(languageCode)
                    .then(model => {
                        this._addToCache([model]);
                        resolve(this._languageMap.get(languageCode));
                    }, error => reject(error));
            }
        });
    }

    public preload(values: string[]|null = null) {
        this._requireInitialisation();
        if (values === null) {
            if (this._languageMap.size === 0) {
                this.reload();
            }
        } else {
            const nonExistent = values.filter(v => !this._languageMap.has(v));
            if (nonExistent.length > 0) {
                this.reload(nonExistent);
            }
        }
    }

    public reload(values: string[]|null = null) {
        this._requireInitialisation();

        if (values === null) {
            this._entityManager.loader
                .find()
                .subscribe( paginated => this._langsReceived(paginated, undefined, 0));
        } else if (this._filterToUse) {
            const filters: FilterList = [
                new FilterAndData(this._filterToUse, this._languageInterface.idProperties[0])
            ];
            this._entityManager.loader
                .find(filters)
                .subscribe(paginated => this._langsReceived(paginated, filters, 0));
        } else {
            for (const value of values) {
                this._entityManager.loader
                    .findById(value)
                    .subscribe(model => this._addToCache([model]));
            }
        }
    }

    private _langsReceived(paginated: Paginated<Resource>, originalFilters: FilterList, originalPage: number) {
        this._addToCache(paginated.member);

        if (!paginated.isLastPage()) {
            if (originalPage > 50) {
                throw new Error('Requested already more than 50 pages of languages and not the last yet. Aborting.');
            }
            this._entityManager.loader
                .find(originalFilters, undefined, undefined, originalPage + 1)
                .subscribe(newPaginated => this._langsReceived(newPaginated, originalFilters, originalPage + 1));
        }
    }

    private _addToCache(models: Resource[]) {
        for (const model of models) {
            this._languageMap.set(this._getValue(model), this._getText(model));
        }
        this._changes.next(null);
    }

    private _getValue(lang: Resource) {
        return lang[this._languageInterface.idProperties[0]];
    }

    private _getText(lang: Resource) {
        return this._languageInterface.getName(lang);
    }

    private _requireInitialisation() {
        if (!(this._languageInterface && this._entityManager)) {
            throw new Error('Translation input service has not been initialised. Please initialise the service calling to TranslationInputService::initialise.');
        }
    }
}
