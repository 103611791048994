import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {WidgetGrid} from "./widget-grid";
import {WidgetType} from "./widget";
import { MatSelect } from "@angular/material/select";
import {AVAILABLE_WIDGETS} from "./available-widgets";
import {SessionService} from "../../session/session.service";
import { Router } from '@angular/router';
import {Cliente} from "../../model/personas/cliente.model";
import * as moment from "moment";

export interface WidgetPlaceholder {
    row: number;
    col: number;
}

@Component({
    selector: "app-inicio",
    templateUrl: "./inicio.component.html",
    styleUrls: ["./inicio.component.scss"]
})
export class InicioComponent implements OnInit, OnDestroy {
    public readonly grid = new WidgetGrid(6, 20, this._session);
    public readonly placeholders: WidgetPlaceholder[] = [];
    public readonly availableWidgets: WidgetType[] = AVAILABLE_WIDGETS;
    public editionMode = false;
    public movingIndex = null;

    @ViewChild("addWidgetSelect")
    private _addWidgetSelect: MatSelect;

    constructor(private _session: SessionService, private router: Router) {}

    ngOnInit() {
        this._session.profileAsObservable.subscribe(result => {
            if (result instanceof Cliente) {
                if(result.usuario.passwordValidUntil && result.usuario.passwordValidUntil > moment.now()) {
                    this.router.navigateByUrl('/password-reset');
                }
            }
        })

    }

    ngOnDestroy(): void {}

    deleteWidget(idx: number): void {
        this.grid.remove(idx);
    }

    addWidget(widget: WidgetType) {
        if (widget) {
            this._addWidgetSelect.value = null;
            for (let i = 1; i <= this.grid.rows; i++) {
                for (let j = 1; j <= this.grid.columns; j++) {
                    if (this.grid.canAdd(widget, i, j)) {
                        this.grid.add(widget, i, j);
                        return;
                    }
                }
            }
        }
    }

    generatePlaceholders(widgetIndex: number) {
        this.clearPlaceholders();
        this.movingIndex = widgetIndex;
        const widget = this.grid.widgets[widgetIndex];
        for (let i = 1; i <= this.grid.rows; i++) {
            for (let j = 1; j <= this.grid.columns; j++) {
                if (
                    (i !== widget.row || j !== widget.column) &&
                    this.grid.canMove(widgetIndex, i, j)
                ) {
                    this.placeholders.push({
                        row: i,
                        col: j
                    });
                }
            }
        }
    }

    clearPlaceholders() {
        this.placeholders.splice(0, this.placeholders.length);
        this.movingIndex = null;
    }

    dragStart(event: DragEvent, idx: number) {
        this.generatePlaceholders(idx);
        event.dataTransfer.setData("text", idx.toString());
    }

    dragEnter(event: DragEvent, idx) {
        this.grid.move(
            this.movingIndex,
            this.placeholders[idx].row,
            this.placeholders[idx].col
        );
    }

    dragExit(event: Event, idx) {}

    dragOver(event: DragEvent) {
        event.preventDefault();
    }

    dragEnd(event: DragEvent) {
        this.clearPlaceholders();
    }

    drop(event: DragEvent) {
        event.preventDefault();
    }
}
