import {Component} from '@angular/core';
import {BasicEntityTableColumnDescriptionOrString} from "../../../basic-entity-front/basic-entity-table/basic-entity-table.component";
import {Festivo} from "../../../model/localizacion/festivo.model";
import {FestivoEditionComponent} from "../../edition-list-dialog/festivo-edition.component";

@Component({
    selector: 'app-festivo',
    template: '<be-table [initialSorting]="initialSorting" [columnsToDisplay]="columns" [modelType]="model" [editionDialog]="dialog"></be-table>'
})
export class FestivoComponent {
    public model = Festivo;
    public columns: BasicEntityTableColumnDescriptionOrString[] = [
        'idFestivo',
        {key: 'nombreFestivo', weight: 2},
        {key: 'fechaFestivo', weight: 2}
    ];
    public initialSorting = {active: 'fechaFestivo', direction: 'asc'};
    public dialog = FestivoEditionComponent;
}
