<div class="date-time-input" [formGroup]="internalGroup">
    <mat-form-field>
        <input matInput [formControlName]="'date'"
               [matDatepicker]="picker"
               [matDatepickerFilter]="datePickerFilter"
               [required]="required"
               [placeholder]="placeholder">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <!--    <mat-form-field>-->
    <!--        <be-time-input [formControlName]="'time'"-->
    <!--                       [required]="required"></be-time-input>-->
    <!--    </mat-form-field>-->
</div>
