import {Component, Directive, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import { flip } from '../animations/animations';

@Directive({
    selector: 'dialog-actions'
})
export class DialogActionsDirective {
}

@Component({
    selector: 'be-dialog-shell',
    templateUrl: './dialog-shell.component.html',
    styleUrls: ['./dialog-shell.component.scss'],
    animations: [flip]
})
export class DialogShellComponent {
    @Input() public title = '';
    @Input() public closeButton = true;
    @Input() public showFooter = true;
    @Input() public revisado = true;
    @Output() public close = new EventEmitter<void>();
    @HostBinding('class.app-dialog-shell') appDialogShellClass = true;
}
