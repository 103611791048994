<mat-list class="list-section">
    <ng-container *ngFor="let section of sections">
        <h3 matSubheader *ngIf="section.list.length > 0">{{section.name}}</h3>
        <mat-list-item *ngFor="let entity of section.list;let i = index">
            <span class="model-name">{{getName(section, entity)}}</span>
            <button mat-icon-button
                    *ngIf="showDeletionButtons"
                    (click)="deleteElement.emit({index: i, element: entity, section: section})">
                <mat-icon>delete_forever</mat-icon>
            </button>
        </mat-list-item>
        <mat-divider *ngIf="section.list.length > 0"></mat-divider>
    </ng-container>
</mat-list>
