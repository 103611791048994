/**
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */
import {BEIDetailsDescription, ObjectPropertyMap, PropertyMap} from './basic-entity-interface/mapping-external';
import 'reflect-metadata';
import {Type} from "@angular/core";
import {Resource} from "../api/resource";

export const objectMapMetadataKey = Symbol('object-map');
export const basicEntityMapMetadataKey = Symbol('map');
export const basicEntityDetailsMetadataKey = Symbol('details');

const BASIC_ENTITY_MODEL_LIST_INTERNAL: Type<Resource>[] = [];
export const glob_GetBasicEntityModelList: () => ReadonlyArray<Type<Resource>> =
    () => Object.freeze<Type<Resource>>(BASIC_ENTITY_MODEL_LIST_INTERNAL.slice(0));

function propertyMetadataFunctionFactory(map: any, symbol: Symbol): (target: any, propertyKey: string) => void {
    return function(target: any, propertyKey: string)  {
        if (propertyKey.startsWith('_')) {
            propertyKey = propertyKey.substr(1);
        }
        let metadata = Reflect.getOwnMetadata(symbol, target.constructor);
        if (metadata) {
            metadata[propertyKey] = map;
        } else {
            metadata = {[propertyKey]: map};
        }
        Reflect.defineMetadata(symbol, metadata, target.constructor);
    };
}

/**
 * Decorator which allows us to define the mappings of the properties directly
 * over the properties themselves.
 */
export function BasicProperty(map: PropertyMap) {
    return propertyMetadataFunctionFactory(map, basicEntityMapMetadataKey);
}

/**
 * Decorator which allows us to define the mappings of the properties
 * over objects, which can be used as models for the properties with Object type.
 */
export function ObjectProperty(map: ObjectPropertyMap) {
    return propertyMetadataFunctionFactory(map, objectMapMetadataKey);
}

/**
 * Decorator which allows us to define the entities on the classes themselves
 */
export function BasicEntity(details: BEIDetailsDescription) {
    return function(constructorFunction: Function) {
        if (!details.type) {
            // We set the type so it is now BasicEntityInterfaceDetails for real
            details.type = constructorFunction.name;
        }
        Reflect.defineMetadata(basicEntityDetailsMetadataKey, details, constructorFunction);
        BASIC_ENTITY_MODEL_LIST_INTERNAL.push(constructorFunction as Type<Resource>);
    };
}
