import {Component} from '@angular/core';
import {BasicEntityTableColumnDescription, BasicEntityTableColumnDescriptionOrString} from "../../../basic-entity-front/basic-entity-table/basic-entity-table.component";
import {RegistroEmpleado} from "../../../model/personas/registro-empleado.model";
import {SessionService} from "../../../session/session.service";
import {ApiInterface} from "../../../api/api-interface";

@Component({
    selector: 'app-registros-empleados',
    templateUrl: './registros-empleados.component.html',
    styleUrls: ['./registros-empleados.component.scss']
})
export class RegistrosEmpleadosComponent {
    public model = RegistroEmpleado;
    public canEdit = false;
    public canRemove = false;
    public columns: BasicEntityTableColumnDescriptionOrString[] = [
        {key: 'id', hiddenDisplayable: true, hiddenInEdition: true},
        {key: 'timestamp', weight: 2, hiddenInEdition: true},
        {key: 'accion', weight: 2},
        {key: 'empleado', weight: 6, hiddenInEdition: true}
    ];

    public column(key: string): BasicEntityTableColumnDescription | undefined {
        for (const column of this.columns) {
            if (typeof (column) === "object" && column.key === key) {
                return column;
            }
        }
        return undefined;
    }

    constructor(sessionService: SessionService) {
        if (sessionService.isLogged() && sessionService.hasRole(ApiInterface.ROLES.ADMIN)) {
            this.column('timestamp').hiddenInEdition = false;
            this.column('empleado').hiddenInEdition = false;
            this.canEdit = true;
            this.canRemove = true;
        }
    }
}
