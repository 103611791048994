<button mat-icon-button [matMenuTriggerFor]="propsMenu" matTooltip="Añadir filtro"
        *ngIf="filtrableProperties.length > 0">
    <mat-icon fontSet="material-icons-outlined" [color]="withFilters ? 'warn' : ''">filter_alt</mat-icon>
</button>
<div class="menu">
    <mat-menu #propsMenu="matMenu">
        <be-filter-list #filterList (click)="$event.stopPropagation()"></be-filter-list>
        <div class="botonera">
            <button mat-flat-button color="warn"
                    (click)="clearFilters()">Resetear
            </button>
            <button mat-flat-button color="primary"
                    (click)="applyFilters()">Aplicar
            </button>
        </div>
    </mat-menu>
</div>
