<div *ngIf="me" class="container">
    <mat-card class="cards_container">
        <div class="datos_empresa fifty">
            <div class="cabecera">
                <mat-icon>business</mat-icon>
                <mat-card-title>Datos {{ me.empresa ? "Empresa" : "Personales" }}</mat-card-title>
            </div>
            <mat-divider></mat-divider>
            <mat-card-content>
                <div class="contenido_card">
                    <form [formGroup]="formGroup">
                        <div class="contenido_card">
                            <div>
                                <mat-radio-group class="radio-group" (change)="cambioPersona($event)"
                                                 [formControl]="control('empresa')">
                                    <mat-radio-button [value]="false" [checked]="!me.empresa">Persona Física
                                    </mat-radio-button>
                                    <mat-radio-button [value]="true" [checked]="me.empresa">Empresa (Persona Jurídica)
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div id="nombre">
                                <mat-form-field>
                                    <mat-label>Nombre de usuario</mat-label>
                                    <input [value]="usuario.username" disabled=true matInput>
                                </mat-form-field>
                            </div>
                            <ng-container *ngIf="!me.empresa; then fisica; else juridica"></ng-container>
                            <ng-template #fisica>
                                <div class="secondLine">
                                    <mat-form-field>
                                        <mat-label>Nombre</mat-label>
                                        <input [formControl]="control('nombre')" matInput>
                                    </mat-form-field>
                                    <mat-form-field>
                                        <mat-label>Apellido 1</mat-label>
                                        <input [formControl]="control('apellido1')" matInput>
                                    </mat-form-field>
                                    <mat-form-field>
                                        <mat-label>Apellido 2</mat-label>
                                        <input [formControl]="control('apellido2')" matInput>
                                    </mat-form-field>
                                    <mat-form-field>
                                        <mat-label>NIF</mat-label>
                                        <input [formControl]="control('cifNif')" matInput>
                                    </mat-form-field>
                                </div>
                            </ng-template>
                            <ng-template #juridica>
                                <div class="secondLine">
                                    <mat-form-field>
                                        <mat-label>Denominación social</mat-label>
                                        <input [formControl]="control('denominacionSocial')" matInput>
                                    </mat-form-field>
                                    <mat-form-field>
                                        <mat-label>CIF</mat-label>
                                        <input [formControl]="control('cifNif')" matInput>
                                    </mat-form-field>
                                </div>
                            </ng-template>
                            <mat-form-field>
                                <mat-label>Email</mat-label>
                                <input [formControl]="control('email')" matInput>
                            </mat-form-field>

                        </div>
                    </form>

                    <button (click)="guardarDatos()" [disabled]="!(formGroup.dirty && dataChanged && formGroup.valid)"
                            color="primary" mat-raised-button>
                        Guardar
                    </button>
                </div>
            </mat-card-content>
        </div>

        <div class="direcciones fifty">
            <div class="cabecera">
                <mat-icon>home</mat-icon>
                <mat-card-title>Direcciones</mat-card-title>
            </div>
            <mat-divider></mat-divider>
            <mat-card-content>
                <div class="contenido_card">
                    <div *ngIf="me.direcciones.length > 0; else no_hay_direcciones">
                        <table>
                            <tr>
                                <th style="width: 40%">Tipo</th>
                                <th style="width: 40%">Dirección</th>
                                <th style="width: 25%">Población</th>
                                <th style="width: 10%">CP</th>
                                <th style="width: 25%">Provincia</th>
                                <th style="width: 25%">País</th>
                                <th style="min-width: 65px"></th>
                            </tr>
                            <tr *ngFor="let direccion of me.direcciones; let i = index">
                                <td>{{ direccion.tipoDireccion.charAt(0).toUpperCase() + direccion.tipoDireccion.slice(1) }}</td>
                                <td>{{ direccion.calle }}</td>
                                <td>{{ direccion.poblacion }}</td>
                                <td>{{ direccion.cp }}</td>
                                <td>{{ nombreProvincia(direccion.demarcacion?.toString()) }}</td>
                                <td>{{ nombrePais(direccion.pais.toString()) }}</td>
                                <td>
                                    <div>
                                        <button (click)="editarDireccion(direccion, i)" mat-button>
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                        <button (click)="borrarDireccion(direccion, i)" mat-button>
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="botones_direccion">
                        <button (click)="addAddress = true; tempAddressForm.reset(); tempAddressId = -1; control('tipoDireccion', tempAddressForm).setValue(DireccionPersona.TIPOS_DIRECCION.FACTURACION)"
                                *ngIf="!addAddress && mostrarDireccionFacturacion"
                                color="primary"
                                mat-raised-button>Añadir dirección de facturación
                        </button>
                        <button (click)="addAddress = true; tempAddressForm.reset(); tempAddressId = -1; control('tipoDireccion', tempAddressForm).setValue(DireccionPersona.TIPOS_DIRECCION.ENVIO)"
                                *ngIf="!addAddress && mostrarDireccionEnvio"
                                color="primary"
                                mat-raised-button>Añadir dirección de envío
                        </button>
                    </div>
                    <div *ngIf="addAddress">
                        <form [formGroup]="tempAddressForm">
                            <div class="newForm">
                                <mat-form-field style="width: 100px">
                                    <mat-label>Tipo de dirección</mat-label>
                                    <mat-select [formControl]="control('tipoDireccion', tempAddressForm)" disabled>
                                        <mat-option [value]="dirEnvio">Envío</mat-option>
                                        <mat-option [value]="dirFact">Facturación</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field style="width: 80%">
                                    <mat-label>Calle</mat-label>
                                    <input [formControl]="control('calle', tempAddressForm)" matInput>
                                    <mat-error *ngIf="control('calle', tempAddressForm).hasError('invalid')">La
                                        dirección debe tener contenido.
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Código Postal</mat-label>
                                    <input [formControl]="control('cp', tempAddressForm)" matInput>
                                    <mat-error *ngIf="control('cp', tempAddressForm).hasError('invalid')">Código postal
                                        inválido.
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field style="width: 20%">
                                    <mat-label>Población</mat-label>
                                    <input [formControl]="control('poblacion', tempAddressForm)" matInput>
                                    <mat-error *ngIf="control('poblacion', tempAddressForm).hasError('invalid')">La
                                        población debe tener contenido.
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field style="width: 20%">
                                    <mat-label>Provincia</mat-label>
                                    <input [formControl]="control('demarcacion', tempAddressForm)"
                                           [matAutocomplete]="auto"
                                           matInput placeholder="Empiece a escribir la provincia..." type="text">
                                    <mat-autocomplete #auto autoActiveFirstOption [displayWith]="displayProperty">
                                        <mat-option *ngFor="let provincia of filteredProvincias | async"
                                                    [value]="provincia">{{ provincia.nombre }}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error *ngIf="control('demarcacion', tempAddressForm).hasError('invalid')">La
                                        provincia debe tener contenido.
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field style="width: 20%">
                                    <mat-label>Pais</mat-label>
                                    <input [formControl]="control('pais', tempAddressForm)" [matAutocomplete]="auto2"
                                           matInput placeholder="Empiece a escribir el país..." type="text">
                                    <mat-autocomplete #auto2 autoActiveFirstOption
                                                      [displayWith]="displayProperty">
                                        <mat-option *ngFor="let pais of filteredPaises | async"
                                                    [value]="pais">{{ pais.nombreDefault }}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error *ngIf="control('pais', tempAddressForm).hasError('invalid')">El país
                                        debe
                                        ser escogido de la lista.
                                    </mat-error>
                                </mat-form-field>
                                <mat-checkbox style="width: 100%"
                                              [formControl]="control('mismaDireccion', tempAddressForm)"
                                              *ngIf="me.direcciones.length === 0">Las dos
                                    direcciones
                                    (Envío/Facturación) son la misma
                                </mat-checkbox>
                            </div>
                        </form>
                        <div class="botones_edicion">
                            <button (click)="addAddress = false" color="warn" mat-raised-button>Cancelar</button>
                            <button (click)="guardarDireccion()"
                                    [disabled]="tempAddressForm.invalid"
                                    color="primary" mat-raised-button>Guardar
                            </button>
                        </div>
                    </div>
                    <ng-template #no_hay_direcciones>
                        No hay ninguna dirección de envío ni de facturación por el momento.
                    </ng-template>
                </div>
            </mat-card-content>
        </div>
        <div class="datos_contacto fifty">
            <div class="cabecera">
                <mat-icon>person</mat-icon>
                <mat-card-title>Contactos</mat-card-title>
            </div>
            <mat-divider></mat-divider>
            <mat-card-content>
                <div class="contenido_card">
                    <div *ngIf="me.contactos.length > 0; else no_hay_contactos">
                        <table>
                            <tr>
                                <th style="width: 8%">Cargo</th>
                                <th style="width: 22%">Nombre</th>
                                <th style="width: 40%">Apellidos</th>
                                <th style="width: 30%">Email</th>
                                <th style="min-width: 65px"></th>
                            </tr>
                            <tr *ngFor="let contacto of me.contactos; let i = index">
                                <td>{{ contacto.cargo }}</td>
                                <td>{{ contacto.nombre }}</td>
                                <td>{{ contacto.apellido1 }} {{ contacto.apellido2 }}</td>
                                <td>{{ contacto.email }}</td>
                                <td>
                                    <div>
                                        <button (click)="editarContacto(contacto, i)" mat-button>
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                        <button (click)="borrarContacto(contacto, i)" mat-button>
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <button (click)="addContact = true" *ngIf="!addContact" color="primary" mat-raised-button>Añadir
                        contacto
                    </button>
                    <div *ngIf="addContact">
                        <form [formGroup]="tempContactForm" class="newForm">
                            <mat-form-field style="width: 9%">
                                <mat-label>Cargo</mat-label>
                                <input [formControl]="control('cargo', tempContactForm)" matInput>
                            </mat-form-field>
                            <mat-form-field style="width: 18%">
                                <mat-label>Nombre</mat-label>
                                <input [formControl]="control('nombre', tempContactForm)" matInput>
                                <mat-error *ngIf="control('nombre', tempContactForm).hasError('required')">El nombre es
                                    un campo requerido.
                                </mat-error>
                                <mat-error *ngIf="control('nombre', tempContactForm).hasError('minLength')">Introduce al
                                    menos 3 caracteres.
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field style="width: 18%">
                                <mat-label>Apellido 1</mat-label>
                                <input [formControl]="control('apellido1', tempContactForm)" matInput>
                                <mat-error *ngIf="control('apellido1', tempContactForm).hasError('required')">El primer
                                    apellido es un campo requerido.
                                </mat-error>
                                <mat-error *ngIf="control('apellido1', tempContactForm).hasError('minLength')">Introduce
                                    al menos 3 caracteres.
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field style="width: 18%">
                                <mat-label>Apellido 2</mat-label>
                                <input [formControl]="control('apellido2', tempContactForm)" matInput>
                            </mat-form-field>
                            <mat-form-field style="width: 27%">
                                <mat-label>Email</mat-label>
                                <input [formControl]="control('email', tempContactForm)" matInput>
                                <mat-error *ngIf="control('email', tempContactForm).hasError('invalid')">El e-mail no
                                    puede ser el mismo que el principal.
                                </mat-error>
                                <mat-error *ngIf="control('email', tempContactForm).hasError('required')">El e-mail es
                                    un campo requerido.
                                </mat-error>
                                <mat-error *ngIf="control('email', tempContactForm).hasError('email')">No parece una
                                    dirección de e-mail.
                                </mat-error>
                            </mat-form-field>
                        </form>

                        <div class="botones_edicion">
                            <button (click)="addContact = false" color="warn" mat-raised-button>Cancelar</button>
                            <button (click)="guardarContacto()"
                                    [disabled]="tempContactForm.pristine || tempContactForm.invalid"
                                    color="primary" mat-raised-button>Guardar
                            </button>
                        </div>
                    </div>
                    <ng-template #no_hay_contactos>
                        No hay contactos por el momento
                    </ng-template>
                </div>
            </mat-card-content>

        </div>
        <div class="telefonos fifty">
            <div class="cabecera">
                <mat-icon>phone</mat-icon>
                <mat-card-title>Teléfonos</mat-card-title>
            </div>
            <mat-card-content>
                <div class="contenido_card">
                    <div *ngIf="me.telefonos.length > 0; else no_hay_telefonos">
                        <table>
                            <tr>
                                <th>Departamento</th>
                                <th>Código de país</th>
                                <th>Teléfono</th>
                                <th>Extensión</th>
                            </tr>
                            <tr *ngFor="let telefono of me.telefonos; let i = index">
                                <td>{{ telefono.departamento }}</td>
                                <td>{{ telefono.codigoArea }}</td>
                                <td>{{ telefono.telefono }}</td>
                                <td>{{ telefono.extension }}</td>
                                <td>
                                    <div class="botones_edicion">
                                        <button (click)="editarTelefono(telefono, i)" mat-button>
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                        <button (click)="borrarTelefono(telefono, i)" mat-button>
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <button (click)="addPhone = true" *ngIf="!addPhone" color="primary" mat-raised-button>Añadir
                        teléfono
                    </button>
                    <div *ngIf="addPhone">
                        <form [formGroup]="tempPhoneForm" class="newForm">
                            <mat-form-field>
                                <mat-label>Código de país</mat-label>
                                <input [formControl]="control('codigoArea', tempPhoneForm)" matInput>
                                <mat-error *ngIf="control('codigoArea', tempPhoneForm).hasError('maxlength')">No existen
                                    códigos de área con más de 3 cifras.
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Teléfono</mat-label>
                                <input [formControl]="control('telefono', tempPhoneForm)" matInput>
                                <mat-error *ngIf="control('telefono', tempPhoneForm).hasError('required')">Al menos el
                                    teléfono es necesario.
                                </mat-error>
                                <mat-error
                                        *ngIf="control('telefono', tempPhoneForm).hasError('minlength') || control('telefono', tempPhoneForm).hasError('maxlength')">
                                    El teléfono debe tener 9 cifras.
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Extensión</mat-label>
                                <input [formControl]="control('extension', tempPhoneForm)" matInput>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Departamento</mat-label>
                                <input [formControl]="control('departamento', tempPhoneForm)" matInput>
                            </mat-form-field>
                        </form>

                        <div class="botones_edicion">
                            <button (click)="addPhone = false" color="warn" mat-raised-button>Cancelar</button>
                            <button (click)="guardarTelefono()"
                                    [disabled]="tempPhoneForm.pristine || tempPhoneForm.invalid"
                                    color="primary" mat-raised-button>Guardar
                            </button>
                        </div>
                    </div>
                    <ng-template #no_hay_telefonos>
                        No hay teléfonos por el momento
                    </ng-template>
                </div>
            </mat-card-content>
        </div>
        <div class="footer">Última modificacion
            el {{ this.me.fechaModificacion ? this.me.fechaModificacion.format('D [de] MMMM [de] YYYY [a las] h:mm:ss a') : '' }}
        </div>

    </mat-card>
</div>
