import {
    Component,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Self,
    SimpleChanges
} from '@angular/core';
import {BasicEntityInterface} from "../../../basic-entity-back/basic-entity-interface/basic-entity-interface";
import {Resource} from "../../../api/resource";
import {InterfaceProviderService} from "../../../basic-entity-back/services/interface-provider.service";
import {ControlValueAccessor, UntypedFormControl, NgControl} from "@angular/forms";
import {MatFormFieldControl} from "@angular/material/form-field";
import {BehaviorSubject, Subject, Subscription} from "rxjs";
import {Uri} from "../../../api/uri";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {Material} from "../../../model/materiales/material.model";
import {FilterAndData} from "../../../api/filter-list";
import {ExactSearchFilter} from "../../../basic-entity-back/filters/search-filter";
import {map} from "rxjs/operators";
import {int} from "three/examples/jsm/nodes/shadernode/ShaderNodeBaseElements";

@Component({
    selector: 'be-cached-input',
    templateUrl: './cached-input.component.html',
    styleUrls: ['./cached-input.component.scss'],
    providers: [
        {provide: MatFormFieldControl, useExisting: CachedInputComponent}
    ]
})
export class CachedInputComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, MatFormFieldControl<Uri | Resource> {
    public static nextId = 0;
    public stateChanges = new Subject<void>();
    @HostBinding() public id = `be-key-value-input-${CachedInputComponent.nextId++}`;

    @Input() public filters = [];
    @Input() public selectedValue = null;

    @Input()
    get placeholder(): string {
        return this._placeholder;
    }

    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }

    private _placeholder: string;

    public get focused() {
        return this._focused;
    }

    private _focused = false;

    public get empty() {
        return !this.control.value;
    }

    @HostBinding('class.floating')
    public get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @Input()
    public get required() {
        return this._required;
    }

    public set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }

    private _required = false;

    @Input()
    get disabled() {
        return this.control.disabled;
    }

    set disabled(dis) {
        const disabled = coerceBooleanProperty(dis);
        this.setDisabledState(disabled);
    }

    public get errorState(): boolean {
        return !!this.ngControl.errors;
    }

    public readonly controlType = 'be-cached-input';
    @HostBinding('attr.aria-describedby') public describedBy = '';

    @Input()
    public get value(): Uri | null {
        return this.control.value;
    }

    public set value(uri: Uri | null) {
        this.writeValue(uri);
    }

    setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }

    onContainerClick(event: MouseEvent): void {
    }

    compareFn(p1, p2): boolean {
        if (p1 && p2) {
            return p1.iri === p2.iri;
        }
        return false;
    }

    /** Interface of the entities referenced by the URIs which are selectable here */
    @Input() public modelTypeOrParent: Function;
    @Input() public onlyUri = false;

    public interfs: BasicEntityInterface<Resource>[];
    public options = new Map<BasicEntityInterface<Resource>, Resource[]>();
    public control = new UntypedFormControl('');

    private _onChange = _ => {
    };
    private _onTouched = () => {
    };
    private _subscription: Subscription;

    constructor(public interfaceProvider: InterfaceProviderService,
                @Optional() @Self() public ngControl: NgControl) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit(): void {
        this.interfs = this.interfaceProvider.allAcceptedInterfaces(this.modelTypeOrParent);
        if (this.filters === undefined || this.filters.length === 0) {
            this.loadData();
        }
        this._subscription = this.control.valueChanges.subscribe(this._manageChange.bind(this));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.filters && changes.filters.currentValue && changes.filters.currentValue.length > 0) {
            setTimeout(() => this.loadData(changes.filters.currentValue), 200);
        }
        if (changes.selectedValue) {
            this.selectedValue = changes.selectedValue.currentValue;
        }
    }


    public loadData(filters?: FilterAndData[]) {
        this.interfs = this.interfaceProvider.allAcceptedInterfaces(this.modelTypeOrParent);
        for (const interf of this.interfs) {
            if (filters) {
                let objs = []
                this.interfaceProvider.managerForModel(interf.serialiser.model).loader.findAndFollow(filters).pipe(
                    map(r => r.member)
                ).subscribe(objsT => {
                    objs = [...objs, ...objsT];
                    this.options.set(interf, objs);
                    if (this.control.value) {
                        this.control.setValue(this.option(this.control.value), {emitEvent: false});
                        if (this.selectedValue) {
                            this.writeValue(this.selectedValue, true)
                        }
                    } else {
                        if (this.selectedValue) {
                            this.writeValue(this.selectedValue, true)
                        }
                    }
                    if (objs.length === 1) {
                        this.writeValue(objs[0], true);
                    }
                });
            } else {
                this.interfaceProvider.managerForModel(interf.serialiser.model)
                    .all.subscribe(objs => {
                    this.options.set(interf, objs);
                    if (this.control.value) {
                        this.control.setValue(this.option(this.control.value), {emitEvent: false});
                        if (this.selectedValue) {
                            this.writeValue(this.selectedValue, true)
                        }
                    } else {
                        if (this.selectedValue) {
                            this.writeValue(this.selectedValue, true)
                        }
                    }
                    if (objs.length === 1) {
                        this.writeValue(objs[0], true);
                    }
                });
            }
        }
    }

    /**
     * When the value of the control changes
     */
    private _manageChange(v: Resource | Uri | null) {
        this._onChange(v);
    }

    public optionValue(opt: Resource | null): Resource | Uri | null {
        if (!opt) {
            return opt;
        }
        return this.onlyUri ? opt.iri : opt;
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.control.disable();
        } else {
            this.control.enable();
        }
    }

    public option(copy: Resource | Uri): Resource | Uri {
        const iri = copy instanceof Resource ? copy.iri : copy;
        const found = Array.from(this.options.values())
            .reduce((p, c) => c.concat(p), [])
            .find(v => v.iri.isTheSame(iri));
        return found ? (this.onlyUri ? found.iri || iri : found) : copy;
    }

    writeValue(obj: any, emit = false): void {
        this.control.setValue(obj != null ? this.option(obj) : null, {emitEvent: emit});
    }
}
