export class MatOptionModel {
    public titulo?: string;
    public segundaLinea?: string;
    public matIcon?: string;
    public matIconColor?: string;

    public constructor(titulo: string, segundaLinea: string, matIcon?: string, matIconColor?: string) {
        this.titulo = titulo;
        this.segundaLinea = segundaLinea;
        this.matIcon = matIcon;
        if (matIconColor) {
            this.matIconColor = matIconColor;
        } else {
            this.matIconColor = 'black';
        }
    }
}
