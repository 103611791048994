import {
    AfterViewInit,
    Component,
    forwardRef, Host,
    Input,
    OnInit,
    Optional, SkipSelf,
} from '@angular/core';
import {
    AbstractControl, ControlContainer,
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormGroup, NG_VALUE_ACCESSOR
} from '@angular/forms';
import * as moment from 'moment';
import {FORMATS} from "../../const";

@Component({
    selector: 'be-time-input',
    templateUrl: './time-input.component.html',
    styleUrls: ['./time-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimeInputComponent),
            multi: true
        }
    ]
})
export class TimeInputComponent implements OnInit, AfterViewInit, ControlValueAccessor {
    /** The internal form group which control the values of the inputs */
    public internalGroup: UntypedFormGroup;

    /** Field to store the callback to perform when the value has changed */
    private _onChange;
    /** Field to store the callback to perform when the input has been touched */
    private _onTouched;
    /** Abstract control for the element (do not mistake with the internal FormGroup) */
    private _control: AbstractControl;

    /** Name for the control to find the FormControl  */
    @Input() public formControlName;
    /** Whether this field is required or not */
    @Input() public required = false;
    /** Placeholder */
    @Input() placeholder: string = null;
    selectedTime: moment.Moment;

    /**
     * Constructs the date time input, receives access to te container to be able
     * to update the errors and values.
     */
    constructor(
        fb: UntypedFormBuilder,
        @Optional() @Host() @SkipSelf() private _controlContainer: ControlContainer
    ) {
        // Initially empty
        this.internalGroup = fb.group({
            time: ""
        });
        // Subscribe to status changes to notify errors
        this.internalGroup.statusChanges.subscribe(val => {
            if (this._control) {
                const timeErrors = this.internalGroup.controls["time"].errors;
                if (timeErrors) {
                    this._control.setErrors({...timeErrors});
                } else {
                    this._control.setErrors(null);
                }
            }
        });
    }

    /**
     * On init, we check if we obtained a control container and get
     * the FormControl assigned to this input.
     */
    ngOnInit(): void {
        if (this._controlContainer && this.formControlName) {
            this._control = this._controlContainer.control.get(this.formControlName);
        }
    }

    ngAfterViewInit(): void {
        if (this._control.value) {
            this.setTime(this._control.value.format(FORMATS.time))
        }
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.internalGroup.disable({emitEvent: false});
        } else {
            this.internalGroup.enable({emitEvent: false});
        }
    }

    writeValue(obj: any): void {
        this.internalGroup.setValue({
            time: obj ? obj : null
        });
    }

    setTime(time) {
        const hour = time.split(':')[0];
        const minute = time.split(':')[1].split(' ')[0];

        const timeSaved = moment();
        timeSaved.set({
            hour: hour,
            minute: minute
        })
        
        this.selectedTime = timeSaved.clone();
        this._control.setValue(timeSaved)
    }
}
