import {Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf} from "@angular/core";
import {AbstractControl, ControlContainer, ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import * as moment from "moment";
import {Moment} from "moment";

/**
 * This component allows to edit a DateTime field, defined by a Moment (from Moment.js).
 * The component is simply a ControlValueAccessor implementer which mixes together
 * a Material Date Time Picker and a TimeInputComponent to compose the resulting Moment.
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */
@Component({
    selector: "be-date-time-input",
    templateUrl: "./date-time-input.component.html",
    styleUrls: ['./date-time-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateTimeInputComponent),
            multi: true
        }
    ]
})
export class DateTimeInputComponent implements OnInit, ControlValueAccessor {
    /** The internal form group which control the values of the inputs */
    public internalGroup: UntypedFormGroup;

    /** Field to store the callback to perform when the value has changed */
    private _onChange;
    /** Field to store the callback to perform when the input has been touched */
    private _onTouched;
    /** Abstract control for the element (do not mistake with the internal FormGroup) */
    private _control: AbstractControl;

    /** Name for the control to find the FormControl  */
    @Input() public formControlName;
    /** Whether this field is required or not */
    @Input() public required = false;
    /** Placeholder */
    @Input() placeholder: string = null;
    /** Date picker filter for the date picker to set (if any) */
    @Input() public datePickerFilter: (date: Moment | null) => boolean = undefined;

    /**
     * Composes the two Moments passed in the object parameter,
     * taking the date from date and the time from time.
     * @param val - Date and time to compose
     * @private
     */
    private _compose(val: { date: moment.Moment; time: moment.Moment }) {
        if (val.date) {
            if (val.time) {
                return val.date.clone().set({
                    hour: val.time.hour(),
                    minute: val.time.minute(),
                    second: val.time.second()
                });
            } else {
                this.internalGroup.setValue(
                    {date: val.date.clone(), time: val.date.clone()},
                    {emitEvent: false}
                );
                return val.date.clone();
            }
        } else {
            this.internalGroup.setValue(
                {date: null, time: null},
                {emitEvent: false}
            );
            return null;
        }
    }

    /**
     * Constructs the date time input, receives access to te container to be able
     * to update the errors and values.
     */
    constructor(
        fb: UntypedFormBuilder,
        @Optional() @Host() @SkipSelf() private _controlContainer: ControlContainer
    ) {
        // Initially empty
        this.internalGroup = fb.group({
            date: "",
            time: ""
        });
        // Subscribe to notify value changes
        this.internalGroup.valueChanges.subscribe(val => {
            if (this._onChange) {
                this._onChange(this._compose(val));
            }
        });
        // Subscribe to status changes to notify errors
        this.internalGroup.statusChanges.subscribe(val => {
            if (this._control) {
                const dateErrors = this.internalGroup.controls["date"].errors;
                const timeErrors = this.internalGroup.controls["time"].errors;
                if (dateErrors || timeErrors) {
                    this._control.setErrors({...dateErrors, ...timeErrors});
                } else {
                    this._control.setErrors(null);
                }
            }
        });
    }

    /**
     * On init, we check if we obtained a control container and get
     * the FormControl assigned to this input.
     */
    ngOnInit(): void {
        if (this._controlContainer && this.formControlName) {
            this._control = this._controlContainer.control.get(this.formControlName);
        }
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.internalGroup.disable({emitEvent: false});
        } else {
            this.internalGroup.enable({emitEvent: false});
        }
    }

    writeValue(obj: any): void {
        this.internalGroup.setValue({
            date: obj ? obj : null,
            time: obj ? obj : null
        });
    }
}
