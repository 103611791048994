import {Resource} from "../../api/resource";
import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {ManagerCachingStrategy} from "../../basic-entity-back/basic-entity-interface/mapping-external";


@BasicEntity({
    name: "Dimensión",
    isPaginated: false,
    endpoint: "dimensions",
    managerCaching: ManagerCachingStrategy.Lazy,
    type: 'Dimension',
    modelNamePattern: '\u2205{{diametro}}'
})
export class Dimension extends Resource {

    @BasicProperty({
        name: "ID",
        isId: true,
        type: PropertyType.Integer()
    })
    private _id: number;

    @BasicProperty({
        name: "Diámetro",
        type: PropertyType.Float()
    })
    private _diametro: number;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get diametro(): number {
        return this._diametro;
    }

    set diametro(value: number) {
        this._diametro = value;
    }
}
