/**
 * Used to configure the formats for all the module
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */
export const FORMATS = {
    date: 'DD/MM/YYYY',
    time: 'HH:mm', // :ss
    datetime: '' // Setted later
};
FORMATS.datetime = `${FORMATS.date} ${FORMATS.time}`;
