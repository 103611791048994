<mat-select [formControl]="control" [placeholder]="placeholder" [compareWith]="compareFn">
    <ng-container *ngIf="interfs.length > 1; else onlyOne">
        <mat-optgroup *ngFor="let interf of interfs" [label]="interf.name">
            <mat-option *ngFor="let entity of options.get(interf)" [value]="optionValue(entity)">
                {{interf.getName(entity)}}
            </mat-option>
        </mat-optgroup>
    </ng-container>
    <ng-template #onlyOne>
        <mat-option *ngFor="let entity of options.get(interfs[0])" [value]="optionValue(entity)">
            {{interfs[0].getName(entity)}}
        </mat-option>
    </ng-template>
    <!--    <mat-option *ngIf="!required" [value]="null">-->
    <!--        &#45;&#45; Eliminar selección &#45;&#45;-->
    <!--    </mat-option>-->
</mat-select>
