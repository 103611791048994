import {Injectable} from '@angular/core';
import {HttpClient, HttpRequest, HttpEvent, HttpHeaders, HttpResponse, HttpEventType} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import * as JSZip from "jszip";
import {map} from "rxjs/operators";
import {Archivo} from "../../model/archivos/archivo.model";
import {ApiService} from "../../api/api.service";

@Injectable({
    providedIn: 'root'
})
export class FileUploadService {
    private uploadUrl = '';
    private zipName = 'VARIOS.zip';
    private httpHeaders: HttpHeaders;
    public progressUploadObserver: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public progressCompressObserver: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private compressCompletedObserver: BehaviorSubject<File> = new BehaviorSubject<File>(null);
    private dropCompletedObserver: Subject<File> = new Subject<File>();
    private filesDropped = [];
    private timeOut;


    constructor(private http: HttpClient, private readonly _apiService: ApiService) {
    }

    upload(file: File, type: string = ''): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        if (type !== '') {
            formData.append('type', type);
        }
        const req = new HttpRequest('POST', `${this.uploadUrl}`, formData, {
            headers: this.httpHeaders,
            reportProgress: true,
            responseType: 'json'
        });
        return this.http.request(req).pipe(map((x: HttpEvent<any>) => {
            if (x.type === HttpEventType.UploadProgress) {
                this.progressUploadObserver.next(Math.round(100 * x.loaded / x.total));
            }
            return x;
        }));
    }

    remove(file: Archivo): Observable<any> {
        return this._apiService.del(file.iri.toString());
    }

    compress(arrayFiles: File[]): Observable<any> {
        // Recorro el Zip
        const numFiles = arrayFiles.length;
        const new_zip = new JSZip();
        arrayFiles.forEach((file, index) => {
            const primerCaracter = file.name.substr(file.name.lastIndexOf('/') + 1, 1);
            const path = file['webkitRelativePath'] !== '' ? file['webkitRelativePath'] : file.name;
            if (primerCaracter !== '.') {
                const fr = new FileReader();
                fr.onload = (e) => {
                    new_zip.file(path, fr.result);
                    if (index === numFiles - 1) {
                        new_zip.generateAsync({
                            type: "arraybuffer", compression: "DEFLATE", compressionOptions: {
                                level: 1 // force a compression and a compression level for this file
                            }
                        }, (metadata) => {
                            this.progressCompressObserver.next(Math.round(metadata.percent));
                        }).then(arrayBuffer => {
                            this.compressCompletedObserver.next(new File([arrayBuffer], this.zipName));
                        });
                    }
                };
                fr.readAsArrayBuffer(file);
            }
        });
        return this.compressCompletedObserver;
    }

    proccessDropEventFiles(arrayFiles: DataTransferItemList): Observable<any> {
        for (let i = 0; i < arrayFiles.length; i++) {
            const item = arrayFiles[i];
            if (item.kind === 'file') {
                const entry = item.webkitGetAsEntry() as any;
                if (entry.isFile) {
                    entry.file((file) => {
                        this.filesDropped.push(file);
                        this.resetTimer();
                    });
                } else if (entry.isDirectory) {
                    this.getAllFilesFromDirectoryRecursive(entry);
                }
            }
        }
        return this.dropCompletedObserver;
    }


    getAllFilesFromDirectoryRecursive(item) {
        this.resetTimer();
        const that = this;
        if (item.isDirectory) {
            const directoryReader = item.createReader();
            directoryReader.readEntries((entries) => {
                entries.forEach((entry) => {
                    that.getAllFilesFromDirectoryRecursive(entry);
                });
            });
        } else {
            item.file((file) => {
                this.filesDropped.push(file);
            });
        }
    }

    resetTimer() {
        if (this.timeOut) {
            clearTimeout(this.timeOut);
        }
        this.timeOut = setTimeout(() => {
            if (this.filesDropped.length === 1) {
                this.dropCompletedObserver.next(this.filesDropped[0]);
            } else {
                this.compress(this.filesDropped).subscribe(res => {
                    this.dropCompletedObserver.next(res);
                });
            }
        }, 1000);
    }


    setBaseUrl(url: string) {
        this.uploadUrl = url;
    }

    setRequestHeaders(params: HttpHeaders) {
        this.httpHeaders = params;
    }
}
