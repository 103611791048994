import {Component, Inject, Injectable, TemplateRef} from '@angular/core';
import { ThemePalette } from "@angular/material/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";

interface SimpleDialogData {
    title: string;
    html?: string;
    bodyTemplate?: TemplateRef<any>;
    showCloseButton: boolean;
    actions: { text: string, value: any, icon?: string, color?: ThemePalette }[];
    width?: string;
}

@Component({
    selector: 'app-simple-dialog',
    template: `
        <be-dialog-shell [ngStyle]="{'width': data.width }" (close)="this.dialogRef.close(null)" [title]="data.title"
                         [closeButton]="data.showCloseButton" [showFooter]="data.actions.length > 0">
            <div class="content" *ngIf="data.html" [innerHTML]="data.html"></div>
            <div class="content" *ngIf="data.bodyTemplate" [ngTemplateOutlet]="data.bodyTemplate"></div>
            <dialog-actions>
                <button *ngFor="let action of data.actions"
                        mat-flat-button (click)="this.dialogRef.close(action.value)" [color]="action.color">
                    <mat-icon *ngIf="action.icon">{{action.icon}}</mat-icon>
                    {{action.text}}
                </button>
            </dialog-actions>
        </be-dialog-shell>`,
    styles: [`.content {
        margin: 1em 0;
    }`]
})
export class SimpleDialogComponent {
    constructor(public dialogRef: MatDialogRef<SimpleDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: SimpleDialogData) {
    }
}

@Injectable({
    providedIn: 'root'
})
export class SimpleDialogService {
    public static readonly RESULT_ACCEPT = true;
    public static readonly RESULT_CANCEL = false;

    constructor(private _matDialog: MatDialog) {
    }

    public simpleDialog<T>(title: string,
                           body: string | TemplateRef<any>,
                           actions: { text: string, value: T, icon?: string, color?: ThemePalette }[],
                           disableClose = false,
                           showCloseButton = true,
                            width: string | number | any): MatDialogRef<SimpleDialogComponent, T> {
        const widthIn = Number.isInteger(width) ? (width - 48) + 'px' : '100%';
        const widthOut = Number.isInteger(width) ? width + 'px' : '100%';
        return this._matDialog.open<SimpleDialogComponent, SimpleDialogData, T>(SimpleDialogComponent,
            {
                role: "alertdialog",
                disableClose: disableClose,
                data: {
                    title: title,
                    html: typeof body === 'string' ? body : undefined,
                    bodyTemplate: body instanceof TemplateRef ? body : undefined,
                    actions: actions,
                    showCloseButton: showCloseButton,
                    width: widthIn
                },
                width: widthOut
            });
    }

    /**
     * Opens a confirmation dialog
     * @param title - Title of the dialog
     * @param body - Content of the dialog
     * @param accept - Text in the accept button (or an array with text and icon, and optionally the color to use)
     * @param cancel - Text in the cancel button (or an array with text and icon)
     * @param disableClose - Disable closing by clicking the backdrop
     * @param showCloseButton - Show close button in the top-right corner
     * @param width
     */
    public confirmationDialog(title: string,
                              body: string | TemplateRef<any>,
                              accept: string | [string, any] | [string, any, ThemePalette] = 'Aceptar',
                              cancel: string | [string, any] = 'Cancelar',
                              disableClose = false,
                              showCloseButton = true,
                              width?: number | string): Promise<boolean> {
        return this.simpleDialog<boolean>(title, body,
            [
                {
                    text: cancel instanceof Array ? cancel[0] : cancel,
                    value: SimpleDialogService.RESULT_CANCEL,
                    icon: cancel instanceof Array ? cancel[1] : null
                },
                {
                    text: accept instanceof Array ? accept[0] : accept,
                    value: SimpleDialogService.RESULT_ACCEPT,
                    icon: accept instanceof Array ? accept[1] : null,
                    color: (accept instanceof Array ? accept[2] || 'primary' : 'primary') as ThemePalette
                }
            ], disableClose, showCloseButton, width).afterClosed().toPromise();
    }
}
