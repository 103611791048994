import {Component} from '@angular/core';
import {BasicEntityTableColumnDescriptionOrString} from "../../../basic-entity-front/basic-entity-table/basic-entity-table.component";
import {Provincia} from "../../../model/localizacion/provincia.model";

@Component({
  selector: 'app-provincia',
    template: '<be-table [columnsToDisplay]="columns" [modelType]="model"></be-table>'
})
export class ProvinciaComponent {
  public model = Provincia;
  public columns: BasicEntityTableColumnDescriptionOrString[] = [
      'codigoProvincia',
      'pais',
      'comunidad',
      'nombre',
      'aliasSEUR',
      'cp',
      'prefijo',
      {key: 'zonaPropia', hidden: true},
      'zona',
      {key: 'festivosPropios', hidden: true},
      {key: 'festivosHeredados', hidden: true}
  ];
}
