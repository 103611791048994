import {Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self} from '@angular/core';
import {ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NgControl} from "@angular/forms";
import { MatFormFieldControl } from "@angular/material/form-field";
import {Subject} from "rxjs";
import {FocusMonitor} from "@angular/cdk/a11y";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {KeyProviderService} from "../../../basic-entity-back/services/key-provider.service";

export type KeyValuePair = [string, string];

@Component({
    selector: 'be-key-value-input',
    templateUrl: './key-value-input.component.html',
    styleUrls: ['./key-value-input.component.scss'],
    providers: [{provide: MatFormFieldControl, useExisting: KeyValueInputComponent}]
})
export class KeyValueInputComponent implements OnInit, ControlValueAccessor, MatFormFieldControl<KeyValuePair>, OnDestroy {
    public static nextId = 0;

    public parts: UntypedFormGroup;
    public stateChanges = new Subject<void>();
    @HostBinding() public id = `example-tel-input-${KeyValueInputComponent.nextId++}`;

    @Input()
    get placeholder(): string {
        return this._placeholder;
    }

    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }

    private _placeholder: string;

    public get focused() {
        return this._focused;
    }

    private _focused = false;

    public get empty() {
        const val = this.parts.value;
        return !val.key && !val.value;
    }

    @HostBinding('class.floating')
    public get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @Input()
    public get required() {
        return this._required;
    }

    public set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }

    private _required = false;

    @Input()
    get disabled() {
        return this.parts.disabled;
    }

    set disabled(dis) {
        const disabled = coerceBooleanProperty(dis);
        if (disabled) {
            this.parts.disable({emitEvent: false});
        } else {
            this.parts.enable({emitEvent: false});
        }
        this.stateChanges.next();
    }

    public get errorState(): boolean {
        return !!this.ngControl.errors;
    }

    public readonly controlType = 'app-key-value-input';
    @HostBinding('attr.aria-describedby') public describedBy = '';
    /**
     * If it is not null, a select will be displayed to pick one of the possible keys
     */
    @Input() public keyProvider: KeyProviderService | null = null;
    public keySet: [string, string][] = null;
    /** All the keys as provided by the key provider */
    private _allKeys: {[s: string]: string} = null;
    /** Allows to define used keys to remove from the keySet */
    @Input() public usedKeys: string[] = [];

    public setUsedKeys(array: string[]) {
        this.usedKeys = array.slice();
        this._updateKeySet();
    }

    @Input()
    public get value(): KeyValuePair | null {
        const val = this.parts.value;
        if (val.key !== '') {
            return [val.key, val.value];
        } else {
            return null;
        }
    }

    public set value(pair: KeyValuePair | null) {
        if (pair) {
            this.parts.setValue({key: pair[0], value: pair[1]});
        } else {
            this.parts.setValue({key: '', value: ''});
        }
        this.stateChanges.next();
    }

    private _onChange = (v) => {};
    private _onTouched = (v) => {}; // Never called!

    constructor(
        fb: UntypedFormBuilder,
        @Optional() @Self() public ngControl: NgControl,
        private _fm: FocusMonitor,
        private _elRef: ElementRef<HTMLElement>
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
        _fm.monitor(_elRef.nativeElement, true).subscribe(origin => {
            this._focused = !!origin;
            this.stateChanges.next();
        });
        this.parts = fb.group({key: '', value: ''});
        this.parts.valueChanges.subscribe(val => this._onChange(this.value));
    }

    ngOnInit(): void {
        if (this.keyProvider) {
            this.keyProvider.keyChanges.subscribe(() => {
                this._allKeys = {};
                for (const [k, v] of this.keyProvider.keys.entries()) {
                    this._allKeys[k] = v;
                }
                this._updateKeySet();
            });
        }
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
        this._fm.stopMonitoring(this._elRef.nativeElement);
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }

    onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this._elRef.nativeElement.querySelector('input').focus();
        }
    }

    private _updateKeySet() {
        this.usedKeys = this.usedKeys.filter(v => v !== this.parts.value.key);
        const copy = Object.assign({}, this._allKeys);
        for (const key of this.usedKeys) {
            delete copy[key];
        }
        this.keySet = [];
        for (const [k, v] of Object.entries(copy)) {
            this.keySet.push([k, v]);
        }
    }
}
