import {Component} from "@angular/core";
import {BasicEntityTableColumnDescriptionOrString} from "../../../basic-entity-front/basic-entity-table/basic-entity-table.component";
import {Comunidad} from "../../../model/localizacion/comunidad.model";

/**
 * @author David Campos Rodríguez <david.campos.r96@gmail.com>
 */

@Component({
    selector: 'app-comunidad',
    template: '<be-table [columnsToDisplay]="columns" [modelType]="model"></be-table>'
})
export class ComunidadComponent {
    public model = Comunidad;
    public columns: BasicEntityTableColumnDescriptionOrString[] = [
        'pais',
        'codigoComunidad',
        'nombre',
        {key: 'zonaPropia', hidden: true},
        'zona',
        {key: 'festivosPropios', hidden: true},
        {key: 'festivosHeredados', hidden: true}
    ];
}
