import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {UntypedFormControl} from '@angular/forms';
import {SessionService} from "../../../session/session.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../../../api/api.service";
import {Municipio} from "../../../model/localizacion/municipio.model";
import {Provincia} from "../../../model/localizacion/provincia.model";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {animate, state, style, transition, trigger} from "@angular/animations";


/**
 * @author Issam Azzam <issam.azzam@nedaware.com>
 */


export interface EnviosElement {
    id: number;
    provincia: string;
    cp: number;
    poblacion: string;
    direccion:string;
    observaciones:string;

   // dataSource: MatTableDataSource<DireccionEnvio>;
}

@Component({
    selector: 'app-envios',
    templateUrl: './envios.component.html',
    styleUrls: ['./envios.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0',display:'none'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class EnviosComponent implements OnInit, AfterViewInit{


    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    public nombreUsuario:string;
    public provincias= [];
    public municipios= [];
    public direcciones= [] ;
    public provinciaEdit:string;
    public provinciaSelected:string;
    public poblacionEdit:string;
    public cpEdit:string;
    public direccionEdit:string;
    public observacionesEdit:string;
    mostrarAdd:boolean;
    modoEdicion:boolean;
    sePuedeBorrar:boolean;
    modificable:boolean;

    displayedColumns: string[] = ['provincia', 'poblacion', 'cp','direccion','editar','borrar'];
    displayedColumnsExpand: string[] = [...this.displayedColumns,'expand'];

    user= new UntypedFormControl('');

    expandedElement: EnviosElement | null;


    public errors: { general: string[],username: string[], password: string[] } = {general: [], username: [], password: []};
    public errorGuardar :string;

    constructor( protected loginService: SessionService,
                 protected router: Router,
                 private _activatedRoute: ActivatedRoute,
                 private apiService: ApiService) {
        this.user.setValue(loginService.token.username);
        this.nombreUsuario=loginService.token.username;
        this.mostrarAdd=false;
        this.modoEdicion=true;

        this.errors.general.push('El campo es obligatorio.')

    }

    ngOnInit(): void {
        this.modificable=true;
    //comprobamos que está loggeado
        if(this.loginService.isLogged()){

            //Boton borrar disponible
            this.sePuedeBorrar=false;
            //Cargar select provincias
            this.apiService.get('/provincias?pais=ES').subscribe((result) => {
                    this.provincias = result['body']['hydra:member'] as Array<Provincia>;
                }
            );
        }
        else{
            this.router.navigateByUrl('login');
        }
    }


    onSubmit(form){
        if (form.form.status === 'INVALID') {
            this.errorGuardar = 'No se ha podido modificar la dirección';
            return;
        }
        const direccionEnvio = {
            'user': '/usuarios/' + this.loginService.user.id,
            'provincia': this.provinciaSelected,
            'poblacion': form.form.value.poblacionSave,
            'cp': form.form.value.cpSave,
            'direccion': form.form.value.direccionSave,
            'observaciones': form.form.value.observacionesSave,
        }
        //Actualizar direccion
        this.mostrarAddForm();
        this.errorGuardar='';
    }

    seleccionProvincia(codProv) {
        //Cargar select poblacion
        this.apiService.get('/municipios?provincia.codigoProvincia='+codProv).subscribe((result) => {
            this.municipios = result['body']['hydra:member'] as Array<Municipio>;
        });
        this.apiService.get('/provincias?pais=ES&codigoProvincia='+codProv).subscribe((result) => {
            this.provinciaSelected = result['body']['hydra:member'][0].nombreProvincia as string;

        });
     }

    getProvincia(codProv){
        this.apiService.get('/provincias?pais=ES&codigoProvincia='+codProv).subscribe((result) => {

            if(result['body']['hydra:member'][0]!=undefined && result['body']['hydra:member'][0]!=null) {
                let provincia = result['body']['hydra:member'][0].nombreProvincia;
                this.direcciones[0].provincia = provincia;
            }
        });
    }

    seleccionMunicipio(idMunicipio) {



    }
    ngAfterViewInit() {
    //    this.dataSource.paginator = this.paginator;
     //   this.dataSource.sort = this.sort;
    }

    mostrarAddForm(){

        this.mostrarAdd=!this.mostrarAdd;

    }
    editar(event,form,row){
    }

    borrar(row){



    }

    setModificable()
    {
        if(this.modificable)
            this.modificable=false;
    }

    getHeaderTitle(column)
    {
        if(column==='cp')
            return 'CP'
        else if (column==='editar')
            return '';
        else if (column==='borrar')
            return '';
        else
            return column[0].toUpperCase() + column.substr(1).toLowerCase();
    }

}
