<ng-container [formGroup]="internalGroup">
  <div formArrayName="entries"
          *ngFor="let entry of internalArray.controls; let i = index">
      <mat-form-field [formGroupName]="i" [floatLabel]="floatLabel">
        <be-key-value-input formControlName="entry" [usedKeys]="usedKeys" [placeholder]="placeholder"
                            [keyProvider]="keyProvider" required></be-key-value-input>
      <mat-icon matSuffix (click)="deleteItem(i)" color="warn" class="dictionary-delete-icon">delete_forever</mat-icon>
    </mat-form-field>
  </div>
    <div class="dictionary-add" *ngIf="areThereKeysAvailable">
    <button mat-icon-button (click)="addItem()" color="primary"><mat-icon>add_circle</mat-icon></button>
  </div>
</ng-container>
